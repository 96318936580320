import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class EventService {
  constructor(public http: HttpClient, public router: Router) {}

  postFirstStep(data: any) {
    return this.http.post(
      `${environment.API_URL}events/create/firstStep/`,
      data.data
    );
  }

  getById(data: any) {
    return this.http.get(`${environment.API_URL}events/${data.idEvent}`);
  }

  publicGetById(eventId: string) {
    return this.http.get(`${environment.API_URL}events/public/${eventId}`);
  }

  getByUser() {
    return this.http.get(`${environment.API_URL}events/get/byUser`);
  }

  getTypeEvent() {
    return this.http.get(`${environment.API_URL}events/type/all`);
  }

  enabled(data: any) {
    return this.http.put(`${environment.API_URL}events/${data.idEvent}`, null);
  }

  delete(data: any) {
    return this.http.delete(`${environment.API_URL}events/${data.idEvent}`);
  }

  search(data: any) {
    return this.http.get(
      `${environment.API_URL}events/search/${data.eventName}/limit/${
        data.numberResult ? data.numberResult : 0
      }`
    );
  }
  getStats(data:any){
    return this.http.post(`${environment.API_URL}events/reports`,data);
  }
}
