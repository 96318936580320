import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
//To have router functions Available
import { RouterModule } from '@angular/router';


// Components
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderMobileComponent } from './header-mobile/header-mobile.component';
import { NavbarComponent } from './navbar/navbar.component';
import { StickyToolbarComponent } from './sticky-toolbar/sticky-toolbar.component';
import { QuickPanelComponent } from './quick-panel/quick-panel.component';
import { LoadingComponent } from './loading/loading.component';
import { NewsCardComponent } from './news-card/news-card.component';

//materialUI
import {MatCardModule} from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        PagenotfoundComponent,
        SidebarComponent,
        BreadcrumbsComponent,
        FooterComponent,
        HeaderMobileComponent,
        NavbarComponent,
        StickyToolbarComponent,
        QuickPanelComponent,
        LoadingComponent,
        NewsCardComponent
    ],
    exports: [
        PagenotfoundComponent,
        SidebarComponent,
        BreadcrumbsComponent,
        FooterComponent,
        HeaderMobileComponent,
        NavbarComponent,
        StickyToolbarComponent,
        QuickPanelComponent,
        LoadingComponent,
        NewsCardComponent,

    ],
    imports: [
        RouterModule,
        CommonModule,
        MatCardModule,
        MatIconModule
    ]
})
export class SharedModule {}
