import { Component, Inject, OnInit } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { take, startWith, map } from "rxjs/operators";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { GlobalState } from "src/app/store/states/global.state";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators, NgForm,
} from "@angular/forms";

// Third libreries
import Swal from "sweetalert2";
import * as moment from "moment";

// Store
import {
  FieldsCreateAction,
  FieldsUpdateAction,
} from "src/app/store/actions/fields.actions";
import { FieldsParams } from "src/app/models/fields/fields-params";
import * as field from "src/app/store/selectors/fields.selectors";
import {EventDetails} from "../../models/event-details/event-details";

@Component({
  selector: "app-new-field",
  templateUrl: "./new-coupon.component.html",
  styleUrls: ["./new-coupon.component.css"],
})
export class NewCouponComponent implements OnInit {

  disabledBtn: boolean = false;
  coupon: any = {};

  // Store
  private subscription: Subscription = new Subscription();

  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NewCouponComponent>,
    public dialog: MatDialog,
    public store: Store<GlobalState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(data){
      this.coupon = data.coupon;
    } else {
      this.coupon.type="PERCENT"
    }
  }

  ngOnInit() {

  }

  saveCoupon(couponForm: NgForm){
    if(couponForm.valid){
      this.coupon.code = this.coupon.code.toUpperCase();
      this.dialogRef.close(this.coupon);
    }
  }
}
