import { Component, Input, Output, EventEmitter } from '@angular/core';
import { News } from '../../models/news-feed/news';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { NewsFeedService } from 'src/app/services/news-feed/news-feed.service';
import { AuthService } from 'src/app/services/service.index';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.css']
})
export class NewsCardComponent {
  @Input() news: News;
  @Output() edit = new EventEmitter<News>();
  @Output() deleted = new EventEmitter<number>();

  loggedInUserId: number;

  getImageUrl(imagePath: string): string {
    const path = `${environment.API_URL}images/${imagePath}`;
    return path
  }

  constructor(private newsFeedService: NewsFeedService, private authService: AuthService) {
    const sessionStorage = this.authService.getSessionStorage();
    this.loggedInUserId = sessionStorage ? sessionStorage.id : null;  }

  editNews() {
    this.edit.emit(this.news);
  }

  deleteNews() {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you really want to delete this news?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#2f80ed',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('News ID:', this.news.id);
        this.newsFeedService.removeById(this.news.id).subscribe(
          (response) => {
            if (response.valid) {
              Swal.fire('Deleted!', 'The news has been deleted.', 'success');
              this.deleted.emit(this.news.id);
            } else {
              Swal.fire('Error!', 'Could not delete the news.', 'error');
            }
          },
          (error) => {
            console.error('Error deleting news', error);
            Swal.fire('Error!', 'An error occurred while deleting the news.', 'error');
          }
        );
      }
      // else {
      //   Swal.fire({
      //     title: 'Cancelled',
      //     text: 'Your news is safe',
      //     icon: 'info',
      //     confirmButtonColor: '#2f80ed',
      //     confirmButtonText: 'OK'
      //   });
      // }
    });
  }

  get truncatedTitle(): string {
    const maxLength = 110;
    if (this.news.title.length > maxLength) {
      return this.news.title.substring(0, maxLength) + '...';
    }
    return this.news.title;
  }

  isOwner(): boolean {
    return this.loggedInUserId === this.news.userId;
  }

}
