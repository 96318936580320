import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { AuthService } from 'src/app/services/service.index';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  user: any;
  constructor(
    public _authService: AuthService,
    public router: Router,
  ) {
    this.user = this._authService.getSessionStorage();
  }

  ngOnInit() {
  }

}
