import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RolesService {
  constructor(public http: HttpClient, public router: Router) {}

  create(data: any) {
    return this.http.post(`${environment.API_URL}roles/create`, data);
  }

  update(data: any) {
    return this.http.put(
      `${environment.API_URL}roles/edit/${data.idRol}`,
      data
    );
  }

  getAllRoles() {
    return this.http.get(`${environment.API_URL}roles/get/all`);
  }

  enabled(data: any) {
    return this.http.put(`${environment.API_URL}roles/${data.idRol}`, null);
  }

  delete(data: any) {
    return this.http.delete(`${environment.API_URL}roles/${data.idRol}`);
  }
}
