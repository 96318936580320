import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateTypeDivision, type_divisionAdapter } from '../states/type_division.state';

export const {
  selectIds: _selectTypeDivisionDataIds,
  selectEntities: _selectTypeDivisionEntities,
  selectAll: _selectAllTypeDivision,
  selectTotal: _selectTypeDivisionTotal
} = type_divisionAdapter.getSelectors();

export const selectState = createFeatureSelector<StateTypeDivision>('type_division');

export const selectTypeDivisionIds = createSelector(
  selectState,
  _selectTypeDivisionDataIds
);

export const selectTypeDivisionEntities = createSelector(
  selectState,
  _selectTypeDivisionEntities
);

export const selectAllTypeDivision = createSelector(
  selectState,
  _selectAllTypeDivision
);

export const selectTypeDivisionResponse = createSelector(
  selectState,
  (state: StateTypeDivision): any => state.type_division
);

export const selectTypeDivisionError = createSelector(
  selectState,
  (state: StateTypeDivision): boolean => state.error
);

export const selectTypeDivisionLoading = createSelector(
  selectState,
  (state: StateTypeDivision): boolean => state.loading
);
