import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {EventDetails} from "../../../models/event-details/event-details";
import {EventDetailsService} from "../../../services/event-details/event-details.service";
import {EventService} from "../../../services/event/event.service";
import {Event} from "../../../models/event/event";
import {SharedService} from "../../../services/shared/shared.service";
import {NewBracketsComponent} from "../../../modals/new-brackets/new-brackets.component";
import {MatDialog} from "@angular/material/dialog";
import {SharedDataService} from "../../../utils/shared";

@Component({
  selector: 'app-pools-brackets',
  templateUrl: './pools-brackets.component.html',
  styleUrls: ['./pools-brackets.component.css']
})
export class PoolsBracketsComponent implements OnInit {
  eventId: number;
  event: Event = {};
  divisions: EventDetails[] = [];

  constructor(
    private route: ActivatedRoute,
    private _sharedService: SharedService,
    private _eventDetailsService: EventDetailsService,
    private _eventService: EventService,
    public dialog: MatDialog,
    private shared:SharedDataService
  ) {
    this._sharedService.changeTitile("");


  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.eventId = params.eventId;
     
      if(this.eventId){
        //Get Event - Divisions
        this._eventService.getById({idEvent:this.eventId}).subscribe((response:any)=>{
         
          if(response.valid){
            this.event = response.data;
            this.divisions = response.data.divisions;                       
            this.shared.event = this.event;            
            localStorage.setItem('event', JSON.stringify(this.event));

          }
        });
      }
    })
  }

  newBracket(division_id,team_size) {
    console.log(division_id,team_size)
    const dialogRef = this.dialog.open(NewBracketsComponent, {
      width: "30%",
      data: { division_id },
    });
       //
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log("---------->",result)
        setTimeout(()=>window.location.href = `/#/superadmin/event/${this.eventId}/division/${division_id}/pools/${this.shared.default_pool.id}`,1000) //verifix
      }
    });
  }

}
