import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private titleSection : string = '';
  private titleSection$ : Subject<string>;

  constructor() {
    this.titleSection$ = new Subject();
  }

  /**
   * Status and title page
   */
  changeTitile(title: string){
    this.titleSection = title;
    this.titleSection$.next(this.titleSection);
  }

  getTitleSection$(): Observable<string>{
    return this.titleSection$.asObservable();
  }
}
