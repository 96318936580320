import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatDialog,
} from "@angular/material";
import { UserService, SharedService } from "src/app/services/service.index";
import Swal from "sweetalert2";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    "name",
  ];

  //Others
  loading: boolean = false;

  constructor(
    private _userServices: UserService,
    public dialog: MatDialog,
    private _sharedService: SharedService
  ) {
    this._sharedService.changeTitile("Dashboard");
  }

  ngOnInit() {}
}
