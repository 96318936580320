import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { GlobalState } from "src/app/store/states/global.state";
import { of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  exhaustMap,
  mergeMap,
} from "rxjs/operators";
import { EventService, AuthService } from "src/app/services/service.index";
import { EventParams } from "src/app/models/event/event-params";
import { EventResponse } from "src/app/models/event/event-response";
import { Router } from "@angular/router";

// Store
import * as event from "../actions/event.actions";

import { EventLoadSearchSuccess } from "src/app/store/actions/event.actions";
import { EventDetailsGetAllAction } from "src/app/store/actions/event-details.actions";

// Third libreries
import Swal from "sweetalert2";

// Services
import {
  NotificationService,
  AlertService,
} from "src/app/services/service.index";

@Injectable()
export class EventEffects {
  constructor(
    private _notificationService: NotificationService,
    private _eventService: EventService,
    private actions$: Actions,
    public router: Router,
    public store: Store<GlobalState>
  ) {
    //
  }

  public createEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<event.EventLoadAction>(event.EventActionType.Save),
      map((action) => action.payload),
      switchMap((params: EventParams) =>
        this._eventService.postFirstStep(params).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Event`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Event`);
              }
            }
            return new event.EventLoadSuccessAction(response);
          }),
          catchError((error) => of(new event.EventLoadFailAction(error)))
        )
      )
    )
  );

  public enabledEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<event.EventLoadAction>(event.EventActionType.Enabled),
      map((action) => action.payload),
      switchMap((params: EventParams) =>
        this._eventService.enabled(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Enabled`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Enabled`);
              }
            } else {
              this.store.dispatch(new EventDetailsGetAllAction(null));
            }
            return new event.EventLoadSuccessAction(response);
          }),
          catchError((error) => of(new event.EventLoadFailAction(error)))
        )
      )
    )
  );

  public deleteEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<event.EventLoadAction>(event.EventActionType.Delete),
      map((action) => action.payload),
      switchMap((params: EventParams) =>
        this._eventService.delete(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Delete`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Delete`);
              }
            } else {
              this.store.dispatch(new EventDetailsGetAllAction(null));
            }
            return new event.EventLoadSuccessAction(response);
          }),
          catchError((error) => of(new event.EventLoadFailAction(error)))
        )
      )
    )
  );

  public GetByIdEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<event.EventLoadAction>(event.EventActionType.GetById),
      map((action) => action.payload),
      switchMap((params: EventParams) =>
        this._eventService.getById(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Search`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Search`);
              }
            } else {
              this.store.dispatch(new EventDetailsGetAllAction(null));
            }
            return new event.EventLoadSuccessAction(response);
          }),
          catchError((error) => of(new event.EventLoadFailAction(error)))
        )
      )
    )
  );

  public searchEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<event.EventLoadAction>(event.EventActionType.Search),
      map((action) => action.payload),
      switchMap((params: EventParams) =>
        this._eventService.search(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Search`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Search`);
              }
            } else {
              return new event.EventLoadSearchSuccess(response);
            }
          }),
          catchError((error) => of(new event.EventLoadFailAction(error)))
        )
      )
    )
  );
}
