import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from "@angular/material";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import Swal from "sweetalert2";

interface Pictures {
  name: string;
  url: any;
}

@Component({
  selector: "app-upload-dialog",
  templateUrl: "./upload-dialog.component.html",
  styleUrls: ["./upload-dialog.component.scss"],
})
export class UploadDialogComponent implements OnInit {
  uploading = false;
  files: Pictures[] = [];

  @ViewChild("file", { static: false }) file;

  constructor(
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  addFiles(event) {
    this.file.nativeElement.click();
  }

  onFilesAdded(event) {
    const files = event.target.files[0];
    // Controlo que el objeto vuelva lleno.
    if (files) {
      // Controlo el tamaño maximo de la imagen.
      if (this.checkSize(files.size)) {
        // Chequeo el formato del archivo.
        if (this.checkFormat(files.type)) {
          // Llamó al metodo que lo lee como base64.
          this.readFile(files);
        } else {
          Swal.fire({
            icon: "error",
            title: "The file its not supported!",
            text: "The supported format are: jpeg, png, bpm.",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "The file its to big!",
          text: "Try with another one, should not be bigger than 5mb.",
        });
      }
    }
  }

  deleteFile(index, event) {
    event.stopPropagation();
    this.files.splice(index, 1);
  }

  handleDrop(e) {
    e.preventDefault();
    const files = e.dataTransfer.files;

    for (let index = 0; index < files.length; index++) {
      // Controlo que el objeto vuelva lleno.
      if (files[index]) {
        // Controlo el tamaño maximo de la imagen.
        if (this.checkSize(files[index].size)) {
          // Chequeo el formato del archivo.
          if (this.checkFormat(files[index].type)) {
            // Llamó al metodo que lo lee como base64.
            this.readFile(files[index]);
          } else {
            Swal.fire({
              icon: "error",
              title: "The file its not supported!",
              text: "The supported format are: jpeg, png, bpm.",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "The file its to big!",
            text: "Try with another one, should not be bigger than 5mb.",
          });
        }
      }
    }
  }

  readFile(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.files.push({
        name: file.name,
        url: reader.result,
      });
    };
  }

  isFilesEmpty() {
    return this.files.length === 0;
  }

  checkSize(size) {
    if (size < this.data.maxSize) {
      return true;
    }
    return false;
  }

  checkFormat(type) {
    if (this.data.acceptedFormat.includes(type) && type) {
      return true;
    }
    return false;
  }

  upload() {
    this.dialogRef.close(this.files);
  }
}
