import { Component, OnInit, ViewChild } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { take, startWith, map } from "rxjs/operators";
import { Store, select } from "@ngrx/store";
import { GlobalState } from "src/app/store/states/global.state";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  DateAdapter,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { SharedService } from "src/app/services/service.index";
import { NewUserComponent } from "src/app/modals/new-user/new-user.component";

// Store
import * as user from "src/app/store/selectors/user.selectors";

import {
  UserDeleteAction,
  UserEnabledAction,
} from "src/app/store/actions/user.actions";
import { UserParams } from "src/app/models/user/user-params";

// Third libreries
import Swal from "sweetalert2";
import * as moment from "moment";

//Services
import { UserService } from "src/app/services/service.index";

// Modals
import { MatDialog } from "@angular/material/dialog";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD-MM-YY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class UsersComponent implements OnInit {
  // Actions Datatable
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  // Datatables
  displayedColumns: string[] = [
    "username",
    "email",
    "fullName",
    "phoneNumber",
    "date",
    "enabled",
    "actions",
  ];
  dataSource: MatTableDataSource<any>;

  dateFromValue: any;
  dateUntilValue: any;
  statues: any[] = [];

  // Store
  private subscription: Subscription = new Subscription();

  originalData: any;
  searchIn: number;
  selectedStatues: any;
  filterValue: "";

  constructor(
    private _sharedService: SharedService,
    private _userService: UserService,
    public store: Store<GlobalState>,
    public dialog: MatDialog
  ) {
    this._sharedService.changeTitile("Users");
  }

  newUser() {
    const dialogRef = this.dialog.open(NewUserComponent, {
     
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // After close
      }
    });
  }

  editUser(user: any): void {
    const dialogRef = this.dialog.open(NewUserComponent, {     
      data: user,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      if (result) {
        // update datatables
      }
    });
  }

  ngOnInit() {
    // Get Users
    this.subscription.add(
      this.store.pipe(select(user.selectUserLoading)).subscribe((loading) => {
        if (!loading.valid) {
          this.store
            .pipe(select(user.selectUserResponse))
            .subscribe((response) => {
              if (response.valid && response.data.length > 0) {
                let data = response.data.map((element: any) => ({
                  ...element,
                  date: moment(element.dateCreated).format("YYYY-MM-DD"),
                }));
                this.dataSource = new MatTableDataSource(data);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.originalData = data;
              } else {
                this.dataSource = new MatTableDataSource([]);
              }
            });
        }
      })
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  enabledConfirmation(idUser: any) {
    Swal.fire({
      title: "Are you sure?",
      text: "The user will access the system again",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, enabled it!",
    }).then((result) => {
      if (result.value) {
        this.store.dispatch(
          new UserEnabledAction(<UserParams>{
            data: { idUser },
          })
        );
      }
    });
  }

  deleteConfirmation(idUser: any) {
    Swal.fire({
      title: "Are you sure?",
      text: "The user will no longer be able to access the system",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, disabled it!",
    }).then((result) => {
      if (result.value) {
        this.store.dispatch(
          new UserDeleteAction(<UserParams>{
            data: { idUser },
          })
        );
      }
    });
  }
}
