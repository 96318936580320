import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { CKEditorModule } from 'ckeditor4-angular';
import {MatTabsModule} from '@angular/material/tabs';
//Routes
import { SUPERADMIN_ROUTES } from './superadmin.routes';

//Modules
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatStepperModule } from '@angular/material';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';

//Main Components
import { SuperadminComponent } from './superadmin.component';

//Components
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatButtonModule } from '@angular/material/button';
import { AddonsComponent } from './addons/addons.component';
import { EventsComponent } from './events/events.component';
import { FieldsComponent } from './fields/fields.component';
import { RolesComponent } from './roles/roles.component';
import { OverviewComponent } from './overview/overview.component';
import { UsersComponent } from './users/users.component';
import { RegistrationComponent } from './registration/registration.component';
import { ModalsModule } from 'src/app/modals/modals.module';

//-- Directives --//
import { RequestAnimationDirective } from '../../directives/request-animation/request-animation.directive';
import { PoolsBracketsComponent } from './pools-brackets/pools-brackets.component';
import { PoolsComponent } from './pools/pools.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { PoolGamesComponent } from "./pool-games/pool-games.component";
import { MatchesComponent } from './matches/matches.component';
import { CalendarComponent } from './calendar/calendar.component';
import {BracketsComponent} from "./brackets/brackets.component";
import {DivisionComponent} from "../../modals/division/division.component";
import {EventHeaderComponent} from "./shared/event-header/event-header.component";
import { DivisionTeamsComponent } from './division-teams/division-teams.component';

import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {PoolGamesDoubleComponent} from "./pool-games-double/pool-games-double.component";
import {MatBadgeModule} from "@angular/material/badge";
import { NotificationsComponent } from './notifications/notifications.component';
import { ReportsComponent } from "./reports/reports.component";
import { TeamsComponent } from './teams/teams.component';
import { StripeComponent } from './stripe/stripe.component';
import { NewsFeedComponent } from './news-feed/news-feed.component';


@NgModule({
    declarations: [
        CalendarComponent,
        MatchesComponent,
        PoolGamesDoubleComponent,
        PoolGamesComponent,
        PoolsComponent,
        SuperadminComponent,
        DashboardComponent,
        AddonsComponent,
        EventsComponent,
        FieldsComponent,
        RolesComponent,
        OverviewComponent,
        UsersComponent,
        RegistrationComponent,
        RequestAnimationDirective,
        PoolsBracketsComponent,
        BracketsComponent,
        DivisionTeamsComponent,
        EventHeaderComponent,
        NotificationsComponent,
        ReportsComponent,
        TeamsComponent,
        StripeComponent,
        NewsFeedComponent
    ],
    exports: [
    ],
    imports: [
        MatSlideToggleModule,
        MatTableModule,
        MatTabsModule,
        DragDropModule,
        MatCardModule,
        MatGridListModule,
        IonicModule,
        CommonModule,
        SharedModule,
        SUPERADMIN_ROUTES,
        ReactiveFormsModule,
        CKEditorModule,
        FormsModule,
        NgSelectModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatListModule,
        MatTableModule,
        MatFormFieldModule,
        MatPaginatorModule,
        MatStepperModule,
        MatInputModule,
        MatIconModule,
        MatSortModule,
        MatDialogModule,
        MatDatepickerModule,
        MatRadioModule,
        MatMenuModule,
        MatButtonModule,
        MatExpansionModule,
        MatCheckboxModule,
        ModalsModule,
        MatBadgeModule,
    ],
    entryComponents: [],
})
export class SuperadminModule { }
