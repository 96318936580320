import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  exhaustMap,
  mergeMap,
} from "rxjs/operators";
import {
  EventDetailsService,
  AuthService,
} from "src/app/services/service.index";
import { EventDetailsParams } from "src/app/models/event-details/event-details-params";
import { EventDetailsResponse } from "src/app/models/event-details/event-details-response";
import { Router } from "@angular/router";
import * as eventDetails from "../actions/event-details.actions";

// Third libreries
import Swal from "sweetalert2";

// Services
import {
  NotificationService,
  AlertService,
} from "src/app/services/service.index";

@Injectable()
export class EventDetailsEffects {
  constructor(
    private _notificationService: NotificationService,
    private _eventService: EventDetailsService,
    private actions$: Actions,
    public router: Router
  ) {
    //
  }

  public createEventDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<eventDetails.EventDetailsLoadAction>(
        eventDetails.EventDetailsActionType.Save
      ),
      map((action) => action.payload),
      switchMap((params: EventDetailsParams) =>
        this._eventService.postSecondStep(params).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(
                    `${errorMsg}`,
                    `EventDetails`
                  );
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(
                  `${errorMsg}`,
                  `EventDetails`
                );
              }
            }
            return new eventDetails.EventDetailsLoadSuccessAction(response);
          }),
          catchError((error) =>
            of(new eventDetails.EventDetailsLoadFailAction(error))
          )
        )
      )
    )
  );

  public getAllEventDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<eventDetails.EventDetailsLoadAction>(
        eventDetails.EventDetailsActionType.GetAll
      ),
      map((action) => action.payload),
      switchMap((params: EventDetailsParams) =>
        this._eventService.getAll().pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(
                    `${errorMsg}`,
                    `EventDetails`
                  );
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(
                  `${errorMsg}`,
                  `EventDetails`
                );
              }
            }
            return new eventDetails.EventDetailsLoadSuccessAction(response);
          }),
          catchError((error) =>
            of(new eventDetails.EventDetailsLoadFailAction(error))
          )
        )
      )
    )
  );
}
