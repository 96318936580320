import {Component, Inject, OnInit} from "@angular/core";
import { ComponentRef } from "@ionic/core";
import { Subject } from "rxjs";
import * as years from 'src/app/store/selectors/years.selectors';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EventDetails} from "../../models/event-details/event-details";
import {FormControl,NgForm} from "@angular/forms";

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material/datepicker';
import Swal from "sweetalert2";

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { A } from "@angular/cdk/keycodes";
import {compressImage} from "../../utils/tools.utils";
import {environment} from "../../../environments/environment";

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: "app-division",
  templateUrl: "./division.component.html",
  styleUrls: ["./division.component.css"],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DivisionComponent implements OnInit {
  //age_start_date = new FormControl(moment());
  //age_end_date = new FormControl(moment());
  isMobile:boolean;

  years: Array<any>;
  type_division: Array<any>;
  gender: Array<any>;
  division: EventDetails;
  skills: Array<any>;
  ageRestric: any =[];
  totalLimitTeam: number;
  totalTeamsDivision: number;
  styles: Array<any>;
  selectedStyleDescription: string;
  selectedValues: any[] = [];
  customize:string;
  imagesBracket: any = [
    {
      header: "",
      footer: "",
    },
  ];

  imagesBracketSend: any = [
    {
      header: "",
      footer: "",
    },
  ];


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DivisionComponent>,  private breakpointObserver: BreakpointObserver) {

    this.years = data.years;
    this.type_division = data.type_division;
    this.gender = data.gender;
    this.division = data.division;
    this.skills =data.skill;
    this.ageRestric =data.ageRestric;
    this.styles = data.styles;
    this.totalLimitTeam = data.division.limit_player;
    this.totalTeamsDivision = data.division && data.division.teams_division ? data.division.teams_division.length  : 0;
    this.isMobile = this.data.isMobile;
    this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    if(data.division.bracket_header){
      this.imagesBracket[0].header = this.convertImage(data.division.bracket_header,'images/');
    }

    if(data.division.bracket_footer) {
      this.imagesBracket[0].footer = this.convertImage(data.division.bracket_footer, 'images/');
    }
    this.customize = data.division.customize;
  }
  ngOnInit() {

      this.division.typeYears = this.years[0];

  }
  save(divisionForm: NgForm){
    this.division.imagesBracket = this.imagesBracket;
    this.division.bracket_header = this.imagesBracket[0].header.startsWith('data:image') ? this.imagesBracket[0].header : this.division.bracket_header;
    this.division.bracket_footer =  this.imagesBracket[0].footer.startsWith('data:image') ? this.imagesBracket[0].footer : this.division.bracket_footer;
    //console.log('Division',this.division);
    //console.log('PlayersDivi',this.totalLimitTeam,this.totalTeamsDivision,this.division.limit_player);
    if(divisionForm.valid){
      if (this.totalTeamsDivision>0  && parseInt(this.division.limit_player,10)< this.totalLimitTeam ){
        setTimeout(() => {
          Swal.fire({
            title: "Error",
            text: "There are already registered teams in the division.",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
          });
        }, 800);

      }
      else{
        this.dialogRef.close(this.division);
      }
    }
  }
  updateDivisionName() {
    let ageRestric = ( this.division.ageRestric && this.division.ageRestric.description) || '';
    let divisionStyle = ( this.division.divisionStyles && this.division.divisionStyles.description) || '';
    let genderRestriction = (this.division.genderRestriction && this.division.genderRestriction.gender) || '';
    let typeSkill =  ( this.division.typeSkill && this.division.typeSkill.abbreviation) || '';
    this.division.division_name =  divisionStyle+ ' '+genderRestriction+' '+ ageRestric+ ' '+typeSkill;

  }

  // Get Images
  async handleUpload(event, type) {


    // Type : 0 - Event / 1 - Sponsor
    const file = event.target.files[0];
    const reader = new FileReader();
    const max_size = 1048576;

    if (event.target.files[0].size > max_size) {

      setTimeout(() => {
        Swal.fire({
          title: "Error",
          text: "Maximum size allowed is 1 Mb",
          icon: "error",
          showConfirmButton: false,
          timer: 4000,
        });
      }, 800);


      return false;
    }



    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log(reader.result);
      switch (type) {
        case 0:
          this.imagesBracket[0].header = reader.result;
          this.imagesBracketSend[0].header =reader.result;
          break;
        case 1:
          this.imagesBracket[0].footer = reader.result;
          this.imagesBracketSend[0].footer = reader.result;
          break;
        default:
          break;
      }
    };
  }

  // Remove Images
  removeUpload(type) {
    // Type : 0 - Event / 1 - Sponsor
    switch (type) {
      case 0:
        this.imagesBracket[0].header = "";
        this.imagesBracketSend[0].header = "";
        break;
      case 1:
        this.imagesBracket[0].footer = "";
        this.imagesBracketSend[0].footer = "";
        break;
      default:
        break;
    }
  }

  convertImage(base64string: string, path: string) {
    if (base64string.startsWith('data:image')) {
      return base64string;
    } else {
      return `${environment.API_URL + path + base64string}`;
    }
  }



}
