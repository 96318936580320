import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable, pipe } from "rxjs";
import { finalize, tap } from "rxjs/operators";
import { RequestAnimationService } from "../request-animation/request-animation.service";

// Third libreries
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class RequestAnimationInterceptor implements HttpInterceptor {
  constructor(private _requestAnimationService: RequestAnimationService) {}

  requestCounter = 0;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.beginRequest();

    return next.handle(req).pipe(
      finalize(() => {
        this.endRequest();

      })
    );
  }

  beginRequest() {
    this.requestCounter = Math.max(this.requestCounter, 0) + 1;

    if (this.requestCounter === 1) {
      setTimeout(() => {
        Swal.showLoading();
      }, 500);
      this._requestAnimationService.busy.next(true);
    }
  }

  endRequest() {
    this.requestCounter = Math.max(this.requestCounter, 1) - 1;

    if (this.requestCounter === 0) {
      setTimeout(() => {
        Swal.close();
      }, 500);
      this._requestAnimationService.busy.next(false);
    }
  }
}
