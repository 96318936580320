import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { Observable } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";

// Third libreries
import Swal from "sweetalert2";

//Services
import { UserService } from "src/app/services/service.index";

@Component({
  selector: "app-activation",
  templateUrl: "./activation.component.html",
  styleUrls: ["./activation.component.css"],
})
export class ActivationComponent implements OnInit {
  activationKey: String = "";

  constructor(
    private _userService: UserService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.activationKey = params.idActivation;
      this.checkActivation(this.activationKey);
    });
  }

  ngOnInit() {}

  checkActivation(activationKey) {
    Swal.showLoading();
  }
}
