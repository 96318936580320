import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ccbanswersService } from "src/app/services/service.index";

@Component({
  selector: 'app-show-answers',
  templateUrl: './show-answers.component.html',
  styleUrls: ['./show-answers.component.css']
})
export class ShowAnswersComponent implements OnInit {
  answers: any[] = []
  constructor(
    private _ccbanswersService: ccbanswersService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this._ccbanswersService.getAllAnswersEvent({ idEvent: this.data.idEvent }).subscribe((response: any) => {
      if (response.valid) {
        this.answers = response.data;
      }
    });
  }
}
