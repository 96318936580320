import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { Subscription, Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { GlobalState } from "src/app/store/states/global.state";
import { Router } from "@angular/router";
import { stringToBoolean } from "../../utils/error.utils";

// Third libreries
import Swal from "sweetalert2";

//Services
import { AuthService } from "src/app/services/service.index";

// Store
import { UserAuthAction } from "src/app/store/actions/user.actions";
import { UserParams } from "src/app/models/user/user-params";

import * as user from "src/app/store/selectors/user.selectors";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showError: Boolean = false;
  msgError: String = "";

  // User
  loadingUser?: boolean;
  errorUser?: boolean;
  public errorUser$?: Observable<boolean>;

  private subscription: Subscription = new Subscription();

  constructor(
    private _authService: AuthService,
    private formBuilder: FormBuilder,
    public router: Router,
    public store: Store<GlobalState>
  ) {
    this.initializeForms();
  }

  ngOnInit() {}

  initializeForms() {
    this.loginForm = this.formBuilder.group({
      user: ["", [Validators.required]],
      password: ["", [Validators.required, Validators.minLength(8)]],
    });
  }

  login() {
    this.store.dispatch(
      new UserAuthAction(<UserParams>{
        data: this.loginForm.value,
      })
    );

    this.store.pipe(select(user.selectUserError)).subscribe((error) => {
      this.errorUser = error;
    });
  }
}
