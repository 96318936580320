import { Component, OnInit } from "@angular/core";
//services
import { AuthService } from "src/app/services/service.index";

// Declare pluggins to initialize
declare var KTLayout;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  role: any;
  menues: any = [];
  constructor(public _authService: AuthService) {
    this.role = this._authService.getSessionStorage().role;
  }
  ngOnInit() {
    //-- Init aside menu pluggins --//
    KTLayout.init();

    if (['Admin','Staff','Superadmin'].indexOf(this.role) !== -1) {
      //Admin
      this.menues = [
        {
          titulo: "Dashboard",
          icono: "kt-menu__link-icon flaticon2-dashboard",
          url: "/superadmin/dashboard",
        },
        {
          titulo: "Manage Events",
          icono: "kt-menu__link-icon flaticon2-shelter",
          url: "/superadmin/events",
        },
        {
          titulo: "Notifications",
          icono: "kt-menu__link-icon flaticon-paper-plane",
          url: "/superadmin/notifications",
        },
        {
          titulo: "Reports",
          icono: "kt-menu__link-icon flaticon-graphic",
          url: "/superadmin/stripe",
        },
        {
          titulo: "Manage Registration",
          icono: "kt-menu__link-icon flaticon2-box-1",
          url: "/superadmin/registration",
        },
        {
          titulo: "Manage Roles",
          icono: "kt-menu__link-icon flaticon2-group",
          url: "/superadmin/roles",
        },
        {
          titulo: "Manage Fields",
          icono: "kt-menu__link-icon flaticon2-setup",
          url: "/superadmin/fields",
        },
        {
          titulo: "Manage Teams",
          icono: "kt-menu__link-icon flaticon2-group",
          url: "/superadmin/teams",
        }
      ];
      if(this.role === 'Superadmin') {
        this.menues.push({
          titulo: "Manage Users",
          icono: "kt-menu__link-icon flaticon2-avatar",
          url: "/superadmin/users",
        });
      }
    } else {
      // Admin
      this.menues = [
        {
          titulo: "Customers",
          icono: "kt-menu__link-icon flaticon2-user",
          url: "/admin/customers",
        },
        {
          titulo: "Users",
          icono: "kt-menu__link-icon flaticon2-shelter",
          url: "/admin/users",
        },
      ];
    }
  }
}
