import { Action } from "@ngrx/store";
import { GenderParams } from "../../models/gender/gender-params";
import { GenderResponse } from "../../models/gender/gender-response";

export enum GenderActionType {
  Get = "[Gender] Get",
  Loading = "[Gender] Loading",
  LoadSuccess = "[Gender] LoadSuccess",
  LoadFailure = "[Gender] LoadFailure",
}

export class GenderLoadAction implements Action {
  public readonly type = GenderActionType.Loading;
  constructor(public payload: GenderParams) {}
}

export class GenderGetAction implements Action {
  public readonly type = GenderActionType.Get;
  constructor(public payload: GenderParams) {}
}

export class GenderLoadSuccessAction implements Action {
  public readonly type = GenderActionType.LoadSuccess;
  constructor(public payload: GenderResponse) {}
}

export class GenderLoadFailAction implements Action {
  public readonly type = GenderActionType.LoadFailure;
  constructor(public error: any) {}
}

export type GenderAction =
  | GenderLoadAction
  | GenderLoadSuccessAction
  | GenderLoadFailAction;
