import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CKEditorModule } from 'ckeditor4-angular';



//Routes
// import { ADMIN_ROUTES } from './admin.routes';

//Modules
import { SharedModule } from "src/app/shared/shared.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatFormFieldModule } from "@angular/material/form-field";

import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatRadioModule } from "@angular/material/radio";
import { MatNativeDateModule } from "@angular/material/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatCardModule, MatGridListModule, MatPaginatorModule, MatSnackBarModule } from "@angular/material";
import { MatMenuModule } from "@angular/material/menu";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";
import { NgbTimepickerModule } from "@ng-bootstrap/ng-bootstrap";

//Components
import { UploadDialogComponent } from "./upload-dialog/upload-dialog.component";
import { NewUserComponent } from "./new-user/new-user.component";
import { NewEventComponent } from "./new-event/new-event.component";
import { NewRolComponent } from "./new-rol/new-rol.component";
import { NewFieldComponent } from "./new-field/new-field.component";

import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatChipsModule } from '@angular/material/chips';

import { NewBracketsComponent } from "./new-brackets/new-brackets.component";
import { NewGameComponent } from "./new-game/new-game.component";
import { SchedulingComponent } from "./scheduling/scheduling.component";
import {DivisionComponent} from "./division/division.component";
import {IonicModule} from "@ionic/angular";
import { TeamSelectionComponent } from "./team-selection/team-selection.component";
import { TbdDetailsComponent } from "./tbd-details/tbd-details.component";
import { AddTeamComponent } from './add-team/add-team.component';
import { AddStatsComponent } from './add-stats/add-stats.component';
import { EditSingleComponent } from "./edit-single/edit-single.component";
import { UpdateScoreComponent } from './update-score/update-score/update-score.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ManageTeamsComponent } from './manage-teams/manage-teams.component';
import { EditCalendarComponent } from './edit-calendar/edit-calendar.component';
import {NewStaffComponent} from "./new-staff/new-staff.component";
import { ShowDislikeComponent } from './coments-dislike/show-dislike.component';
import { MassEditDivisionsComponent } from './mass-edit-divisions/mass-edit-divisions.component';
import { AddPlayersTeamComponent } from './add-players-team/add-players-team.component';
import { AddMatchComponent } from './add-match/add-match.component';
import { ShowAnswersComponent } from './show-answers/show-answers.component';
import { NewsModalComponent } from "./add-news-feed/add-news-feed.component";
import {DiscountCouponsService} from "../services/discountCoupons/discountCoupons.service";
import {NewCouponComponent} from "./new-coupon/new-coupon.component";

@NgModule({
  declarations: [
    EditSingleComponent,
    TbdDetailsComponent,
    TeamSelectionComponent,
    SchedulingComponent,
    NewGameComponent,
    NewBracketsComponent,
    UploadDialogComponent,
    NewUserComponent,
    NewEventComponent,
    NewRolComponent,
    NewFieldComponent,
    DivisionComponent,
    AddTeamComponent,
    AddStatsComponent,
    UpdateScoreComponent,
    ManageTeamsComponent,
    EditCalendarComponent,
    NewStaffComponent,
    ShowDislikeComponent,
    AddPlayersTeamComponent,
    ShowDislikeComponent,
    MassEditDivisionsComponent,
    AddMatchComponent,
    NewsModalComponent,
    ShowAnswersComponent,
    NewCouponComponent
  ],
  exports: [],
  imports: [
    IonicModule,
    MatCardModule,
    MatGridListModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatDividerModule,
    MatListModule,
    MatTableModule,
    MatIconModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatMenuModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatCheckboxModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgbTimepickerModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    CKEditorModule
  ],
  providers: [MatDatepickerModule],
  entryComponents: [
    EditSingleComponent,
    TbdDetailsComponent,
    TeamSelectionComponent,
    SchedulingComponent,
    NewGameComponent,
    NewBracketsComponent,
    UploadDialogComponent,
    NewUserComponent,
    NewEventComponent,
    NewRolComponent,
    NewFieldComponent,
    DivisionComponent,
    AddTeamComponent,
    AddStatsComponent,
    UpdateScoreComponent,
    ManageTeamsComponent,
    EditCalendarComponent,
    NewStaffComponent,
    ShowDislikeComponent,
    AddPlayersTeamComponent,
    MassEditDivisionsComponent,
    AddMatchComponent,
    ShowAnswersComponent,
    NewsModalComponent,
    NewCouponComponent
  ],
})
export class ModalsModule {}
