import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  exhaustMap,
  mergeMap,
} from "rxjs/operators";
import {
  BracketService,
  AuthService,
} from "src/app/services/service.index";
import { BracketParams } from "src/app/models/bracket/bracket-params";
import { EventDetailsResponse } from "src/app/models/event-details/event-details-response";
import { Router } from "@angular/router";
import * as brackets from "../actions/brackets.actions";

// Third libreries
import Swal from "sweetalert2";

// Services
import {
  NotificationService,
  AlertService,
} from "src/app/services/service.index";

@Injectable()
export class BracketEffects {
  constructor(
    private _notificationService: NotificationService,
    private _bracketService: BracketService,
    private actions$: Actions,
    public router: Router
  ) {
    //
  }



  public saveBracket$ = createEffect(() =>
    this.actions$.pipe(
      ofType<brackets.BracketLoadAction>(
        brackets.BracketsActionType.Save
      ),
      map((action) => action.payload),
        switchMap((params: BracketParams) =>
        this._bracketService.saveBracket(params).pipe(
          map((response: any) => {
            return new brackets.BracketLoadSuccessAction(response);
          }),catchError((error) => {
            console.log("EFECT SAVE ERROR",error)
            return of(new brackets.BracketLoadFailAction(error))
           }) 
        )
      )
    )
  )

  public getAllBracketsByEventId$ = createEffect(() =>
    this.actions$.pipe(
      ofType<brackets.BracketLoadAction>(
        brackets.BracketsActionType.GetByEventId
      ),
      map((action) => action.payload),
      switchMap((params: BracketParams) =>
        this._bracketService.getBracketsByDivisionId(params).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(
                    `${errorMsg}`,
                    `EventDetails`
                  );
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(
                  `${errorMsg}`,
                  `EventDetails`
                );
              }
            }
            console.log("LOAD ALL.........>",response)
            return new brackets.BracketLoadSuccessAction(response);
          }),
          catchError((error) => {
           return of(new brackets.BracketLoadFailAction(error))
          }
          )
        )
      )
    )
  );
}
