import { Component, OnInit} from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {SharedService, StripeService} from "src/app/services/service.index";
import { AuthService } from "src/app/services/service.index";


@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.css']
})
export class StripeComponent implements OnInit {
  stripeSubscription: boolean = false;
  stripeSuscriptionLoading: boolean = true; 
  


  stripe: any;  

  constructor(
    public http: HttpClient,
    public router: ActivatedRoute,
    public route: Router,
    public _serviceStripe:StripeService,
    public _authService: AuthService,
    private _sharedService: SharedService,
    ) { 
      this._sharedService.changeTitile("Subscription");
    }

  ngOnInit(): void {
    this.stripeSuscriptionLoading = true;    
    this._serviceStripe.getSubscriptionStripeStatus().subscribe((response: any) => {
      if (response.valid) {
        if (response.data.status == 'active') {
          this.route.navigate(['/superadmin/reports'])
        } else {
          this.stripeSubscription = true;
        }
      } else {
        this.stripeSubscription = true; 
      }      
      this.stripeSuscriptionLoading = false;
    });

   this.router.queryParams.subscribe(params => {
      const sessionId = params.session_id; 
      const paymentStatus = params.payment_status; 
      if(sessionId){          
        this.stateSessionStripe(sessionId);         
      }
    });
  }

  connectToStripe() {   
    this._serviceStripe.getStripeSubscription().subscribe((response:any)=>{                        
      if (response.valid) {            
        const url = response.data;        
        window.location.href = url;                
     }else{      
     }
   });
  }

  stateSessionStripe(sesionId: any) {
    this._serviceStripe.getSessionsStripeStatus({ 'sesionId': sesionId }).subscribe((response: any) => {
      if (response.valid) {
        if (response.data.status == 'complete' && response.data.payment_status == 'paid') {
          this.route.navigate(['/superadmin/reports'])            
        }
      }
    });

  }
}