import { Component, OnInit } from '@angular/core';
import { NewsFeedService } from '../../../services/news-feed/news-feed.service';
import { News } from '../../../models/news-feed/news';
import { NewsResponse } from 'src/app/models/news-feed/news-response';
import { MatDialog } from '@angular/material/dialog';
import { NewsModalComponent } from '../../../modals/add-news-feed/add-news-feed.component';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/service.index';



@Component({
  selector: "app-news-feed",
  templateUrl: "./news-feed.component.html",
  styleUrls: ["./news-feed.component.css"],
})
export class NewsFeedComponent implements OnInit {
  newsList: News[] = [];
  eventId: number;
  event: any;


  constructor(
    private newsFeedService: NewsFeedService,
    private _eventService: EventService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.eventId = params.eventId;
      if(this.eventId){
        //Get Event
        this._eventService.getById({idEvent:this.eventId}).subscribe((response:any)=>{

          if(response.valid){
            this.event = response.data;
            // localStorage.setItem('event', JSON.stringify(this.event));
            console.log('event', this.event)
          }
        });
      }
          this.loadNewsFeed();
    });
  }

  loadNewsFeed() {
    if (this.eventId) {
      this.newsFeedService.getAllByEvent(this.eventId).subscribe(
        (response: NewsResponse) => {
          if (response.valid) {
            this.newsList = response.data;
          } else {
            console.error('Error fetching news feed', response.errors);
          }
        },
        (error) => {
          console.error('Error fetching news feed', error);
        }
      );
    }
  }

  openCreateModal() {
    const dialogRef = this.dialog.open(NewsModalComponent, {
      // width: '600px',
      data: { mode: "create", eventId: this.eventId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "success") {
        this.loadNewsFeed();
      }
    });
  }

  openEditModal(news: News) {
    const dialogRef = this.dialog.open(NewsModalComponent, {
      // width: '600px',
      data: { mode: "edit", news, eventId: this.eventId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "success") {
        this.loadNewsFeed();
      }
    });
  }
  onNewsDeleted(newsId: number): void {
    this.newsList = this.newsList.filter(news => news.id !== newsId);
  }
  // newsFeedByEvent(eventId: any) {
  //   window.location.href = `/#/superadmin/news-feed-by-event/${eventId}`;
  // }
}
