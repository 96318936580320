import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSelectChange } from '@angular/material';
import { EventDetailsService, EventService, NotificationService, SharedService,EnventTeams,PlayerMatchesStatsService } from 'src/app/services/service.index';
import * as moment from "moment";
import { UserService } from '../../../services/user/user.service';
@Component({
  selector: 'app-notifications',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  events;  
  divisions;  
  teams;
  team;
  division;
  event;
  type;
  message;
  statTeam;
  data: any[]; 

  displayedColumns: string[] = [   
    'name_Team', 
    'name_Player',
    'passingYards',
    'passingTouchdowns',
    'rushingYards',
    'rushingTouchdowns',
    'receivingYards',
    'receivingTouchdowns',
    'tackles',
    'sacks',
    'interceptions',
    'defensiveTouchdown',
  ];
  selectedDivision: any; 
  constructor(
    public dialog: MatDialog,
    private _sharedService: SharedService,
    private _eventService: EventService,
    private divService:EventDetailsService,
    private _eventTeams:EnventTeams,
    private _PlayerMatchesStatsService: PlayerMatchesStatsService,
    private noti:NotificationService,
    private usrService:UserService,
    private eventService:EventService
  ) {
    this._sharedService.changeTitile("Reports");
   }

  ngOnInit() {
    this._eventService.getByUser().subscribe((res:any)=>{
      if (res.valid && res.data.length > 0) {
        this.events = res.data.map((element: any) => ({
          id:element.idEvent,name:element.name
        }));
      } 
    })
  }
  
  async changeEvents(event:MatSelectChange){

    let divisions:any = await this.divService.getByEvent(event.source.value).toPromise();                
    this.divisions = divisions.data.map((element)=>{
      return {
        name:element.division_name,
        division_id: element.id,        
        eventId: element.idEvent.idEvent,       
        idEvent: element.idEvent.id       
      }
    });
  }
  async changeDivision(event: MatSelectChange) {

    this.teams = undefined;
    this.data=[];

    let teamsEvent: any = await this._eventTeams.getByEventId({ 'eventId': event.value.eventId, 'divisionId': event.value.division_id }).toPromise();    
    if (teamsEvent.data.length > 0) {      
      this.teams = teamsEvent.data.map((element) => {
        return {
          name: element.teamId.name,
          idTeam: element.teamId.teamId,
          division_id: event.source.value.division_id,
          EventId: event.source.value.eventId,
          idEvent: event.source.value.idEvent,
        }
      });     
    } else {
      this.team = undefined;
    }
  }

  async changeTeam(event:MatSelectChange) { 

  }
  
  exportToCsv(): void {
    const csvData = this.data;  
    const csvContent = this.convertArrayToCSV(csvData);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'stats-data.csv';
    link.click();
  }
  private convertArrayToCSV(data: any[]): string {
    const csvArray = [];
    const headers = Object.keys(data[0]);
    csvArray.push(headers.join(','));

    data.forEach((item) => {
      const row = headers.map((field) => item[field]);
      csvArray.push(row.join(','));
    });
    return csvArray.join('\n');
  }

  async getStats() {    

    if (this.event && this.division && this.team) {      
      this.data = [];
      if (this.team !== "all") {
        try {         
          this.statTeam = await this._PlayerMatchesStatsService.getByPlayerStatByTeam({
            'idTeam':this.team,
            'division_id': this.division.division_id,
            'eventid':  this.division.idEvent
          }).toPromise();
        } catch (error) {
          console.error('Error fetching player stats:', error);
        }
        this.data = this.statTeam.data;
      } else {        
        this.statTeam = await this._PlayerMatchesStatsService.getByPlayerStatByTeamsAll({
          'division_id': this.division.division_id,
          'eventid': this.division.eventId
        }).toPromise();
        this.data = this.statTeam.data;
      }
    } else {
      this.noti.showError("Filters required", "Error");
      this.data=[];
    }
  }

}
