import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Fields } from "../../models/fields/fields";

export interface StateFields extends EntityState<Fields> {
  fields: any;
  error: boolean;
  loading: boolean;
}

export const fieldsAdapter: EntityAdapter<Fields> = createEntityAdapter<Fields>({});

export const initialStateFields: StateFields = fieldsAdapter.getInitialState({
  fields: null,
  error: false,
  loading: true,
});
