import { Component, OnInit } from "@angular/core";
import {PlayerService} from "../../services/service.index";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: "app-site",
  templateUrl: "./player_invitation.component.html",
  styleUrls: ["./player_invitation.component.css"],
})
export class PlayerInvitationComponent implements OnInit {

  team_name: string;
  already_accepted: boolean = false;
  exist_waivers: boolean = false;
  waivers: any[];
  accepted: boolean = false;
  invalid: boolean = false;
  expired: boolean = false;
  playerId: number;
  invitation_code: string;

  constructor(
    private route: ActivatedRoute,
    private playerService: PlayerService
  ) {

    route.params.subscribe((params) => {
      this.playerId = params.playerId;
      this.invitation_code = params.invitation_code;
      playerService.acceptInvitation(params.playerId,params.invitation_code).subscribe((response: any) => {
        if(response.valid){
          this.accepted = false;
          this.already_accepted = false;
          this.expired = false;
          this.invalid = false;
          this.exist_waivers = false;
          this.team_name = response.data.teamName;
          if(response.data.currentStatus === "Waivers"){
            this.exist_waivers = true;
            this.waivers = response.data.waivers;
          }
          if(response.data.currentStatus === "Pending"){
            this.accepted = true;
          } else if(response.data.currentStatus === "Accepted"){
            this.already_accepted = true;
          } else if(response.data.currentStatus === "Expired"){
            this.expired = true;
          }
        } else {
          this.accepted = false;
          this.already_accepted = false;
          this.expired = false;
          this.invalid = true;
          this.exist_waivers = false;
        }
      })
    })
  }

  ngOnInit() {

  }

  checkWaivers(){
    for(let waive of this.waivers){
      if(!waive.accepted){
        return true;
      }
    }
    return false;
  }
  acceptWaivers(){
    this.playerService.acceptWaivers(this.playerId,this.invitation_code).subscribe((response: any) => {
      if(response.valid){
        this.already_accepted = false;
        this.expired = false;
        this.invalid = false;
        this.exist_waivers = false;
        this.accepted = true;
      } else {
        this.accepted = false;
        this.already_accepted = false;
        this.expired = false;
        this.invalid = true;
        this.exist_waivers = false;
      }
    })
  }
}
