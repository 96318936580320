import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { TypeEvent } from "../../models/type-event/type-event";

export interface StateTypeEvent extends EntityState<TypeEvent> {
  typeEvent: any;
  error: boolean;
  loading: boolean;
}

export const typeEventAdapter: EntityAdapter<TypeEvent> = createEntityAdapter<TypeEvent>({});

export const initialStateTypeEvent: StateTypeEvent = typeEventAdapter.getInitialState({
  typeEvent: null,
  error: false,
  loading: true,
});
