import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateEvent, eventAdapter } from '../../store/states/event.state';

export const {
  selectIds: _selectEventDataIds,
  selectEntities: _selectEventEntities,
  selectAll: _selectAllEvent,
  selectTotal: _selectEventTotal
} = eventAdapter.getSelectors();

export const selectState = createFeatureSelector<StateEvent>('event');

export const selectEventIds = createSelector(
  selectState,
  _selectEventDataIds
);

export const selectEventEntities = createSelector(
  selectState,
  _selectEventEntities
);

export const selectAllEvent = createSelector(
  selectState,
  _selectAllEvent
);

export const selectEventResponse = createSelector(
  selectState,
  (state: StateEvent): any => state.event
);

export const selectEventSearchResponse = createSelector(
  selectState,
  (state: StateEvent): any => state.eventSearch
);

export const selectEventError = createSelector(
  selectState,
  (state: StateEvent): boolean => state.error
);

export const selectEventLoading = createSelector(
  selectState,
  (state: StateEvent): any => state
);
