import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Gender } from "../../models/gender/gender";

export interface StateGender extends EntityState<Gender> {
  gender: any;
  error: boolean;
  loading: boolean;
}

export const genderAdapter: EntityAdapter<Gender> = createEntityAdapter<Gender>({});

export const initialStateGender: StateGender = genderAdapter.getInitialState({
  gender: null,
  error: false,
  loading: true,
});
