import { Component, OnInit } from "@angular/core";
import { SharedService } from "src/app/services/service.index";
@Component({
  selector: "app-addons",
  templateUrl: "./addons.component.html",
  styleUrls: ["./addons.component.css"],
})
export class AddonsComponent implements OnInit {
  constructor(private _sharedService: SharedService) {
    this._sharedService.changeTitile("Addons");
  }

  ngOnInit() {
    //
  }
}
