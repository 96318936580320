import { Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  DateAdapter,
  MatSelect,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {AuthService, BracketService, EventService, SharedService} from "src/app/services/service.index";
import Swal from "sweetalert2";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SharedDataService } from "src/app/utils/shared";
import { NewBracketsComponent } from "src/app/modals/new-brackets/new-brackets.component";
import {ActivatedRoute, Router} from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NewGameComponent } from 'src/app/modals/new-game/new-game.component';
import {Event} from "../../../models/event/event";
import { EditSingleComponent } from 'src/app/modals/edit-single/edit-single.component';
import { AddStatsComponent } from 'src/app/modals/add-stats/add-stats.component';
import { UpdateScoreComponent } from "src/app/modals/update-score/update-score/update-score.component";


export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD-MM-YY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-pools-games-double",
  templateUrl: "./pool-games-double.component.html",
  styleUrls: ["./pool-games-double.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PoolGamesDoubleComponent implements OnInit {
  @ViewChild('matSelectNumber', {static:false}) childN: MatSelect;
  @ViewChild('matSelectPool', {static:false}) childP: MatSelect;
  pools;
  current_pool_teams = [];
  current_selected_pool_teams = [];
  current_pool:any;
  pool_ngames = [];
  total_games = [];
  event: Event;
  division_id: number;
  eventId;
  bracket_id:number;
  isedition:boolean = false;
  tournament:any = [];
  losers:any = [];
  finals:any = [];
  rounds_loser_sizes = ['g-1'];
  rounds_loser_sizes_counter = 1;
  idEVE: any;
  dialogRef: MatDialogRef<any>;
  role_score_stats: false;

  constructor(
    public sanitizer: DomSanitizer,

    public route: ActivatedRoute,
    public router: Router,
    private service:BracketService,
    private _sharedService: SharedService,
    public dialog: MatDialog,
    private _eventService: EventService,
    private _authService: AuthService,
    private shared:SharedDataService  ) {

    this._sharedService.changeTitile("");
    route.params.subscribe((params) => {
      const eventId = params.eventId;
      this.idEVE = params.eventId;
      this.eventId = params.eventId;
      this.division_id = params.division_id;
      this.bracket_id = params.bracket_id;
      //Get Event
      this._eventService.getById({idEvent: this.eventId}).subscribe((response: any) => {
        if (response.valid) {
          this.event = response.data;

          //Get And Check Permissions
          this._authService.getRolesByEvent(this.eventId).subscribe((response: any) => {
            if (response.valid) {
              this.role_score_stats = response.data.some(role => role.role === "Scoring/Stats");
            } else {
              console.log(response);
            }
          });

        }
      });
    });
  }

  format;
  single = [];
  rounds = [[]];
  last_rounds = [];
  bye_teams = [];
  has_bye_teams = false;
  counter = 0;
  pools_ids = [];
  match_losers = [];
  bye_losers = [];
  count = 0;
  async ngOnInit() {
    Swal.close();
    Swal.showLoading();
    this.route.params.subscribe(async (params) => {
         this.pools = await this.service.getPoolGames(params.bracket_id);
         if(this.pools.data.Pools.length > 0){
           this.pools_ids = this.pools.data.Pools.map(e=>e.id)

           let first_pool = this.pools.data.Pools[0];

           if(first_pool.number_games > 0){
             this.router.navigate(['/superadmin/event',this.event.id,'division/double/',this.division_id,'pools/matches/',params.bracket_id])
           }
           this.format = this.pools.data.format;
             let response:any = await this.service.getDoubleGames(this.bracket_id).toPromise();
              this.tournament = response.data.main;
              this.losers = response.data.loser;
              this.finals = response.data.final;
              if(this.tournament.length > 0){
                this.gameFromserver();
                console.log(this.rounds);
                console.log("Testing !!!!!!!!!!!!!!!!");
                return
              }
              this.single = this.generateSingleEliminationTournament( this.pools.data.Pools); // Just Getting Teams
              let total = [...this.single].length;
              const numRounds = this.calculateRounds( Math.ceil(total / 2)); //Calculates the number of rounds according to the logarithm in base 2
              let n_bye = this.calculateByeTeams(this.single.length);// Calculate the amount of excess teams on the "Power of Two" (BYEs).
              this.bye_teams = this.single.splice(0, n_bye);// Return and Remove excess of teams

              let round2=[]

              // This For Works just for 1st and 2nd round(Just if there are BYE teams)
              for(let i =0; i<=numRounds;i++){
                console.log(i);
                let rounds_test = [...this.rounds];
                console.log("Checking rounds",rounds_test);
                if(i === 1){ // Second round (But Setting First Round) verifyx
                     this.rounds[0].push(this.intoPairs(this.single)); // Setting First Round
                     this.counter++;
                }else if(i===2){ // Third round (But working on Second round) verifyx
                    await this.shared.asyncForEach(this.rounds[0][0],(e,i)=>{ // Why Async?
                        if(this.bye_teams[i]){
                          round2.push([`Winner Game ${i+1}`,this.bye_teams[i],this.counter++ ]); // Creating Games with BYEs Teams on 2nd Round
                        }
                    })
                    if(this.bye_teams.length>0){
                        this.has_bye_teams = true;
                        let bye_length = this.bye_teams.length;
                         this.bye_teams.splice(0,round2.length);
                         for(let i =0; i<this.bye_teams.length;i+=2){ // This works if there are more byes teams than 1st Round
                          round2.push([this.bye_teams[i],this.bye_teams[i+1],this.counter++ ]);
                         }

                         while(bye_length<this.rounds[0][0].length){ // Completing Games from 1rst Round Winners
                            round2.push([`Winner Game ${bye_length+2}`,`Winner Game ${bye_length+1}`,this.counter++ ]);
                            bye_length+=2;
                         }
                    }
                    if(round2.length>0){
                      this.rounds.splice(1,0,[round2]) // Adding Round 2 on Rounds Array
                    }
                }
              }

              this.generateFirstsLosersGames();

              let next = this.rounds[1] ? this.rounds[1][0].length : this.rounds[0][0].length; // next = Number Games of Last Round (1st or 2nd)

              let gmes = [];
              let counter=this.rounds[1] ? this.rounds[1][0][0][2]-1 : 0; // IMPORTANT [2] is the number of the game
              let first_time = true;
              while(next>=2){ // Completing Mathematically all the rounds between Winners
                let aux = [];
                let last_round = first_time ? this.rounds[this.rounds.length - 1][0] : this.rounds[this.rounds.length - 1];
                first_time = false;
                for(let i=0;i<next;i+=2){
                   aux.push([`Winner Game ${last_round[i][2]}`,`Winner Game ${last_round[i + 1][2]}`,++this.counter]);
                   counter+=2;
                }

                this.rounds.push(aux);

                //Adding Loser Games
                if(aux.length !== this.match_losers[this.match_losers.length - 1].length){
                  this.createNextLoserRound();
                }

                this.addLosersToLosersBracket();


                next /= 2;
              }

              const current_last_round = this.rounds[this.rounds.length - 1][0];
              const current_last_losers = this.match_losers[this.match_losers.length - 1][0];


              this.last_rounds.push([`Winner Game ${current_last_losers[2]}`,`Winner Game ${current_last_round[2]}`,++this.counter])

              this.last_rounds.push([`Winner Game ${this.counter}`,`Loser Game ${this.counter}`,++this.counter])


           this.save_double([...this.rounds]);

         }
         Swal.close();
    })
  }

  addLosersToLosersBracket(){
    var last_round = this.rounds[this.rounds.length - 1];
    let last_round_loser = this.match_losers[this.match_losers.length - 1];
    console.log("Test Rounds",last_round,last_round_loser);
    let next_loser_round = [];
    for(let i=0;i<last_round_loser.length;i++){
      next_loser_round.push([`Winner Game ${last_round_loser[i][2]}`,`Loser Game ${last_round[i][2]}`,++this.counter]);
    }
    this.rounds_loser_sizes.push(`g-${this.rounds_loser_sizes_counter}`);
    this.match_losers.push(next_loser_round);
  }

  createNextLoserRound(){
    let last_round_loser = this.match_losers[this.match_losers.length - 1];

    let next_loser_round = [];
    for(let i=0;i<last_round_loser.length;i+=2){
      next_loser_round.push([`Winner Game ${last_round_loser[i + 1][2]}`,`Winner Game ${last_round_loser[i][2]}`,++this.counter]);
    }
    this.rounds_loser_sizes_counter = this.rounds_loser_sizes_counter * 2;
    this.rounds_loser_sizes.push(`g-${this.rounds_loser_sizes_counter}`);
    this.match_losers.push(next_loser_round);
  }

  generateFirstsLosersGames(){
    this.counter--;
    let teams_losers = [];
    let first_round = this.rounds[0][0];
    console.log(first_round);
    first_round.map((match) => teams_losers.push("Loser Game "+ match[2])); // [2] = number of the Game
    if(this.has_bye_teams){ // if there are Bye teams we have to add 2nd round
      let second_round = this.rounds[1][0];
      second_round.map((match) => teams_losers.push("Loser Game "+ match[2]));
    }

    let n_bye_losers = this.calculateByeTeams(teams_losers.length);
    this.bye_losers = teams_losers.splice(0, n_bye_losers);// Return and Remove excess of teams
    this.match_losers.push(this.intoPairs(teams_losers)); // Setting First Round

    //Setting Byes Losers Teams from First Round
    let round_byes_losers = [];

    let bye_i = 0;
    for(const match_loser of this.match_losers[0]){
      if(this.bye_losers[bye_i]){
        round_byes_losers.push([`Winner Game ${match_loser[2]}`,this.bye_losers[bye_i],++this.counter ]); // Creating Games with BYEs Teams on 2nd Round
      }
      bye_i++;
    }


    if(this.bye_losers.length>0){
      let bye_length = this.bye_losers.length;
      let bye_losers_copy = [...this.bye_losers];
      bye_losers_copy.splice(0,round_byes_losers.length);
      for(let i =0; i<bye_losers_copy.length;i+=2){ // This works if there are more byes teams than 1st Round
        round_byes_losers.push([bye_losers_copy[i+1],bye_losers_copy[i],++this.counter ]);
      }

      while(bye_length<this.match_losers[0].length){ // Completing Games from 1rst Round Winners
        round_byes_losers.push([`Winner Game ${bye_length+2}`,`Winner Game ${bye_length+1}`,++this.counter ]);
        bye_length+=2;
      }
    }

    if(round_byes_losers.length>0){
      this.match_losers.push(round_byes_losers); // Adding Round 2 on Rounds Array
      this.rounds_loser_sizes_counter = this.rounds_loser_sizes_counter * 2;
      this.rounds_loser_sizes.push(`g-${this.rounds_loser_sizes_counter}`);
    }
  }



  gameFromserver(){
    let rounds = this.groupByRounds(this.tournament);
    console.log("1111111", rounds)
    let nested = rounds["2"].filter(e=>!e.alias);
    this.counter++;
    this.bye_teams = [];


    for(let key in rounds){
      let matches = [];
      rounds[key].forEach(e=>{

        if(key === '2') {// possible byes round
          if(e.matchesElimination && e.matchesElimination.teamRelatedVisitor === null && e.matchesElimination.teamRelatedLocal !== null)
            this.bye_teams.push("exist");
        }

        if(!e.matchesElimination){
          matches.push([e.teamLocal,e.teamVisitor,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,'A']);
        }else if(e.matchesElimination && e.matchesElimination.match.length === 1){
          if (e.teamLocal && e.teamVisitor){
            matches.push([e.teamLocal, e.teamVisitor,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,e.teamLocal.id,e.teamVisitor.id,'BBB']);
          }else if(!e.teamLocal && !e.teamVisitor){
            matches.push([e.matchesElimination.match[0].Teams.name,e.matchesElimination.match[1].Teams.name,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,0,0,'C']);
          }else if(e.teamLocal && !e.teamVisitor){
            matches.push([ e.matchesElimination.match[1].Teams.name,e.teamLocal,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,0,e.teamLocal.id,'D']);
          }else if(!e.teamLocal && e.teamVisitor){
            matches.push([e.matchesElimination.match[0].Teams.name,e.teamVisitor,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,0,e.teamVisitor.id,'E']);
          }
        }else if(e.matchesElimination && e.matchesElimination.match.length === 2){
          if (e.teamLocal && e.teamVisitor){
            matches.push([ e.teamLocal.Teams.name, e.teamVisitor.Teams.name,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,e.teamLocal.Teams.id, e.teamVisitor.Teams.id,'F']);
          }else if(!e.teamLocal && !e.teamVisitor){
            matches.push([  e.matchesElimination.match[0].Teams.name,e.matchesElimination.match[1].Teams.name,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,0,0,'G']);
          }else if(e.teamLocal && !e.teamVisitor){
            matches.push([e.teamLocal.Teams.name, e.matchesElimination.match[1].Teams.name,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,e.teamLocal.Teams.id, 0,'H']);
          }else if(!e.teamLocal && e.teamVisitor){
            matches.push([e.matchesElimination.match[0].Teams.name,e.teamVisitor.Teams.name,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,0,e.teamVisitor.Teams.id,'I']);
          }
        }//visitor caja abajo

      })

        if(Number(key) === 1){
          this.rounds[0].push(matches);
        }else if(Number(key) === 2){
          this.rounds.splice(Number(key),0, nested.length>0 ? matches: [matches] )
        }else{
          this.rounds.splice(Number(key),0,matches)
        }
    }

    //Double Elimination
    let rounds_losers = this.groupByRounds(this.losers);
    for(let key_loser in rounds_losers){
      let matches = [];

      //Adding Dinamic sizes
      if(key_loser === '2') {
        this.rounds_loser_sizes_counter = this.rounds_loser_sizes_counter * 2;
        this.rounds_loser_sizes.push(`g-${this.rounds_loser_sizes_counter}`);
      } else if(Number(key_loser) > 2 ){
        if(rounds_losers[key_loser].length !== rounds_losers[Number(key_loser) - 1].length) {
          this.rounds_loser_sizes_counter = this.rounds_loser_sizes_counter * 2;
        }
        this.rounds_loser_sizes.push(`g-${this.rounds_loser_sizes_counter}`);
      }

      rounds_losers[key_loser].forEach(e=>{
        if(key_loser === '2') {// possible byes round
          if(e.matchesElimination.seedVisitor === "loser" && e.matchesElimination.seedLocal === "winner")
            this.bye_losers.push("exist");
        }

        if(!e.matchesElimination){
          matches.push([e.teamLocal,e.teamVisitor,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,'LA']);
        }else if(e.matchesElimination && e.matchesElimination.match.length === 1){
          if (e.teamLocal && e.teamVisitor){
            matches.push([e.teamLocal, e.teamVisitor,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,e.teamLocal.id,e.teamVisitor.id,'LB']);
          }else if(!e.teamLocal && !e.teamVisitor){
            matches.push([e.matchesElimination.match[0].Teams.name,e.matchesElimination.match[1].Teams.name,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,0,0,'LC']);
          }else if(e.teamLocal && !e.teamVisitor){
            matches.push([ e.matchesElimination.match[1].Teams.name,e.teamLocal,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,0,e.teamLocal.id,'LD']);
          }else if(!e.teamLocal && e.teamVisitor){
            matches.push([e.matchesElimination.match[0].Teams.name,e.teamVisitor,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,0,e.teamVisitor.id,'LE']);
          }
        }else if(e.matchesElimination && e.matchesElimination.match.length === 2){
          if (e.teamLocal && e.teamVisitor){
            matches.push([ e.teamLocal.Teams.name, e.teamVisitor.Teams.name,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,e.teamLocal.Teams.id, e.teamVisitor.Teams.id,'LF']);
          }else if(!e.teamLocal && !e.teamVisitor){
            matches.push([  e.matchesElimination.match[0].Teams.name,e.matchesElimination.match[1].Teams.name,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,0,0,'LG']);
          }else if(e.teamLocal && !e.teamVisitor){
            matches.push([e.teamLocal.Teams.name, e.matchesElimination.match[1].Teams.name,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,e.teamLocal.Teams.id, 0,'LH']);
          }else if(!e.teamLocal && e.teamVisitor){
            matches.push([e.matchesElimination.match[0].Teams.name,e.teamVisitor.Teams.name,e.name,e.id,e.scoreLocal,e.scoreVisitor,e.finalized,0,e.teamVisitor.Teams.id,'LI']);
          }
        }//visitor caja abajo

      });

      this.match_losers.push(matches);
    }
   // console.log("22222", this.rounds);
    //console.log("333333", this.match_losers);
    //console.log("444444", this.bye_losers);
  }

  onChange(){
    this.isedition = !this.isedition ;
    console.log(this.isedition)
  }

  save_double(tournament){
    try{
        if(this.nestedStructure(tournament[1][0])){
            tournament[1] =  tournament[1].flat();
        }
        tournament[0] =  tournament[0].flat();
        this.service.saveDoubleGames({tournament:tournament,loser: this.match_losers, last_rounds: this.last_rounds, bracket_id: this.bracket_id }).toPromise().then(async (e:any)=>{
          if(e.valid){
            let response:any = await this.service.getDoubleGames(this.bracket_id).toPromise();
            this.tournament = response.data.main;
            this.losers = response.data.loser;
            this.finals = response.data.final;
            this.counter=0;
            this.rounds = [[]];
            this.gameFromserver()
          }
        })

    }catch(e){
      console.log(e);
    }
  }

  groupByRounds(arr){
    return arr.reduce(function(result, obj) {
      var key = obj.round;
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(obj);
      return result;
    }, {});
  }

  calculateByeTeams(totalTeams) {
    let powerOfTwo = 1;
    while (powerOfTwo < totalTeams) {
      powerOfTwo *= 2;
    }
    return powerOfTwo - totalTeams;
  }
  getElementsIndex(n){
    return Array.from({ length: n + 1 }, (_, index) => index);
  }
  isWinnerOf(obj){
    return typeof obj === "string" ? true : false;
  }
  nestedStructure(arr) {
    if(Array.isArray(arr[0])){
      return true;
    }else{
      return false
    }
  }
  add_ngames(event){
    let save_customs;
    const index = this.pool_ngames.findIndex((obj) => obj && obj.id === this.current_pool.id);
    this.current_pool.ngames = event.value;
    if (index !== -1) {
      save_customs = this.pool_ngames[index].games.filter(e=>e.custom);
      this.pool_ngames.splice(index, 1);
    }
    this.current_selected_pool_teams = this.current_selected_pool_teams.filter(e => e!==null);
    this.current_selected_pool_teams.forEach(obj => {
      if(obj.gamesPlayed){
        delete obj['gamesPlayed'];
      }
    });


    this.createGames();
    if(save_customs && save_customs.length>0){
      save_customs.forEach(e=>{
        this.current_pool_teams.unshift(e);
      });
    }
    this.pool_ngames.push({id:this.current_pool.id,bracket_id:this.current_pool.bracket_id,ngames:this.current_pool.ngames,games:this.current_pool_teams});
  }
  getImage(image){
    return this.sanitizer.bypassSecurityTrustResourceUrl(atob(image));
  }
  calculateRounds(numMatches) {
    const rounds = Math.ceil(Math.log2(numMatches));
    return rounds+1;
  }
  switchPool(event){
    this.current_pool_teams = this.createMatches(this.pools.data.Pools.filter(e=>e.id === event.value)[0].PoolTeams);
    this.current_selected_pool_teams = this.pools.data.Pools.filter(e=>e.id === event.value)[0].PoolTeams;
    this.total_games = [...Array((this.current_selected_pool_teams.filter(e=> e !== null).length-1)).keys() ];
    this.current_pool =  this.pools.data.Pools.filter(e=>e.id === event.value)[0];
    if(this.current_pool.ngames){
      this.childN.value = this.current_pool.ngames;
      this.add_ngames( {
        value:this.current_pool.ngames
      })
    }else{
      this.childN.value = "";
    }
  }
  open_pencil(obj){

    let teams = this.pools.data.Pools.map(e=>e.PoolTeams).flat();//
    let wteam0 = this.getKeyFromValue(obj[0].id);
    let wteam1 = this.getKeyFromValue(obj[1].id);

    if(!wteam0 && !wteam1){
      wteam0 = "poolTeamLocal";
      wteam1 = "poolTeamVisitor";
    }else if(!wteam0 && wteam1=="poolTeamLocal"){
      wteam0 = "poolTeamVisitor";
    }else if(!wteam0 && wteam1=="poolTeamVisitor"){
      wteam0 = "poolTeamLocal";
    }else if(!wteam1 && wteam0=="poolTeamLocal"){
      wteam1 = "poolTeamVisitor";
    }else if(!wteam1 && wteam0=="poolTeamVisitor"){
      wteam1 = "poolTeamLocal";
    }else if (wteam0 == wteam1){
      wteam0 = "poolTeamLocal";
      wteam1 = "poolTeamVisitor";
    }

    const dialogRef = this.dialog.open(EditSingleComponent, {
      width: "50%",
      height: "auto",
     data: { values : teams, game: `${obj[2]}`, current:{ [wteam0]:obj[0],[wteam1]:obj[1] } },
    });//1 home 0 away
    dialogRef.afterClosed().subscribe(async (result) => {
      if(!result) return false;
      let match = { [wteam0]:obj[0].id,[wteam1]:obj[1].id }

      await this.service.updateTeams({old_away:match.poolTeamVisitor,new_away:result.away,old_home: match.poolTeamLocal,new_home:result.home,id:obj[3],name:result.name}).toPromise();
      let response:any = await this.service.getDoubleGames(this.bracket_id).toPromise();
      this.tournament = response.data.main;
      this.losers = response.data.loser;
      this.finals = response.data.final;

      if(this.tournament.length > 0){
        this.counter=0;
        this.rounds = [[]];
        this.gameFromserver()
      }
    });
  }
  getKeyFromValue(value) {
    if(!value) return;
    for (let i = 0; i < this.tournament.length; i++) {
      const obj =  this.tournament[i];
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === value) {
          return key;
        }
      }
    }
  }
  generateSingleTeam(arr) {
    const pairs = [];
    const singles = [];
    for (let i = 0; i < arr.length - 1; i += 2) {
      pairs.push([arr[i], arr[i + 1]]);
    }
    if (arr.length % 2 !== 0) {
      singles.push(arr[arr.length - 1]);
    }
    if(pairs.length>0){
      this.single.concat(pairs);
    }
    return singles
  }

  intoPairs(mapped) {
    const pares = [];
    for (let i = 0; i < mapped.length; i += 2) {
      let element1 = mapped[i];
      let element2 = mapped[i + 1];
      element1 && delete element1.userId;
      element2 && delete element2.userId;
      element2 && delete element2.logo;
      element1 && delete element1.logo;
      this.counter+=1;
      pares.push( [ element1||'bye', element2||'bye',this.counter]);
    }

    return pares;
  }

  generateSingleEliminationTournament(pools) {
    let matches = [];
    pools.forEach(pool => {
      pool.PoolTeams.forEach(team => {
        matches.push(team);
      });
    });

    return matches;
  }

  getFormatedTeam(pools){
    let teams = [];
    pools.forEach((pool) => {
        const team1 = pool.Teams ? pool.Teams : (pool.PoolTBD ? pool.PoolTBD : 'PoolTBD');
        teams.push(team1);

    });
    return teams;
  }


  createMatches(players) {
    let n = players.length;
    const schedule = [];
    if (n % 2 !== 0) {
      players.push(null);
      n++;
    }
    for (let i = 0; i < n - 1; i++) {
      const round = [];
      for (let j = 0; j < n / 2; j++) {
        if (players[j] && players[n - j - 1]) {
          round.push([players[j], players[n - j - 1]]);
        }
      }
      schedule.push(round);
      players.splice(1, 0, players.pop());
    }

    return schedule.flat();
  }

  newGame() {
    const dialogRef = this.dialog.open(NewGameComponent, {
      width: "50%",
      height: "auto",
      data: { values : [...this.current_selected_pool_teams] },
    });

    dialogRef.afterClosed().subscribe((result) => {
        if(result){
            result.custom = true;
            this.current_pool_teams.unshift(result);
           // this.current_selected_pool_teams.unshift(result);
            const index = this.pool_ngames.findIndex((obj) => obj.id === this.current_pool.id);
            if (index !== -1) {
              this.pool_ngames[index].games = this.current_pool_teams;
            }
        }
        //console.log(this.current_pool_teams);
       // console.log(this.current_selected_pool_teams);
    });
  }
  async save_games(data:any){
    try{
        let response = await this.service.saveGames(
          {
            games: this.pool_ngames,
          }
        );
        if(response['valid']){
          setTimeout(() => {
            Swal.fire({
              title: "Success",
              text: "Games Created!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
            window.location.href =`/#/superadmin/event/${this.event.id}/division/${this.division_id}/pools/matches/${this.current_pool.bracket_id}`;
          }, 800);
        }
      }catch(e){
        throw e;
      }
  }

  createGames(){
     // let save_customs = this.current_pool_teams.filter(e=>e.custom);
      let teams = this.current_selected_pool_teams.filter(e=>!e.custom);
      const maxGamesPerTeam = this.childN.value;
      const schedule = [];
      this.shuffle(teams);
      for (let i = 0; i < teams.length - 1; i++) {
        const team1 = teams[i];
        for (let j = i + 1; j < teams.length; j++) {
          const team2 = teams[j];
          if (this.getGamesPlayed(team1) < maxGamesPerTeam && this.getGamesPlayed(team2) < maxGamesPerTeam) {
            schedule.push([team1,team2] /*{ home: team1.Teams.id, away: team2.Teams.id}*/);
            team1.gamesPlayed = (team1.gamesPlayed || 0) + 1;
            team2.gamesPlayed = (team2.gamesPlayed || 0) + 1;
          }
        }
      }
      this.current_pool_teams = [];
      this.current_pool_teams = schedule;
      /*save_customs.forEach(e=>{
        this.current_pool_teams.unshift(e);
      });*/
  }

  shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  getGamesPlayed(team) {
    return team.gamesPlayed || 0;
  }

  deepSearchAndReplace(arr, searchId, replaceObject) {
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      if (typeof item === 'object' && item !== null && item.hasOwnProperty('id') && item.id === searchId) {
        arr[i] = replaceObject; // Replace the object with the replace object
      }
      if (Array.isArray(item)) {
        this.deepSearchAndReplace(item, searchId, replaceObject);
      }
    }
  }

  addsStat(idMatch,idTeamLocal,idTeamVisitor) {
   let idEvent = this.eventId;
   let division_id = this.division_id;
   const dialogRef = this.dialog.open(AddStatsComponent, {
      width: "90%",
      data: {idMatch,idTeamLocal,idTeamVisitor,idEvent,division_id},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // After close
      }
    });
  }

    updateScore(idmatch : number,nameLocal:string,nameVisitor:string, localscore:number,visitorscore:number,finalized:any)
    {
      let finalizeScore: any = finalized;
      let bracket = this.bracket_id;
      let idEvent = this.eventId;
      let division_id = this.division_id;
      const dialogRef = this.dialog.open(UpdateScoreComponent, {
        width:"280px",
        data: {idmatch,localscore,visitorscore,finalizeScore,nameLocal,nameVisitor,bracket,idEvent,division_id},

      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.result==true) {
          window.location.reload();
        }
      });
    }

}







/*
[
  [
      [
          "Winner game 1",
          {
              "id": 1734,
              "pool_id": 236,
              "team_id": 53,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 53,
                  "name": "asasa",
                  "teamId": "af8bedb6-fd26-46dc-9153-555d43ad7dd9",
                  "logo": "/Users/jrequena/Git/apollo-apps/api-wesport/app/dist/images/image_1684960120836.png",
                  "dateCreated": "2023-05-25T00:28:40.000Z",
                  "userId": {
                      "idUser": 1,
                      "firstName": "Demo",
                      "lastName": "Luc",
                      "dateBirth": "2022-10-26",
                      "address": "",
                      "city": "",
                      "state": "Portugal",
                      "zipCode": "",
                      "phoneNumber": "123123",
                      "username": "Demo",
                      "email": "demo@demo.com",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "",
                      "inches": "",
                      "jerseyNumber": "18",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Admin",
                      "dateCreated": "2022-12-09T13:33:03.000Z",
                      "genderUser": {
                          "id": 2,
                          "gender": "Female",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          2
      ],
      [
          {
              "id": 1727,
              "pool_id": 236,
              "team_id": 52,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 52,
                  "name": "sasas",
                  "teamId": "660192f8-5b19-499a-82af-438df2d507fe",
                  "logo": "/Users/jrequena/Git/apollo-apps/api-wesport/app/dist/images/image_1684959966717.png",
                  "dateCreated": "2023-05-25T00:26:06.000Z",
                  "userId": {
                      "idUser": 1,
                      "firstName": "Demo",
                      "lastName": "Luc",
                      "dateBirth": "2022-10-26",
                      "address": "",
                      "city": "",
                      "state": "Portugal",
                      "zipCode": "",
                      "phoneNumber": "123123",
                      "username": "Demo",
                      "email": "demo@demo.com",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "",
                      "inches": "",
                      "jerseyNumber": "18",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Admin",
                      "dateCreated": "2022-12-09T13:33:03.000Z",
                      "genderUser": {
                          "id": 2,
                          "gender": "Female",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          {
              "id": 1725,
              "pool_id": 236,
              "team_id": 50,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 50,
                  "name": "Team 1",
                  "teamId": "0a7f5acd-f0d1-477a-8ded-d0540ca12928",
                  "dateCreated": "2023-01-31T21:14:50.000Z",
                  "userId": {
                      "idUser": 1,
                      "firstName": "Demo",
                      "lastName": "Luc",
                      "dateBirth": "2022-10-26",
                      "address": "",
                      "city": "",
                      "state": "Portugal",
                      "zipCode": "",
                      "phoneNumber": "123123",
                      "username": "Demo",
                      "email": "demo@demo.com",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "",
                      "inches": "",
                      "jerseyNumber": "18",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Admin",
                      "dateCreated": "2022-12-09T13:33:03.000Z",
                      "genderUser": {
                          "id": 2,
                          "gender": "Female",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          3
      ],
      [
          {
              "id": 1724,
              "pool_id": 236,
              "team_id": 49,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 49,
                  "name": "Adolfo",
                  "teamId": "1e00af57-7288-4b46-bac8-2c49d7252634",
                  "dateCreated": "2023-01-23T19:15:14.000Z",
                  "userId": {
                      "idUser": 14,
                      "firstName": "test",
                      "lastName": "test",
                      "dateBirth": "2022-11-24",
                      "address": "test",
                      "city": "test",
                      "state": "test",
                      "zipCode": "123123234",
                      "phoneNumber": "234234",
                      "username": "test3",
                      "email": "test3@test.com",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "6",
                      "inches": "8",
                      "jerseyNumber": "30",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Player",
                      "dateCreated": "2022-11-24T03:08:56.000Z",
                      "genderUser": {
                          "id": 1,
                          "gender": "Male",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          {
              "id": 1723,
              "pool_id": 236,
              "team_id": 49,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 49,
                  "name": "Adolfo",
                  "teamId": "1e00af57-7288-4b46-bac8-2c49d7252634",
                  "dateCreated": "2023-01-23T19:15:14.000Z",
                  "userId": {
                      "idUser": 14,
                      "firstName": "test",
                      "lastName": "test",
                      "dateBirth": "2022-11-24",
                      "address": "test",
                      "city": "test",
                      "state": "test",
                      "zipCode": "123123234",
                      "phoneNumber": "234234",
                      "username": "test3",
                      "email": "test3@test.com",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "6",
                      "inches": "8",
                      "jerseyNumber": "30",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Player",
                      "dateCreated": "2022-11-24T03:08:56.000Z",
                      "genderUser": {
                          "id": 1,
                          "gender": "Male",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          4
      ],
      [
          {
              "id": 1720,
              "pool_id": 236,
              "team_id": 47,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 47,
                  "name": "Uzoni Football Club",
                  "teamId": "54b6ce2e-3d9d-4095-a525-d25c1de38cc3",
                  "logo": "",
                  "dateCreated": "2023-01-17T18:35:22.000Z",
                  "userId": {
                      "idUser": 16,
                      "firstName": "test",
                      "lastName": "test",
                      "dateBirth": "2022-11-26",
                      "address": "test",
                      "city": "test",
                      "state": "test",
                      "zipCode": "123",
                      "phoneNumber": "123",
                      "username": "mobileuser",
                      "email": "mobileuser@wesport.live",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "3",
                      "inches": "1",
                      "jerseyNumber": "34",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Player",
                      "dateCreated": "2023-01-11T12:04:07.000Z",
                      "genderUser": {
                          "id": 1,
                          "gender": "Male",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          {
              "id": 1719,
              "pool_id": 236,
              "team_id": 46,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 46,
                  "name": "LSU Tigers",
                  "teamId": "c4c15cb2-2a48-41a9-9c54-75fb61220532",
                  "logo": "",
                  "dateCreated": "2022-12-14T18:19:22.000Z",
                  "userId": {
                      "idUser": 16,
                      "firstName": "test",
                      "lastName": "test",
                      "dateBirth": "2022-11-26",
                      "address": "test",
                      "city": "test",
                      "state": "test",
                      "zipCode": "123",
                      "phoneNumber": "123",
                      "username": "mobileuser",
                      "email": "mobileuser@wesport.live",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "3",
                      "inches": "1",
                      "jerseyNumber": "34",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Player",
                      "dateCreated": "2023-01-11T12:04:07.000Z",
                      "genderUser": {
                          "id": 1,
                          "gender": "Male",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          5
      ],
      [
          {
              "id": 1718,
              "pool_id": 236,
              "team_id": 45,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 45,
                  "name": "Tennessee Volunteers",
                  "teamId": "f972c1a1-b67f-4d24-b9a3-2dc440cbb8ca",
                  "logo": "",
                  "dateCreated": "2022-12-14T18:18:59.000Z",
                  "userId": {
                      "idUser": 16,
                      "firstName": "test",
                      "lastName": "test",
                      "dateBirth": "2022-11-26",
                      "address": "test",
                      "city": "test",
                      "state": "test",
                      "zipCode": "123",
                      "phoneNumber": "123",
                      "username": "mobileuser",
                      "email": "mobileuser@wesport.live",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "3",
                      "inches": "1",
                      "jerseyNumber": "34",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Player",
                      "dateCreated": "2023-01-11T12:04:07.000Z",
                      "genderUser": {
                          "id": 1,
                          "gender": "Male",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          {
              "id": 1717,
              "pool_id": 236,
              "team_id": 44,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 44,
                  "name": "Test",
                  "teamId": "adc272aa-0a7b-48d1-9719-7d5830e90952",
                  "dateCreated": "2022-11-28T17:24:13.000Z",
                  "userId": {
                      "idUser": 17,
                      "firstName": "Racheal",
                      "lastName": "Covington",
                      "dateBirth": "1998-03-28",
                      "address": "100 Abbey Road",
                      "city": "Clovis",
                      "state": "NM",
                      "zipCode": "88101",
                      "phoneNumber": "9729539654",
                      "username": "TESTING22",
                      "email": "rachealjcovington@hotmail.com",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "5",
                      "inches": "11",
                      "jerseyNumber": "44",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Player",
                      "dateCreated": "2022-12-01T18:38:17.000Z",
                      "genderUser": {
                          "id": 1,
                          "gender": "Male",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          6
      ],
      [
          {
              "id": 1716,
              "pool_id": 236,
              "team_id": 43,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 43,
                  "name": "San Lorenzo",
                  "teamId": "c44505ab-b3b9-4fd2-86dd-2a8ffcf75a1c",
                  "dateCreated": "2022-11-25T22:15:15.000Z",
                  "userId": {
                      "idUser": 15,
                      "firstName": "test",
                      "lastName": "test",
                      "dateBirth": "2022-11-25",
                      "address": "test",
                      "city": "test",
                      "state": "test",
                      "zipCode": "2323",
                      "phoneNumber": "2323",
                      "username": "adminuser",
                      "email": "adminuser@wesport.live",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "3",
                      "inches": "1",
                      "jerseyNumber": "30",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Player",
                      "dateCreated": "2023-01-11T13:00:22.000Z",
                      "genderUser": {
                          "id": 2,
                          "gender": "Female",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          {
              "id": 1715,
              "pool_id": 236,
              "team_id": 42,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 42,
                  "name": "Dragon",
                  "teamId": "2a3f6edb-04ed-4e5c-9b9d-7230c4578f07",
                  "dateCreated": "2022-11-24T04:57:42.000Z",
                  "userId": {
                      "idUser": 14,
                      "firstName": "test",
                      "lastName": "test",
                      "dateBirth": "2022-11-24",
                      "address": "test",
                      "city": "test",
                      "state": "test",
                      "zipCode": "123123234",
                      "phoneNumber": "234234",
                      "username": "test3",
                      "email": "test3@test.com",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "6",
                      "inches": "8",
                      "jerseyNumber": "30",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Player",
                      "dateCreated": "2022-11-24T03:08:56.000Z",
                      "genderUser": {
                          "id": 1,
                          "gender": "Male",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          7
      ],
      [
          {
              "id": 1714,
              "pool_id": 236,
              "team_id": 41,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 41,
                  "name": "Fenix",
                  "teamId": "a763b5b6-5835-4f36-8a0b-44ea9fd60af3",
                  "dateCreated": "2022-11-24T04:57:42.000Z",
                  "userId": {
                      "idUser": 13,
                      "firstName": "test",
                      "lastName": "test",
                      "dateBirth": "2022-11-24",
                      "address": "test",
                      "city": "test",
                      "state": "test",
                      "zipCode": "333434",
                      "phoneNumber": "3222323",
                      "username": "test2",
                      "email": "test2@test.com",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "5",
                      "inches": "5",
                      "jerseyNumber": "25",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Player",
                      "dateCreated": "2023-01-10T22:41:19.000Z",
                      "genderUser": {
                          "id": 1,
                          "gender": "Male",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          {
              "id": 1713,
              "pool_id": 236,
              "team_id": 40,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 40,
                  "name": "Apus",
                  "teamId": "e485e28a-fef9-4ed1-be34-aa18f3c1987a",
                  "dateCreated": "2022-11-24T04:57:42.000Z",
                  "userId": {
                      "idUser": 1,
                      "firstName": "Demo",
                      "lastName": "Luc",
                      "dateBirth": "2022-10-26",
                      "address": "",
                      "city": "",
                      "state": "Portugal",
                      "zipCode": "",
                      "phoneNumber": "123123",
                      "username": "Demo",
                      "email": "demo@demo.com",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "",
                      "inches": "",
                      "jerseyNumber": "18",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Admin",
                      "dateCreated": "2022-12-09T13:33:03.000Z",
                      "genderUser": {
                          "id": 2,
                          "gender": "Female",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          8
      ],
      [
          {
              "id": 1685,
              "pool_id": 236,
              "team_id": 37,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 37,
                  "name": "Newell",
                  "teamId": "9cb333ba-41ea-440a-886d-e336d8091a9e",
                  "logo": null,
                  "dateCreated": "2022-11-24T04:57:42.000Z",
                  "userId": {
                      "idUser": 2,
                      "firstName": "Juan",
                      "lastName": "Perez",
                      "dateBirth": "2022-10-27",
                      "address": "tester rd",
                      "city": "test",
                      "state": "Portugal",
                      "zipCode": "12345",
                      "phoneNumber": "3222233",
                      "username": "Juean",
                      "email": "test@test.com",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "",
                      "inches": "",
                      "idCardPicture": "",
                      "profilePicture": "",
                      "jerseyNumber": "20",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Player",
                      "dateCreated": "2023-01-18T16:41:56.000Z",
                      "genderUser": {
                          "id": 2,
                          "gender": "Female",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          {
              "id": 1684,
              "pool_id": 236,
              "team_id": 37,
              "pool_tbd_id": null,
              "created": "2023-06-08T04:06:39.000Z",
              "Teams": {
                  "id": 37,
                  "name": "Newell",
                  "teamId": "9cb333ba-41ea-440a-886d-e336d8091a9e",
                  "logo": null,
                  "dateCreated": "2022-11-24T04:57:42.000Z",
                  "userId": {
                      "idUser": 2,
                      "firstName": "Juan",
                      "lastName": "Perez",
                      "dateBirth": "2022-10-27",
                      "address": "tester rd",
                      "city": "test",
                      "state": "Portugal",
                      "zipCode": "12345",
                      "phoneNumber": "3222233",
                      "username": "Juean",
                      "email": "test@test.com",
                      "password": "$2a$10$/lw/RLghV8GDyb9fkzo.TeXKMvPnhp8RdCWLoVfbokmW9n1GC2KH2",
                      "feet": "",
                      "inches": "",
                      "idCardPicture": "",
                      "profilePicture": "",
                      "jerseyNumber": "20",
                      "enabled": 1,
                      "playerPosition": null,
                      "role": "Player",
                      "dateCreated": "2023-01-18T16:41:56.000Z",
                      "genderUser": {
                          "id": 2,
                          "gender": "Female",
                          "dateCreated": "2022-11-24T04:57:06.000Z"
                      },
                      "typeUser": {
                          "id": 1,
                          "type": "Player",
                          "dateCreated": "2022-11-24T04:57:54.000Z"
                      }
                  }
              },
              "PoolTBD": null
          },
          9
      ]
  ]
]*/



