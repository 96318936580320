import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateEventDetails, eventDetailsAdapter } from '../../store/states/event-details.state';

export const {
  selectIds: _selectEventDetailsDataIds,
  selectEntities: _selectEventDetailsEntities,
  selectAll: _selectAllEventDetails,
  selectTotal: _selectEventDetailsTotal
} = eventDetailsAdapter.getSelectors();

export const selectState = createFeatureSelector<StateEventDetails>('eventDetails');

export const selectEventDetailsIds = createSelector(
  selectState,
  _selectEventDetailsDataIds
);

export const selectEventDetailsEntities = createSelector(
  selectState,
  _selectEventDetailsEntities
);

export const selectAllEventDetails = createSelector(
  selectState,
  _selectAllEventDetails
);

export const selectEventDetailsResponse = createSelector(
  selectState,
  (state: StateEventDetails): any => state.eventDetails
);

export const selectEventDetailsError = createSelector(
  selectState,
  (state: StateEventDetails): boolean => state.error
);

export const selectEventDetailsLoading = createSelector(
  selectState,
  (state: StateEventDetails): any => state
);
