import { Action } from "@ngrx/store";
import { UserParams } from "../../models/user/user-params";
import { UserResponse } from "../../models/user/user-response";

export enum UserActionType {
  Auth = "[User] Auth",
  Update = "[User] Update",
  Create = "[User] Create",
  Enabled = "[User] Enabled",
  Delete = "[User] Delete",
  GetAll = "[User] Get All",
  Loading = "[User] Loading",
  LoadSuccess = "[User] LoadSuccess",
  LoadFailure = "[User] LoadFailure",
}

export class UserLoadAction implements Action {
  public readonly type = UserActionType.Loading;
  constructor(public payload: UserParams) {}
}

export class UserAuthAction implements Action {
  public readonly type = UserActionType.Auth;
  constructor(public payload: UserParams) {}
}

export class UserCreateAction implements Action {
  public readonly type = UserActionType.Create;
  constructor(public payload: UserParams) {}
}

export class UserUpdateAction implements Action {
  public readonly type = UserActionType.Update;
  constructor(public payload: UserParams) {}
}

export class UserEnabledAction implements Action {
  public readonly type = UserActionType.Enabled;
  constructor(public payload: UserParams) {}
}

export class UserDeleteAction implements Action {
  public readonly type = UserActionType.Delete;
  constructor(public payload: UserParams) {}
}

export class UserGetAllAction implements Action {
  public readonly type = UserActionType.GetAll;
  constructor(public payload: UserParams) {}
}

export class UserLoadSuccessAction implements Action {
  public readonly type = UserActionType.LoadSuccess;
  constructor(public payload: UserResponse) {}
}

export class UserLoadFailAction implements Action {
  public readonly type = UserActionType.LoadFailure;
  constructor(public error: any) {}
}

export type UserAction =
  | UserLoadAction
  | UserLoadSuccessAction
  | UserLoadFailAction;
