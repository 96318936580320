import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "../service.index";
import Swal from "sweetalert2";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private _authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = "";
        let pipeMsj = error.error.message
          ? error.error.message
          : error.error.mensaje;

        if (error.error instanceof ErrorEvent) {
          console.log("this is client side error");
          errorMsg = `Error: ${error.error.message}`;
        } else {
          console.log("this is server side error");
          errorMsg = `Error Code: ${error.status},  Message: ${pipeMsj}`;
        }
        this.checkTocken(pipeMsj);
        console.log(errorMsg);
        return throwError(errorMsg);
      })
    );
  }

  checkTocken(error: string) {
    if (error.includes("UnauthorizedError")) {
      setTimeout(() => {
        Swal.fire({
          title: "Error",
          text: "Your session has expired, log in again please.",
          icon: "error",
          showConfirmButton: true,
          timer: 2000,
        });
      }, 800);
      this._authService.removeSessionStorage();
      this.router.navigate(["/login"]);
    }
  }
}
