import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class BracketService {
  constructor(public http: HttpClient, public router: Router) {}

  saveBracket(data: any) {    
    return this.http.post(
      `${environment.API_URL}brackets/create`,
      data.data
    );
  }
  removeBracket(bracket_id) {
    return this.http.post(`${environment.API_URL}brackets/remove`,
      {bracket_id:bracket_id}
    );
  }

  getFields() {
    return this.http.get(`${environment.API_URL}fields/get/all`).toPromise();
  }

  updateMatches(data:any) {
    return this.http.post(`${environment.API_URL}brackets/schedule/matches`,data).toPromise();
  }

  updateScoreMatch(data:any) {
    return this.http.post(`${environment.API_URL}matches/updateScoreMatch`,data).toPromise();
  }

  updateScoreMatchDouble(data:any) {
    return this.http.post(`${environment.API_URL}matches/updateScoreMatchDouble`,data).toPromise();
  }

  getMatches(bracket_id: any) {
    return this.http.get(`${environment.API_URL}brackets/pool/matches/${bracket_id}`).toPromise();
  }
  getMatchesByEvent(event_id: any) {
    return this.http.get(`${environment.API_URL}brackets/matches/event/${event_id}`).toPromise();
  }
  getPoolGames(bracket_id: any) {
    return this.http.get(`${environment.API_URL}brackets/pool/games/${bracket_id}`).toPromise();
  }
  getPoolsGames(bracket_id: any) {
    return this.http.get(`${environment.API_URL}brackets/pool/games/${bracket_id}`)
  }
  getBracketsByDivisionId(data: any) {
    return this.http.get(`${environment.API_URL}brackets/divisions/${data.division_id}`);
  }
  savePoolBracket(data){
    return this.http.post(
      `${environment.API_URL}brackets/pool/create`,
      data
    ).toPromise();
  }
  saveGames(data: any) {
    return this.http.post(`${environment.API_URL}brackets/create/games`, data).toPromise();
  }
  saveSingleGames(data: any) {
    return this.http.post(`${environment.API_URL}brackets/create/games/single`, data);
  }
  saveDoubleGames(data: any) {
    return this.http.post(`${environment.API_URL}brackets/create/games/double`, data);
  }
  getSingleGames(data: any) {
    return this.http.post(`${environment.API_URL}brackets/games/single`, {bracket_id:data});
  }
  getDoubleGames(data: any) {
    return this.http.post(`${environment.API_URL}brackets/games/double`, {bracket_id:data});
  }
  updateTeams(data: any) {    
    return this.http.put(`${environment.API_URL}brackets/games/single`, {name:data.name,old_away:data.old_away,new_away:data.new_away,old_home: data.old_home,new_home:data.new_home,id:data.id});
  }

  getByBracketId(data: any) {
    return this.http.get(`${environment.API_URL}brackets/bracket/${data.bracket_id}`);
  }

  saveAddNewMatch(data: any) {       
    return this.http.post(
      `${environment.API_URL}brackets/addnewmatch`,
      data.data
    );
  }
}
