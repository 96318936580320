import { Directive, Input, OnInit, ElementRef, Renderer2 } from "@angular/core";
import { interval, Subscription } from "rxjs";
import { RequestAnimationService } from "../../services/request-animation/request-animation.service";

@Directive({
  selector: "[request-animation]",
})
export class RequestAnimationDirective implements OnInit {
  @Input() showDelay: number = 50;
  @Input() hideDelay: number = 1000;
  hideTimer: Subscription;
  showTimer: Subscription;
  childremElements: any;
  i: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private _requestAnimationService: RequestAnimationService
  ) {}

  cancelPendingHide() {
    if (this.hideTimer) {
      this.hideTimer.unsubscribe();
      delete this.hideTimer;
    }
  }

  cancelPendingShow() {
    if (this.showTimer) {
      this.showTimer.unsubscribe();
      delete this.showTimer;
    }
  }

  ngOnInit() {
    this._requestAnimationService.busy.subscribe((busy) => {
      if (busy) {
        this.cancelPendingHide();

        // If a show is already pending, don't start a new one.
        if (!this.showTimer) {
          this.showTimer = interval(this.showDelay).subscribe(() => {
            console.log("Agregando Loading ", busy);
            // this.renderer.addClass(this.el.nativeElement, 'inactive');
            this.childremElements = Array.from(this.el.nativeElement.children);
            Array.from(this.el.nativeElement.children).forEach((child) => {
              this.renderer.removeChild(this.el.nativeElement, child);
            });

            this.i = this.renderer.createElement("i");

            this.renderer.addClass(this.i, "fas");
            this.renderer.addClass(this.i, "fa-sync-alt");

            this.renderer.appendChild(this.el.nativeElement, this.i);

            this.showTimer.unsubscribe();
            this.showTimer = null;
          });
        }
      }
    });

    this._requestAnimationService.busy.subscribe((busy) => {
      if (!busy) {
        this.cancelPendingShow();

        // If a show is already pending, don't start a new one.
        if (!this.hideTimer) {
          this.hideTimer = interval(this.hideDelay).subscribe(() => {
            console.log("Reconstruyendo elementos ", busy);
            // this.renderer.removeClass(this.el.nativeElement, 'inactive');
           if(!this.i){
             return false
           }
            this.renderer.removeChild(this.el.nativeElement, this.i);
            this.childremElements.forEach((child) => {
              this.renderer.appendChild(this.el.nativeElement, child);
            });
            this.hideTimer.unsubscribe();
            this.hideTimer = null;
          });
        }
      }
    });
  }
}
