import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { AuthService } from "../auth/auth.service";
import { Observable } from "rxjs";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const data = this.auth.getSessionStorage();

    const token = !data ? "no token" : data.token;

    request = request.clone({
      setHeaders: {
        authorization: `Bearer ${token}`,
      },
    });

    return next.handle(request);
  }
}
