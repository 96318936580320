import { Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  DateAdapter,
  MatSelect,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {AuthService, BracketService, EventService, SharedService} from "src/app/services/service.index";
import Swal from "sweetalert2";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { MatDialog } from "@angular/material/dialog";
import { SharedDataService } from "src/app/utils/shared";
import { NewBracketsComponent } from "src/app/modals/new-brackets/new-brackets.component";
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NewGameComponent } from 'src/app/modals/new-game/new-game.component';
import {Event} from "../../../models/event/event";
import { AddStatsComponent } from 'src/app/modals/add-stats/add-stats.component';
import { UpdateScoreComponent } from "src/app/modals/update-score/update-score/update-score.component";
import { ShowDislikeComponent } from 'src/app/modals/coments-dislike/show-dislike.component';

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD-MM-YY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-matches",
  templateUrl: "./matches.component.html",
  styleUrls: ["./matches.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class MatchesComponent implements OnInit {
  matches:any = [];
  event_name;
  event: Event;
  counter = 0;
  bracket_id: number;
  division_id: number;
  event_Id: any;
  role_scheduling: false;
  role_score_stats: false;

  constructor(
    public sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    private service:BracketService,
    private _sharedService: SharedService,
    public dialog: MatDialog,
    private _eventService: EventService,
    private _authService: AuthService,
    private shared:SharedDataService  ) {

    this._sharedService.changeTitile("");
    route.params.subscribe((params) => {
      const eventId = params.eventId;
      this.division_id = params.division_id;
      this.event_Id = params.eventId;
      //Get Event
      this._eventService.getById({idEvent: eventId}).subscribe((response: any) => {
        if (response.valid) {

          //Get And Check Permissions
          this._authService.getRolesByEvent(eventId).subscribe((response: any) => {
            if (response.valid) {
              this.role_scheduling = response.data.some(role => role.role === "Scheduling");
              this.role_score_stats = response.data.some(role => role.role === "Scoring/Stats");
            } else {
              console.log(response);
            }
          });

          this.event = response.data;
        }
      });
    });
  }


  ngOnInit() {
    Swal.close();
    Swal.showLoading();
    this.route.params.subscribe(async (params) => {
        let response:any = await this.service.getMatches(params.bracket_id);
        this.bracket_id = params.bracket_id;      
        this.matches = response.data.data;          
        let count = 0;
        this.matches.Pools.forEach(ele => {
          ele.Matches.forEach(el => {
            count++;
            el.match_name = this.get_name(response.data.devi.idEvent.name)+' - '+this.get_name(response.data.devi.division_name)+' - '+ this.addZeros(count,4);
          });
        });
        Swal.close();

    })
  }
  get_name(sentence){
    const words = sentence.split(" ");
    let initials = "";
    for (let i = 0; i < words.length; i++) {
      initials += words[i].charAt(0);
    }
    return initials;
  }
  addZeros(num, size) {
      this.counter++;
      let numStr = this.counter.toString();
      while (numStr.length < 4) {
        numStr = "0" + numStr;
      }
    return numStr;
  }

  newCalendar($event){
    window.location.href =`/#/superadmin/event/${this.event.id}/calendar`;
  }

  addsStat(idMatch,idTeamLocal,idTeamVisitor) {
    let idEvent = this.event_Id;
    let division_id = this.division_id
    const dialogRef = this.dialog.open(AddStatsComponent, {
       width: "90%",
       data: {idMatch,idTeamLocal,idTeamVisitor,idEvent,division_id},
     });
     dialogRef.afterClosed().subscribe((result) => {
       if (result) {
         // After close
       }
     });
   }

     updateScore(idmatch : number,nameLocal:string,nameVisitor:string, localscore:number,visitorscore:number,finalized:any)
     {
       let finalizeScore: any = finalized;
       let bracket = this.bracket_id;
       let idEvent = this.event_Id;
       let division_id = this.division_id
       const dialogRef = this.dialog.open(UpdateScoreComponent, {
         width:"280px",
         data: {idmatch,localscore,visitorscore,finalizeScore,nameLocal,nameVisitor,bracket,idEvent,division_id},

       });
       dialogRef.afterClosed().subscribe((result) => {
        if (result.result==true) {
         for (const pool of this.matches.Pools) {
          const matchToUpdate = pool.Matches.find(match => match.id === idmatch);
          if (matchToUpdate) {
            matchToUpdate.scoreLocal = result.local;
            matchToUpdate.scoreVisitor = result.visitor;            
            if(result.finalize){
              matchToUpdate.finalized = 'Confirmed'
            }
            break;
          }
        }
         }
       });
     }
  showDisLike(matchId: number,nameMatch:any) {
    const dialogRef = this.dialog.open(ShowDislikeComponent, {
      width: "280px",
      data: {matchId,nameMatch},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        
      }
    });
  }
}
