import * as event from "../../store/states/event.state";
import { EventAction, EventActionType } from "../../store/actions/event.actions";

export function eventReducer(
  state = event.initialStateEvent,
  action: EventAction | any
): event.StateEvent {
  switch (action.type) {
    case EventActionType.Loading: {
      return { ...state, loading: true };
    }
    case EventActionType.LoadSearchSuccess: {
      return  { ...state,
        eventSearch: action.payload,
        error: false,
        loading: false,
      };
    }
    case EventActionType.LoadSuccess: {
      return  { ...state,
        event: action.payload,
        error: false,
        loading: false,
      };
    }
    case EventActionType.LoadFailure: {
      return  { ...state,
        event: null,
        error: true,
        loading: false,
      };
    }
    default:
      return state;
  }
}
