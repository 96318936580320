import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { UserService, PlayerService } from 'src/app/services/service.index';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';

@Component({
  selector: 'app-add-players-team',
  templateUrl: './add-players-team.component.html',
  styleUrls: ['./add-players-team.component.css']
})
export class AddPlayersTeamComponent implements OnInit {

  playersTeam: any[] = [];
  division: any;
  filteredUsers: any[] = [];
  selectedUsers: any[] = [];
  allUsers: any[] = [];
  title: string;
  toAge: any;
  fromAge: any;
  typeList: any;
  nameTeam: any;

  pageSize = 4;
  currentPage = 0;
  paginatedFilteredUsers: any[] = [];
  paginatedTeamPlayers: any[] = [];

  searchTerm = new FormControl('');

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private _userService: UserService,
    private _playerService: PlayerService,
    public dialogRef: MatDialogRef<AddPlayersTeamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.division = this.data.division;
    this.toAge = this.division.ageRestric.toAge;
    this.fromAge = this.division.ageRestric.fromAge;
    this.typeList = this.data.typeList;    
    this.nameTeam = this.data.team.name;
    this.loadUsers();
    
    this.searchTerm.valueChanges.subscribe(term => {
      this.searchByName(term);
    });
  }

  loadUsers() {
    if (this.typeList === 'tr') {
      this._userService.getAllUserPlayers(this.data.team.teamId).subscribe(
        (res: any) => {
          if (res.valid) {
            this.allUsers = res.data[0];            
          } else {
            console.log('Error:', res);
          }
        },
        (error) => {
          console.error('Error fetching users', error);
        }
      );

      this._playerService.getPlayersEventByTeam({ teamId: this.data.team.teamId, divisionId: this.division.id }).subscribe(
        (res: any) => {
          if (res.valid) {
            this.playersTeam = res.data[0];            
            this.allUsers = [...this.allUsers, ...res.data[1]];
            this.updatePaginatedTeam();
            this.searchByName('*');
          } else {
            console.log('Error:', res);
          }
        },
        (error) => {
          console.error('Error fetching users', error);
        }
      );
    } else {
      this._userService.getAllUserPlayers(this.data.team.teamId).subscribe(
        (res: any) => {
          if (res.valid) {
            this.playersTeam = res.data[1];
            this.allUsers = res.data[0];
            this.updatePaginatedTeam();
            this.searchByName('*');
          } else {
            console.log('Error:', res);
          }
        },
        (error) => {
          console.error('Error fetching users', error);
        }
      );
    }
  }

  searchByName(name: string): void {
    if (name.trim() === '' || name.trim() === '*') {
      this.filteredUsers = this.allUsers;
      this.currentPage = 0;
      this.updatePaginatedFilteredUsers();
     return;
    }
    this.filteredUsers = this.allUsers.filter(user =>
      user.userName.toLowerCase().includes(name.toLowerCase())
    );
    this.updatePaginatedFilteredUsers();
  }

  selectUser(user: any): void {
    if (user.yearUser < this.fromAge || user.yearUser > this.toAge) {
      this.showAlert("Error", "Player does not have the correct age for the division.", "error", 3000, false, false, false);
    } else {
      const userIndex = this.selectedUsers.findIndex(selectedUser => selectedUser.idUser === user.idUser);
      if (userIndex === -1) {
        this.selectedUsers.push({ ...user, playerId: user.playerId ? user.playerId : uuidv4(), division_id: this.division.id, teamId: this.data.team.teamId });
      } else {
        this.selectedUsers.splice(userIndex, 1);
      }
    }
  }

  removeSelectedUser(selectedUser: any): void {
    const userIndex = this.selectedUsers.findIndex(user => user.idUser === selectedUser.idUser);
    if (userIndex !== -1) {
      this.selectedUsers.splice(userIndex, 1);
    }
  }

  updatePaginatedFilteredUsers(): void {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;    
    this.paginatedFilteredUsers = this.filteredUsers.slice(startIndex, endIndex);
  }

  updatePaginatedTeam(): void {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedTeamPlayers = this.playersTeam.slice(startIndex, endIndex);    
  }

  onPageChange(event: any, typePaginator: string): void {
    this.currentPage = event.pageIndex;    
    if (typePaginator === 'F') {
      this.updatePaginatedFilteredUsers();
    } else {
      this.updatePaginatedTeam();
    }
  }

  saveTeam() {
    if (this.typeList === 'tr') {
      const modifiedPlayers = this.selectedUsers.map(player => {
        const { idUser, userName, yearUser, ...rest } = player;
        return { ...rest, userId: idUser, eventId: this.division.idEvent.idEvent, division_id: this.division.id };
      });

      this._playerService.assignmentPlayerEventByTeam(modifiedPlayers).subscribe(
        (res: any) => {
          if (res.valid) {
            if (res.data && res.data.length > 0) {
              const playersNotYear = res.data.map(user => `${user.namePlayer}`).join('-');
              this.showAlert("Error", `These players are already in the division: ${playersNotYear}`, "error", 8000, false, false, true);
            } else {
              this.showAlert("Success", "Data saved", "success", 2000, true, true, false);
            }
          } else {
            console.log('Error:', res);
          }
        },
        (error) => {
          console.error('Error fetching users', error);
        }
      );
    } else {
      if (this.selectedUsers.length > 0) {
        const modifiedPlayers = this.selectedUsers.map(player => {
          const { idUser, userName, yearUser, ...rest } = player;
          return { ...rest, userId: idUser };
        });

        this._playerService.savePlayerWithTeam(modifiedPlayers).subscribe(
          (res: any) => {
            if (res.valid) {
              this.showAlert("Success", "Data saved", "success", 2000, true, true, false);
            } else {
              console.log('Error:', res);
            }
          },
          (error) => {
            console.error('Error fetching users', error);
          }
        );
      } else {
        this.showAlert("Error", "No data", "error", 2000, false, false, false);
      }
    }
  }

  showAlert(title: string, text: string, icon: any, timer: number, stateAlert: boolean, estateDialogRef: boolean, buttonConfirm: boolean): void {    
    setTimeout(() => {
      Swal.fire({
        title,
        text,
        icon,
        showConfirmButton: buttonConfirm,
        timer
      });
      if (stateAlert) {
        this.dialogRef.close(estateDialogRef);
      }      
    }, 800);
  }
}