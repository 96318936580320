import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class PlayerMatchesStatsService {
  constructor(public http: HttpClient, public router: Router) {}


getByPlayerStatByTeam(data: any) {       
    return this.http.get(`${environment.API_URL}player_matches_stats/get/getPlayersStatTeam/${data.idTeam}/${data.division_id}/${data.eventid}`);
}

getByPlayerStatByTeamsAll(data: any) {   
  return this.http.get(`${environment.API_URL}player_matches_stats/get/getPlayersStatTeamsAll/${data.division_id}/${data.eventid}`);
}

}