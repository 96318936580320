import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Bracket } from '../../models/bracket/bracket';

export interface StateBrackets extends EntityState<Bracket> {
  brackets: any;
  error: boolean;
  loading: boolean;
}

export const bracketAdapter: EntityAdapter<Bracket> = createEntityAdapter<Bracket>({});

export const initialStateBrackets: StateBrackets = bracketAdapter.getInitialState({
  brackets: null,
  error: false,
  loading: true,
});
