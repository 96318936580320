import * as gender from "../../store/states/gender.state";
import { GenderAction, GenderActionType } from "../../store/actions/gender.actions";

export function genderReducer(
  state = gender.initialStateGender,
  action: GenderAction | any
): gender.StateGender {
  switch (action.type) {
    case GenderActionType.Loading: {
      return { ...state, loading: true };
    }
    case GenderActionType.LoadSuccess: {
      return  { ...state,
        gender: action.payload,
        error: false,
        loading: false,
      };
    }
    case GenderActionType.LoadFailure: {
      return  { ...state,
        gender: null,
        error: true,
        loading: false,
      };
    }
    default:
      return state;
  }
}
