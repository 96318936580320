import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class EventDetailsService {
  constructor(public http: HttpClient, public router: Router) {}

  postSecondStep(data: any) {
    return this.http.post(
      `${environment.API_URL}eventsDetail/create/secondStep/`,
      data.data
    );
  }

  getAll() {
    return this.http.get(`${environment.API_URL}eventsDetail/get/all/`);
  }

  getByEvent(eventId: string) {
    return this.http.get(`${environment.API_URL}eventsDetail/getByEvent/`+eventId);
  }

  postEditAllDivisions(data: any) {    
    return this.http.post(
      `${environment.API_URL}eventsDetail/update/EditAllDivisions/`,
      data.data
    );
  }
}
