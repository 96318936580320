import { Component, OnInit } from "@angular/core";
import { SharedService } from "src/app/services/service.index";
@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.css"],
})
export class OverviewComponent implements OnInit {
  constructor(private _sharedService: SharedService) {
    this._sharedService.changeTitile("Overview");
  }

  ngOnInit() {
    //
  }
}
