import * as brackets from "../../store/states/brackets.state";
import { BracketActions, BracketsActionType } from "../../store/actions/brackets.actions";

export function bracketsReducer(
  state = brackets.initialStateBrackets,
  action: BracketActions | any
): brackets.StateBrackets {
  
  switch (action.type) {
    case BracketsActionType.Loading: {
      return { ...state, loading: true };
    }
    case BracketsActionType.LoadSuccess: {
      return  { ...state,
        brackets: action.payload,
        error: false,
        loading: false,
      };
    }
    case BracketsActionType.LoadFailure: {
      return  { ...state,
        brackets: null,
        error: true,
        loading: false,
      };
    }
    case BracketsActionType.ResetSuccess: {
      return  { ...state,
        brackets: null,
        error: false,
        loading: false,
      };
    }
    default:
      return state;
  }
}
