import { Component, Inject, OnInit } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { take, startWith, map } from "rxjs/operators";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { GlobalState } from "src/app/store/states/global.state";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";

// Third libreries
import Swal from "sweetalert2";
import * as moment from "moment";

// Store
import {
  RolesCreateAction,
  RolesUpdateAction,
} from "src/app/store/actions/roles.actions";
import { RolesParams } from "src/app/models/roles/roles-params";
import * as rol from "src/app/store/selectors/roles.selectors";

@Component({
  selector: "app-new-staff",
  templateUrl: "./new-staff.component.html",
  styleUrls: ["./new-staff.component.css"],
})
export class NewStaffComponent implements OnInit {
  title: string = "";
  user: any;
  btnAction: string = "";
  idRol: number;
  disabledBtn: boolean = false;
  rolFormGroup: FormGroup;

  roles: any = [];
  selectedRol: any[] = [];


  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NewStaffComponent>,
    public dialog: MatDialog,
    public store: Store<GlobalState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.user = data.user;
    this.roles = data.roles;
    this.selectedRol = data.selectedRoles;

    console.log(this.selectedRol)
  }

  ngOnInit() {

  }

  compareRoles(rol,selectedRol){
    return !!selectedRol.find(srol => srol.rol.id == rol.id);
  }

  // Select Role
  selectRol(list) {
    let roles = list.selectedOptions.selected.map(item => item.value);
    roles = roles.map(rol => ({ rol: rol }));
    this.selectedRol = roles;
  }

  save(){
    this.dialogRef.close(this.selectedRol);
  }

}
