import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Staff } from "../../models/staff/staff";

export interface StateStaff extends EntityState<Staff> {
  staff: any;
  error: boolean;
  loading: boolean;
}

export const staffAdapter: EntityAdapter<Staff> = createEntityAdapter<Staff>({});

export const initialStateStaff: StateStaff = staffAdapter.getInitialState({
  staff: null,
  error: false,
  loading: true,
});
