import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { NewsResponse } from '../../models/news-feed/news-response';
import { News } from 'src/app/models/news-feed/news';



@Injectable({
  providedIn: "root"
})
export class NewsFeedService {
  private apiUrl = `${environment.API_URL}news-feed/`;

  constructor(private http: HttpClient) {}

  getAllByUser(): Observable<NewsResponse> {
    return this.http.get<NewsResponse>(`${this.apiUrl}getAllByUser`);
  }

  getAllByEvent(id: number): Observable<NewsResponse> {
    return this.http.get<NewsResponse>(`${this.apiUrl}getAllByEvent/${id}`);
  }

  create(news: News): Observable<NewsResponse> {
    return this.http.post<NewsResponse>(`${this.apiUrl}create`, news);
  }

  edit(id: number, news: News): Observable<NewsResponse> {
    return this.http.put<NewsResponse>(`${this.apiUrl}edit/${id}`, news);
  }

  getById(id: number): Observable<NewsResponse> {
    return this.http.get<NewsResponse>(`${this.apiUrl}${id}`);
  }

removeById(id: number): Observable<NewsResponse> {
    return this.http.delete<NewsResponse>(`${this.apiUrl}delete/${id}`);
  }
}
