import * as fields from "../../store/states/fields.state";
import { FieldsAction, FieldsActionType } from "../../store/actions/fields.actions";

export function fieldsReducer(
  state = fields.initialStateFields,
  action: FieldsAction | any
): fields.StateFields {
  switch (action.type) {
    case FieldsActionType.Loading: {
      return { ...state, loading: true };
    }
    case FieldsActionType.LoadSuccess: {
      return  { ...state,
        fields: action.payload,
        error: false,
        loading: false,
      };
    }
    case FieldsActionType.LoadFailure: {
      return  { ...state,
        fields: null,
        error: true,
        loading: false,
      };
    }
    default:
      return state;
  }
}
