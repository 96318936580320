import { Action } from "@ngrx/store";
import { TypeEventParams } from "../../models/type-event/type-event-params";
import { TypeEventResponse } from "../../models/type-event/type-event-response";

export enum TypeEventActionType {
  Get = "[TypeEvent] Get",
  Loading = "[TypeEvent] Loading",
  LoadSuccess = "[TypeEvent] LoadSuccess",
  LoadFailure = "[TypeEvent] LoadFailure",
}

export class TypeEventLoadAction implements Action {
  public readonly type = TypeEventActionType.Loading;
  constructor(public payload: TypeEventParams) {}
}

export class TypeEventGetAction implements Action {
  public readonly type = TypeEventActionType.Get;
  constructor(public payload: TypeEventParams) {}
}

export class TypeEventLoadSuccessAction implements Action {
  public readonly type = TypeEventActionType.LoadSuccess;
  constructor(public payload: TypeEventResponse) {}
}

export class TypeEventLoadFailAction implements Action {
  public readonly type = TypeEventActionType.LoadFailure;
  constructor(public error: any) {}
}

export type TypeEventAction =
  | TypeEventLoadAction
  | TypeEventLoadSuccessAction
  | TypeEventLoadFailAction;
