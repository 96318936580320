import { Action } from "@ngrx/store";
import { EventParams } from "../../models/event/event-params";
import { EventResponse } from "../../models/event/event-response";

export enum EventActionType {
  Save = "[Event] Save",
  Enabled = "[Event] Enabled",
  Search = "[Event] Search",
  Delete = "[Event] Delete",
  GetById = "[Event] GetById",
  Loading = "[Event] Loading",
  LoadSuccess = "[Event] LoadSuccess",
  LoadSearchSuccess = "[Event] LoadSearchSuccess",
  LoadFailure = "[Event] LoadFailure",
}

export class EventLoadAction implements Action {
  public readonly type = EventActionType.Loading;
  constructor(public payload: EventParams) {}
}

export class EventGetByIdAction implements Action {
  public readonly type = EventActionType.GetById;
  constructor(public payload: EventParams) {}
}

export class EventSaveAction implements Action {
  public readonly type = EventActionType.Save;
  constructor(public payload: EventParams) {}
}

export class EventEnabledAction implements Action {
  public readonly type = EventActionType.Enabled;
  constructor(public payload: EventParams) {}
}

export class EventDeleteAction implements Action {
  public readonly type = EventActionType.Delete;
  constructor(public payload: EventParams) {}
}

export class EventSearchAction implements Action {
  public readonly type = EventActionType.Search;
  constructor(public payload: EventParams) {}
}

export class EventLoadSuccessAction implements Action {
  public readonly type = EventActionType.LoadSuccess;
  constructor(public payload: EventResponse) {}
}

export class EventLoadSearchSuccess implements Action {
  public readonly type = EventActionType.LoadSearchSuccess;
  constructor(public payload: EventResponse) {}
}

export class EventLoadFailAction implements Action {
  public readonly type = EventActionType.LoadFailure;
  constructor(public error: any) {}
}

export type EventAction =
  | EventLoadAction
  | EventLoadSuccessAction
  | EventLoadSearchSuccess
  | EventLoadFailAction;
