import { ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef, NgZone } from '@angular/core';
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  DateAdapter,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {BracketService, EventService, SharedService} from "src/app/services/service.index";
import Swal from "sweetalert2";
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList } from '@angular/cdk/drag-drop';

import { MatDialog } from "@angular/material/dialog";
import { SharedDataService } from "src/app/utils/shared";
import { NewBracketsComponent } from "src/app/modals/new-brackets/new-brackets.component";
import { FormBuilder } from "@angular/forms";
import {Event} from "../../../models/event/event";
import {ActivatedRoute, Router} from "@angular/router";
import {Bracket} from "../../../models/bracket/bracket";
import {BracketResponse} from "../../../models/bracket/bracket-response";
import { TeamSelectionComponent } from 'src/app/modals/team-selection/team-selection.component';
import { TbdDetailsComponent } from 'src/app/modals/tbd-details/tbd-details.component';

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD-MM-YY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};


@Component({
  selector: "app-pools",
  templateUrl: "./pools.component.html",
  styleUrls: ["./pools.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PoolsComponent implements OnInit {

  @ViewChild("viewContainerRef", { read: ViewContainerRef, static: false }) vcr!: ViewContainerRef;
  ref!: ComponentRef<NewBracketsComponent>
  all_teams = [];
  combo_pool;
  pool_ids = [];
  created = false;
  event: Event;
  pool;
  eventId:number;
  bracket_id: number;
  division_id: number;
  is_tbd_added= false;
  format;
  informationBraket;
  state_rute;
  state_rute_save;
  total_teams;
  constructor(
    private _bracketService:BracketService,
    private _sharedService: SharedService,
    public dialog: MatDialog,
    private shared:SharedDataService,
    public route: ActivatedRoute,
    private router: Router,
    private _eventService:EventService
  ) {
    this._sharedService.changeTitile("");
  }
  gridCols: number = 2;

  ngOnInit() {

      this.route.params.subscribe(async (params) => {
        this.eventId = params.eventId;
        this.division_id = params.division_id;
        //Get Event
        let response:any = await this._eventService.getById({idEvent: this.eventId}).toPromise();
        if (response.valid) {
          this.event = response.data;
        }

        this.bracket_id = params.bracket_id;
        console.log("BRACKET ID:",this.bracket_id);
        const res: any =  await this._bracketService.getPoolGames(this.bracket_id);
        console.log(".......", res.data);
        if(res.valid){
          this.format = res.data.format;
          this.informationBraket = res.data;
          this.state_rute  = res.data.state_rute;
          this.shared.default_pool = {};
          this.shared.default_pool.Pools = res.data.Pools;
          this.pool = this.shared.default_pool;

          this.total_teams = this.informationBraket.Pools[0].PoolTeams.length;

          if(this.shared.default_pool.Pools.length !== 1){
            //this.router.navigate(['/superadmin/event',this.event.id,'division',this.division_id,'pools','games',this.bracket_id])
          } else {
            this.total_teams = this.shared.default_pool.Pools[0].PoolTeams.length;

            this.all_teams = [...this.shared.default_pool.Pools[0].PoolTeams];
            this.combo_pool = [...Array((this.total_teams)).keys()] ;
            this.combo_pool = this.combo_pool.slice(1,this.combo_pool.length-1)

            this.pool.Pools[0].PoolTeams = this.pool.Pools[0].PoolTeams.map(e=>{
              return  {
                ...e,
                type: e.Teams.name.startsWith("TBD") ? 'TBD': 'TEAM'
              }
            })
          }

          this.changeTeams(null);

        }
      });

  }


  current_split;
  changeTeams(e){
    this.t = 0;
    this.modified_pool = [];
    this.current_split = e ? e.target.value : 1;
    //console.log('Targe->',e.target.value);
    if(this.current_split == 1)
      this.modified_pool.push(this.pool.Pools[0].PoolTeams);
    else
      this.splitArray(this.pool.Pools[0].PoolTeams,e.target.value);
   // console.log(this.modified_pool.length);
    this.pool_ids = [...Array( (this.modified_pool.length)).keys() ] ;
    this.pool_ids = this.pool_ids.map(e=>`${e}`);
    //console.log('POOLSID',this.pool_ids);
    //console.log('POOLSID',this.modified_pool);

  }
  splitTeams(chunkSize) {
    const res = [];
    for (let i = 0; i < this.pool.Pools[0].PoolTeams.length; i += chunkSize) {
        const chunk = this.pool.Pools[0].PoolTeams.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
  }
  stringify(e){
    return JSON.stringify(e);
  }
  //getBracketsByDivisionId
  async add_details(event){
    let brackets:any = await this._bracketService.getBracketsByDivisionId({division_id:this.division_id}).toPromise();
    //console.log("....",brackets)
    const dialogRef = this.dialog.open(TbdDetailsComponent, {
      width: "30%",
      data:  [...brackets.data] ,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
          const it = JSON.parse(event.target.getAttribute("data"));
            if(this.modified_pool.length>1){
              for (let i = 0; i < this.modified_pool.length; i++) {
                for (let j = 0; j < this.modified_pool[i].length; j++) {
                  const team = this.modified_pool[i][j].Teams;
                  if (team.id === it.Teams.id) {
                    team.name = result.name;
                    this.modified_pool[i][j].bracket_id = result.bracket_id;
                    this.modified_pool[i][j].pool_id = result.pool_id;
                    this.modified_pool[i][j].place = result.place
                  }
                }
              }
            }else {
              this.pool.Pools[0].PoolTeams.forEach(ele => {
                  if(ele.Teams.id == it.Teams.id){
                    ele.Teams.name = result.name;
                    ele.pool_id = result.pool_id;
                    ele.bracket_id = result.bracket_id;
                    ele.place = result.place;
                  }
              });
            }
         }
    });
  }
  generate_tbd(ev){
     let tbds =  ev.target.value;
     this.is_tbd_added = true;
     let i = 1;
     this.pool.Pools[0].PoolTeams = this.pool.Pools[0].PoolTeams.filter(e=> !e.Teams.name.startsWith("TBD"))
     if(tbds > 0){
        while(i<=tbds){
          let ntbds = this.pool.Pools[0].PoolTeams.filter(e=> e.Teams.name.startsWith("TBD")) || []
          this.pool.Pools[0].PoolTeams.push({
            pool_id:this.pool.Pools[0].id,
            Teams: {id: (ntbds.length+1), name: `${'TBD-'+ (ntbds.length+1)}` },
            type:'TBD'
          })
          i+=1;
        }
        if(this.current_split){
          this.changeTeams({target:{value:this.current_split}})
        }
     }
  }
  check_tbds(){
     return this.pool.Pools[0].PoolTeams.filter(e=> e.type === 'TBD' && e.Teams.name.startsWith("TBD-")).length > 0 ? false : true
  }

  save(){
    if(this.pool.Pools.length >0 ){
      let post_tbd=[];

      this.pool.Pools.forEach(async(element) => {
        try{
          console.log(this.modified_pool);
          let post_pool= this.modified_pool.map((e,o)=>{
            return e.filter((i)=>{
              if(!i.type || i.type === "TEAM"){
                return {
                  team_id: i.Teams.id
                }
              }else{
                i.index = o+1
                post_tbd.push(i)
              }
            })
         })


          if (this.format == "Double Elimination") {
            this.state_rute_save = "Double Elimination"
          } else if (this.format == "Single Elimination") {
            this.state_rute_save = "Single Elimination"
          } else if (this.format == "Round Robin") {
            this.state_rute_save = "Round Robin"
          } else {
            this.state_rute_save = "Consolation"
          }

        let data = {
            pool_id: element.id,
            bracket_id: element.bracket_id,
            state_rute: this.state_rute_save,
            new_pools: {
               pools:this.modified_pool.length === 0 ? this.shared.default_pool.Pools[0].PoolTeams : this.modified_pool
            },
            tbd:{
              tbds: post_tbd
            },
            eventId: this.eventId,
            divsion_id:this.division_id

        };

        if(post_tbd.length > 0 && (post_tbd.filter((obj) => !obj.hasOwnProperty('Teams') || !obj.hasOwnProperty('place')).length > 0)){
          Swal.fire({
            title: "Warning",
            text: "Complete the placeholders details first",
            icon: "warning",
            showConfirmButton: false,
            timer: 2000,
          });
          return false
          }

          await this._bracketService.savePoolBracket(data);
          //console.log('Data',data);
          this.created = true;
          setTimeout(() => {
            Swal.fire({
              title: "Success",
              text: "Pools Created!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
            let event = JSON.parse(localStorage.getItem("event"));
            if(this.format === 'Double Elimination'){
              window.location.href =`/#/superadmin/event/${event.id}/division/double/${this.division_id}/pools/games/${element.bracket_id}`;
            }else{
              window.location.href =`/#/superadmin/event/${event.id}/division/${this.division_id}/pools/games/${element.bracket_id}`;
            }

        }, 800);

        }catch(e){

          this.created = false
          throw e;
        }
      });
    }
  }
  modified_pool = [];
  t = 0;
  splitArray(array, cols) {
    if (cols==1) return array;
      let size = Math.ceil(array.length / cols);
      //console.log(size +'--'+cols)
      //let aux = array.slice(0, size).concat([null]).concat(this.splitArray(array.slice(size), cols-1));
     if(this.t==0){
      this.modified_pool.push(array.slice(0, size));
     }
     this.modified_pool.push(this.splitArray(array.slice(size), cols-1));
     this.modified_pool = this.modified_pool.filter(e=> e);
     this.t++;
  }

  public dropGrid(event: CdkDragDrop<string[]>): void {
   // moveItemInArray(this.board.columns, event.previousIndex, event.currentIndex);
  }
  public drop(event: CdkDragDrop<object[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
     // console.log('previousys '+event.previousIndex,event.previousContainer.data)
     // console.log('current '+event.currentIndex,event.container.data)
      transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
    }
  }
  temp_result_teams;
  open_teams(){
    const dialogRef = this.dialog.open(TeamSelectionComponent, {
      width: "30%",
      data:  [...this.all_teams] ,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.pool.Pools[0].PoolTeams = result;
        const pTeams = new Map(result.map((element) => [element.id, element]));
        this.all_teams = this.all_teams.map((element) => {
          if (pTeams.has(element.id)) {
            return pTeams.get(element.id);
          } else {
            return { ...element, selected: false };
          }
        });

      }
    });
  }

}
