import { Component, OnInit } from '@angular/core';
import {ActivatedRoute,Router} from "@angular/router";
import {EnventTeams} from "../../../services/teams/team.service";
import { SharedDataService } from 'src/app/utils/shared';
import {Event} from "../../../models/event/event";
import Swal from "sweetalert2";
import {MatDialog} from "@angular/material/dialog";
import {AddTeamComponent } from "../../../modals/add-team/add-team.component";
import { EventService } from 'src/app/services/event/event.service';
import { WaitListService } from 'src/app/services/waitList/waitList.service';
import {AddPlayersTeamComponent } from "../../../modals/add-players-team/add-players-team.component";
import {ShowAnswersComponent } from "../../../modals/show-answers/show-answers.component";


@Component({
  selector: 'app-division-teams',
  templateUrl: './division-teams.component.html',
  styleUrls: ['./division-teams.component.css']
})
export class DivisionTeamsComponent implements OnInit {
  division_id: number;
  division: any;
  teams: any[];
  event: Event = {};
  eventId: string;  
  Idevent:string
  namedivsion:string;
  waitList: any[];
  constructor(
    private route: ActivatedRoute,
    private _eventTeams:EnventTeams, 
    private shared:SharedDataService,
    public dialog: MatDialog,
    private _eventService: EventService,
    private _waitListService: WaitListService,
    public router: Router,
    ) {            

      route.params.subscribe((params) => {
        this.division_id = params.division_id;    
        this.Idevent  =params.eventId;      
           //Teams of event
           this._eventTeams.getAllByDivision({division_id:this.division_id}).subscribe((response:any)=>{
            if(response.valid){           

              this.teams = response.data;             
            }
          });

          //Teams of waitList
          this._waitListService.getAllWaitList({idEvent: this.Idevent ,division_id:this.division_id}).subscribe((response:any)=>{
            if(response.valid){     
              this.waitList = response.data;                                 


            }
          });


          //Data event
          this._eventService.getById({idEvent:this.Idevent}).subscribe((response:any)=>{            
            if(response.valid){                               
              this.event = response.data;
              this.eventId = this.event.idEvent;
              let namedivi:any= response.data.divisions;            
              for(var i=0; i < namedivi.length; i++) {
                if (namedivi[i].id == this.division_id){               
                this.namedivsion =  namedivi[i].division_name;
                this.division= namedivi[i];
                }  
              }        
            
            }
          });
      })
    }


  removeTeam(event,idTeam,nameTeam:string,team)
  {    
    event.stopPropagation();
    Swal.fire({
      title: `Are you sure to delete the Team: ${nameTeam}?`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.value) {
        this._eventTeams.deleteTeamEventById(idTeam).subscribe((res: any) => {
          if (res.valid) {
            this.teams = this.teams.filter(function (id) {
              return id!== team;
            });

          }
        })
      }
    });
  }


  addTeam(division_id) {   
    let event_id = this.eventId;
    const dialogRef = this.dialog.open(AddTeamComponent, {
      width: "30%",
      data: { division_id,event_id},
    });
    dialogRef.afterClosed().subscribe((result) => { 
      if (result) {       
        this.router.navigate(['/superadmin/division-teams',division_id,this.Idevent])
        .then(() => {
          window.location.reload();
        });  
           
     }
    });
  }

  addPlayersTeam(team:any,typeList:string){    
    let event_id = this.eventId;
    let division =this.division;
    const dialogRef = this.dialog.open(AddPlayersTeamComponent, {
     width: "50%",
     data: { division,event_id,typeList,team},
    });
    dialogRef.afterClosed().subscribe((result) => { 
      if (result) {              
          window.location.reload();  
     }
    });
  }
  showAnswers(){
    let idEvent =   this.Idevent ;    
    const dialogRef = this.dialog.open(ShowAnswersComponent, {
     width: "50%",
     data: { idEvent},
    });
    dialogRef.afterClosed().subscribe((result) => { 
      if (result) {              
          window.location.reload();  
     }
    });
  }

  ngOnInit() {
  }

}
