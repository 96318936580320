import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class WaitListService {
  constructor(public http: HttpClient, public router: Router) {}

  getAllWaitList(data: any) {    
    return this.http.get(`${environment.API_URL}waitlist/get/waitlistteams/byidEvent/${data.idEvent}/byidDivision/${data.division_id}`);
    
  }
}
