import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  exhaustMap,
  mergeMap,
} from "rxjs/operators";
import { UserService, AuthService } from "src/app/services/service.index";
import { UserParams } from "src/app/models/user/user-params";
import { UserResponse } from "src/app/models/user/user-response";
import { Router } from "@angular/router";
import * as user from "../actions/user.actions";

// Third libreries
import Swal from "sweetalert2";

// Services
import {
  NotificationService,
  AlertService,
} from "src/app/services/service.index";

@Injectable()
export class UserEffects {
  constructor(
    private _notificationService: NotificationService,
    private _userService: UserService,
    private _authService: AuthService,
    private actions$: Actions,
    public router: Router
  ) {
    //
  }

  public authUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<user.UserLoadAction>(user.UserActionType.Auth),
      map((action) => action.payload),
      switchMap((params: UserParams) =>
        this._authService.login(params).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Login`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Login`);
              }
            } else {
              this._authService.setSessionStorage(response.data);
              // Modal
              this.router.navigate(["/superadmin"]);
            }
            return new user.UserLoadSuccessAction(response);
          }),
          catchError((error) => of(new user.UserLoadFailAction(error)))
        )
      )
    )
  );

  public createUser$ = createEffect(() =>
  this.actions$.pipe(
    ofType<user.UserLoadAction>(user.UserActionType.Create),
    map((action) => action.payload),
    switchMap((params: UserParams) =>
      this._userService.create(params.data).pipe(
        map((response: any) => {
          if (!response.valid) {
            let errorMsg = "";
            if (response.errors) {
              const reversedKeys = Object.keys(response.errors).reverse();
              reversedKeys.forEach((key) => {
                errorMsg = `${
                  response.errors[key]["param"].charAt(0).toUpperCase() +
                  response.errors[key]["param"].slice(1)
                }: ${response.errors[key]["msg"]}`;
                this._notificationService.showError(`${errorMsg}`, `Create`);
              });
            } else {
              errorMsg = response.message;
              this._notificationService.showError(`${errorMsg}`, `Create`);
            }
          } else {
            setTimeout(() => {
              Swal.fire({
                title: "Success",
                text: "Create User",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
              });
            }, 800);
          }
          return new user.UserLoadSuccessAction(response);
        }),
        catchError((error) => of(new user.UserLoadFailAction(error)))
      )
    )
  )
);


  public updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<user.UserLoadAction>(user.UserActionType.Update),
      map((action) => action.payload),
      switchMap((params: UserParams) =>
        this._userService.update(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Update`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Update`);
              }
            } else {
              setTimeout(() => {
                Swal.fire({
                  title: "Success",
                  text: "Update User",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }, 800);
            }
            return new user.UserLoadSuccessAction(response);
          }),
          catchError((error) => of(new user.UserLoadFailAction(error)))
        )
      )
    )
  );

  public getAllUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<user.UserLoadAction>(user.UserActionType.GetAll),
      map((action) => action.payload),
      switchMap((params: UserParams) =>
        this._userService.getAll().pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Login`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Login`);
              }
            }
            return new user.UserLoadSuccessAction(response);
          }),
          catchError((error) => of(new user.UserLoadFailAction(error)))
        )
      )
    )
  );

  public enabledUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<user.UserLoadAction>(user.UserActionType.Enabled),
      map((action) => action.payload),
      switchMap((params: UserParams) =>
        this._userService.enabled(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Enabled`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Enabled`);
              }
            } else {
              setTimeout(() => {
                Swal.fire({
                  title: "Success",
                  text: "Update User",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }, 800);
            }
            return new user.UserLoadSuccessAction(response);
          }),
          catchError((error) => of(new user.UserLoadFailAction(error)))
        )
      )
    )
  );

  public deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<user.UserLoadAction>(user.UserActionType.Delete),
      map((action) => action.payload),
      switchMap((params: UserParams) =>
        this._userService.delete(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Delete`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Delete`);
              }
            } else {
              setTimeout(() => {
                Swal.fire({
                  title: "Success",
                  text: "Update User",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }, 800);
            }
            return new user.UserLoadSuccessAction(response);
          }),
          catchError((error) => of(new user.UserLoadFailAction(error)))
        )
      )
    )
  );
}
