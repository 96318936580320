import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-customers",
  templateUrl: "./customers.component.html",
  styleUrls: ["./customers.component.css"],
})
export class CustomersComponent implements OnInit {
  loading: boolean = false;

  constructor() {}

  ngOnInit() {
    //
  }
}
