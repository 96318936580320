import { Component, OnInit,Inject, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {EnventTeams} from "src/app/services/teams/team.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelect } from '@angular/material';

@Component({
  selector: 'app-edit-single',
  templateUrl: './edit-single.component.html',
  styleUrls: ['./edit-single.component.css']
})
export class EditSingleComponent implements OnInit {
  public teamsFormGroup: FormGroup;
  @ViewChild('matSelect1', { static: false }) matSelect1: MatSelect;
  @ViewChild('matSelect2', { static: false }) matSelect2: MatSelect;
  teams:any;
  game;
  currents;
  home;
  away
  constructor(
    private _formBuilder: FormBuilder,
    private _eventTeams: EnventTeams,
    public dialogRef: MatDialogRef<EditSingleComponent>,
    public dialog: MatDialog,   
  /* @Optional()*/ @Inject(MAT_DIALOG_DATA) public data: any,
  )
  { 
       
  }
  ngOnInit()
  { 
    this.teams = this.data.values;
    this.game = this.data.game;
    this.currents = this.data.current;

    console.log("ccccc", this.game)
  }
  close(){   
    this.dialogRef.close({home:this.home,away:this.away,name:this.game});
  }
  selectedHome(ev){
    this.home = Number(ev.target.value)
  }
  selectedAway(ev){
    this.away = Number(ev.target.value)
  }
  isWinnerOf(obj){
    return typeof obj === "string" ? true : false;
  }


}
