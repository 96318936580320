import { Action } from "@ngrx/store";
import { TypeDivisionParams } from "../../models/type_division/type_division_params";
import {TypeDivisionResponse} from "../../models/type_division/type_division_response";

export enum TypeDivisionActionType {
  Get = "[Division] Get",
  Loading = "[Division] Loading",
  LoadSuccess = "[Division] LoadSuccess",
  LoadFailure = "[Division] LoadFailure",
}

export class TypeDivisionLoadAction implements Action {
  public readonly type = TypeDivisionActionType.Loading;
  constructor(public payload: TypeDivisionParams) {}
}

export class TypeDivisionGetAction implements Action {
  public readonly type = TypeDivisionActionType.Get;
  constructor(public payload: TypeDivisionParams) {}
}

export class TypeDivisionLoadSuccessAction implements Action {
  public readonly type = TypeDivisionActionType.LoadSuccess;
  constructor(public payload: TypeDivisionResponse) {}
}

export class TypeDivisionLoadFailAction implements Action {
  public readonly type = TypeDivisionActionType.LoadFailure;
  constructor(public error: any) {}
}

export type TypeDivisionAction =
  | TypeDivisionLoadAction
  | TypeDivisionLoadSuccessAction
  | TypeDivisionLoadFailAction;
