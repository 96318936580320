import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateFields, fieldsAdapter } from '../../store/states/fields.state';

export const {
  selectIds: _selectFieldsDataIds,
  selectEntities: _selectFieldsEntities,
  selectAll: _selectAllFields,
  selectTotal: _selectFieldsTotal
} = fieldsAdapter.getSelectors();

export const selectState = createFeatureSelector<StateFields>('fields');

export const selectFieldsIds = createSelector(
  selectState,
  _selectFieldsDataIds
);

export const selectFieldsEntities = createSelector(
  selectState,
  _selectFieldsEntities
);

export const selectAllFields = createSelector(
  selectState,
  _selectAllFields
);

export const selectFieldsResponse = createSelector(
  selectState,
  (state: StateFields): any => state.fields
);

export const selectFieldsError = createSelector(
  selectState,
  (state: StateFields): boolean => state.error
);

export const selectFieldsLoading = createSelector(
  selectState,
  (state: StateFields): any => state
);
