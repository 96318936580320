import { Action } from "@ngrx/store";
import { EventDetailsParams } from "../../models/event-details/event-details-params";
import { EventDetailsResponse } from "../../models/event-details/event-details-response";

export enum EventDetailsActionType {
  Save = "[EventDetails] Save",
  Get = "[EventDetails] Get",
  GetAll = "[EventDetails] GetAll",
  Loading = "[EventDetails] Loading",
  LoadSuccess = "[EventDetails] LoadSuccess",
  LoadFailure = "[EventDetails] LoadFailure",
}

export class EventDetailsLoadAction implements Action {
  public readonly type = EventDetailsActionType.Loading;
  constructor(public payload: EventDetailsParams) {}
}

export class EventDetailsSaveAction implements Action {
  public readonly type = EventDetailsActionType.Save;
  constructor(public payload: EventDetailsParams) {}
}

export class EventDetailsGetAction implements Action {
  public readonly type = EventDetailsActionType.Get;
  constructor(public payload: EventDetailsParams) {}
}

export class EventDetailsGetAllAction implements Action {
  public readonly type = EventDetailsActionType.GetAll;
  constructor(public payload: EventDetailsParams) {}
}

export class EventDetailsLoadSuccessAction implements Action {
  public readonly type = EventDetailsActionType.LoadSuccess;
  constructor(public payload: EventDetailsResponse) {}
}

export class EventDetailsLoadFailAction implements Action {
  public readonly type = EventDetailsActionType.LoadFailure;
  constructor(public error: any) {}
}

export type EventDetailsAction =
  | EventDetailsLoadAction
  | EventDetailsLoadSuccessAction
  | EventDetailsLoadFailAction;
