import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { stringToBoolean } from "../../utils/error.utils";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(public http: HttpClient, public router: Router) {}

  login(data: any) {
    return this.http.post(`${environment.API_URL}auth/authenticate`, data.data);
  }

  logout() {
    this.removeSessionStorage();
    this.router.navigate(["/login"]);
  }

  setSessionStorage(dataUser: Object) {
    let data = dataUser[0];
    localStorage.setItem("sessionInfo", JSON.stringify(data));
  }

  removeSessionStorage() {
    localStorage.removeItem("sessionInfo");
  }

  getSessionStorage() {
    return JSON.parse(localStorage.getItem("sessionInfo"));
  }

  getRolesByEvent(eventId) {
    return this.http.post(`${environment.API_URL}auth/getRolesByEventId`, {eventId: eventId});
  }

  isLogin() {
    const userData = this.getSessionStorage();
    if (localStorage.getItem("sessionInfo") && userData.token) {
      return true;
    }
    return false;
  }
}
