import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventService, EventDetailsService } from "src/app/services/service.index";
import { EventDetails } from "../../models/event-details/event-details";
import { EditSingleComponent } from '../edit-single/edit-single.component';

@Component({
  selector: 'app-mass-edit-divisions',
  templateUrl: './mass-edit-divisions.component.html',
  styleUrls: ['./mass-edit-divisions.component.css']
})
export class MassEditDivisionsComponent implements OnInit {
  formGroup: FormGroup;
  eventId: number;
  divisions: EventDetails[] = [];
  modifiedDivisions: Set<number> = new Set();
  nameEvent:string;

  constructor(
    private _formBuilder: FormBuilder,
    private _eventService: EventService,
    private _eventDetails: EventDetailsService,
    public dialogRef: MatDialogRef<EditSingleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formGroup = this._formBuilder.group({});
  }

  ngOnInit() {
    this.eventId = this.data.idEvent;
    if (this.eventId) {
      // Obtener Event - Divisions
      this._eventService.getById({ idEvent: this.eventId }).subscribe((response: any) => {
        if (response.valid) {
          this.divisions = response.data.divisions;
          this.nameEvent = response.data.divisions[0].idEvent.name;
          this.initForm();
        }
      });
    }
  }

  initForm() {
    const formControls = {};
    this.divisions.forEach((division, index) => {
      formControls[`divisionId_${index}`] = [division.id];
      formControls[`division_name_${index}`] = [division.division_name, Validators.required];
      formControls[`price_${index}`] = [division.price, Validators.required];
      formControls[`price_end_${index}`] = [division.price_end ? new Date(division.price_end) : null, Validators.required];
    });
    this.formGroup = this._formBuilder.group(formControls);
  }

  save(): void {

    const divisionsArray = this.divisions.map((_, index) => ({
      id: this.formGroup.get(`divisionId_${index}`).value,
      price: this.formGroup.get(`price_${index}`).value,
      price_end: this.formGroup.get(`price_end_${index}`).value
    }));

    if (divisionsArray.length > 0) {
      this._eventDetails.postEditAllDivisions({ data: divisionsArray }).subscribe((response: any) => {
        if (response.valid) {
          this.dialogRef.close(true);
        }
      });
    }
  }
}
