import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(private toastController: ToastController) {}

  async presentToastFull(
    message: any,
    color: string,
    position: any,
    duration: number
  ) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: position,
      color: color,
    });
    toast.present();
  }

  async presentToast(message: any, color: string, position: any) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2500,
      position: position,
      color: color,
    });
    toast.present();
  }
}
