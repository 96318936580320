import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateTypeEvent, typeEventAdapter } from '../../store/states/type-event.state';

export const {
  selectIds: _selectTypeEventDataIds,
  selectEntities: _selectTypeEventEntities,
  selectAll: _selectAllTypeEvent,
  selectTotal: _selectTypeEventTotal
} = typeEventAdapter.getSelectors();

export const selectState = createFeatureSelector<StateTypeEvent>('typeEvent');

export const selectTypeEventIds = createSelector(
  selectState,
  _selectTypeEventDataIds
);

export const selectTypeEventEntities = createSelector(
  selectState,
  _selectTypeEventEntities
);

export const selectAllTypeEvent = createSelector(
  selectState,
  _selectAllTypeEvent
);

export const selectTypeEventResponse = createSelector(
  selectState,
  (state: StateTypeEvent): any => state.typeEvent
);

export const selectTypeEventError = createSelector(
  selectState,
  (state: StateTypeEvent): boolean => state.error
);

export const selectTypeEventLoading = createSelector(
  selectState,
  (state: StateTypeEvent): boolean => state.loading
);
