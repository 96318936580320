import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class FieldsService {
  constructor(public http: HttpClient, public router: Router) {}

  create(data: any) {
    return this.http.post(`${environment.API_URL}fields/create`, data);
  }

  update(data: any) {
    return this.http.put(
      `${environment.API_URL}fields/edit/${data.idField}`,
      data
    );
  }

  getAllFields() {
    return this.http.get(`${environment.API_URL}fields/get/all`);
  }

  enabled(data: any) {
    return this.http.put(`${environment.API_URL}fields/${data.idField}`, null);
  }

  delete(data: any) {
    return this.http.delete(`${environment.API_URL}fields/${data.idField}`);
  }

  getFielsdByEvent(data:any) {
    return this.http.get(`${environment.API_URL}fields/fieldsevent/${data.eventId}`);
  }

  getAllFieldsByUser() {    
    return this.http.get(`${environment.API_URL}fields/fieldsbyuser`);    
  }

  validateFieldEvent(data: any) {
    return this.http.get(`${environment.API_URL}fields/validatefieldevent/${data.eventId}/${data.fieldId}`);
  }  
}
