import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";

import {
  AuthService,
  SidebarService,
  SettingsService,
  SharedService,
  UserService,
  EventService, 
  EventDetailsService, 
  GenderService, 
  RolesService, 
  FieldsService, 
  StaffService, 
  LoginGuard,
  StatusLoginGuard,
  LoginAdminGuard,
  LoginSuperAdminGuard,
} from "./service.index";

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    AuthService,
    SidebarService,
    SettingsService,
    SharedService,
    UserService,
    EventService,
    EventDetailsService,
    GenderService,
    RolesService,
    FieldsService,
    StaffService,
    LoginGuard,
    LoginAdminGuard,
    LoginSuperAdminGuard,
    StatusLoginGuard,
  ],
})
export class ServiceModule {}
