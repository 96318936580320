import { ThrowStmt } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BracketService } from 'src/app/services/brackets/brackets.service';
import { GlobalState } from 'src/app/store/states/global.state';



@Component({
  selector: 'app-edit-calendar',
  templateUrl: './edit-calendar.component.html',
  styleUrls: ['./edit-calendar.component.css']
})
export class EditCalendarComponent implements OnInit {

  dates = [];
  fields = [];
  matches = [];
  hours_day = Array(24).fill(0).map((x, i) => i + 1);

  hours: number[] = [];
  minutes: number[] = [];

  selectedHour: number;
  selectedMinute: number;

  inputValue: string = '';
  isInputEmpty: boolean = false;
  from: Date;


  minutesFive: number[] = [];
  selectedMinutes: number;


  onKeyPress(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    const isNumber = /^\d+$/.test(inputChar);
    if (!isNumber) {
      event.preventDefault();
    }
  }


  constructor(
    public dialogRef: MatDialogRef<EditCalendarComponent>,
    public store: Store<GlobalState>,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private bracketService: BracketService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
      this.matches = this.data.matches,
      this.dates = this.data.dates,
      this.fields = this.data.fields,
      this.from = this.data.from,

    this.hours = Array.from({ length: 24 }, (_, i) => i);
    this.minutes = Array.from({ length: 60 }, (_, i) => i);

    for (let minute = 0; minute < 60; minute += 5) {
      this.minutesFive.push(minute);
    }
    this.selectedMinutes = this.minutesFive[1];

  }
  moveTime(minute: string) {    

    if (!this.isInputEmpty) {
      const resultDate = this.matches.map(item => {
        const dataUpdate = new Date(item.date);
        const minuteAdd = parseInt(minute, 10);
        dataUpdate.setMinutes(dataUpdate.getMinutes() + minuteAdd);
        return { ...item, date: dataUpdate.toISOString() };
      });

      const to: Date = this.data.to;
      const toDate = new Date(to);
      toDate.setHours(0, 0, 0, 0);

      const dateResult = resultDate.filter(item => {
        const dateSet = new Date(item.date);
        dateSet.setHours(0, 0, 0, 0);
        return dateSet > toDate;
      });

      this.bracketService.updateMatches(resultDate);
      this.dialogRef.close(true);
    }

  } getTimeValue(hour) {
    return String(hour).padStart(2, '0') + ":00";
  }

}
