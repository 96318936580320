import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class StripeService {
  constructor(public http: HttpClient, public router: Router) {}

  getStripeSubscription() {    
    return this.http.get(`${environment.API_URL}stripe/stripesubscription` );    
  }

  getSessionsStripeStatus(data:any) {
    return this.http.get(`${environment.API_URL}stripe/sessionstripestatus/${data.sesionId}` );    
  }

  getSubscriptionStripeStatus() {
    return this.http.get(`${environment.API_URL}stripe/subscriptionstripestatus` );    
  }
  
}
