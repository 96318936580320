import { Component, OnInit, ViewChild } from '@angular/core';
import {EnventTeams} from "src/app/services/teams/team.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import {ManageTeamsComponent} from  "src/app/modals/manage-teams/manage-teams.component";
import { Router } from '@angular/router';
import Swal from "sweetalert2";


@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent implements OnInit {
  

  // Actions Datatable
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  // Datatables
  displayedColumns: string[] = ["name", "id", "actions"];
  dataSource: MatTableDataSource<any>;


  public teamsFormGroup: FormGroup;
  teams: any[];

  constructor(
    private _formBuilder: FormBuilder,
    private _eventTeams: EnventTeams,      
    public dialog: MatDialog,   
    public router: Router,
  ) { }

  ngOnInit() {

    this.teamsFormGroup = this._formBuilder.group({      
    });

    this._eventTeams.getAllTeams().subscribe((response:any)=>{
       if (response.valid && response.data.length > 0) {
        this.teams = response.data;        
       //console.log('Data_teams->', response.data);    
        this.dataSource = new MatTableDataSource(this.teams);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;                
      }else{
        this.dataSource = new MatTableDataSource([]);
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  newTeam() {
    const dialogRef = this.dialog.open(ManageTeamsComponent, {
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        setTimeout(() => {
          Swal.fire({
            title: "Success",
            text: "Create Team",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }, 1000);
        this.ngOnInit();        
      }
    });
  }

  editTeam(team) {
    //console.log('Editar Team',team);
    const dialogRef = this.dialog.open(ManageTeamsComponent, {
      width: "20%",
      data: team
    });

    dialogRef.afterClosed().subscribe((result) => {       
      if (result == true){               
        setTimeout(() => {
          Swal.fire({
            title: "Success",
            text: "Update Team",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }, 1000);
        this.ngOnInit();                
        }      
    });
  }
}
