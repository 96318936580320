import { ActionReducerMap } from '@ngrx/store';
import { GlobalState } from './states/global.state';

import { userReducer } from './reducers/user.reducers';
import { typeEventReducer } from './reducers/type-event.reducers';
import { eventReducer } from './reducers/event.reducers';
import { eventDetailsReducer } from './reducers/event-details.reducers';
import { genderReducer } from './reducers/gender.reducers';
import { rolesReducer } from './reducers/roles.reducers';
import { fieldsReducer } from './reducers/fields.reducers';
import { staffReducer } from './reducers/staff.reducers';
import { type_divisionReducer } from './reducers/type_division.reducers';
import { yearsReducer } from './reducers/years.reducers';
import { bracketsReducer } from './reducers/brackets.reducers';

export const reducers: ActionReducerMap<GlobalState> = {
  brackets: bracketsReducer,
  user : userReducer,
  typeEvent : typeEventReducer,
  event : eventReducer,
  eventDetails : eventDetailsReducer,
  gender : genderReducer,
  roles : rolesReducer,
  fields : fieldsReducer,
  staff : staffReducer,
  type_division : type_divisionReducer,
  years : yearsReducer,
};
