import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { GlobalState } from "src/app/store/states/global.state";
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  DateAdapter,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { SharedService } from "src/app/services/service.index";



// Modals
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SharedDataService } from "src/app/utils/shared";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD-MM-YY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-tbd-details-brackets",
  templateUrl: "./tbd-details.component.html",
  styleUrls: ["./tbd-details.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class TbdDetailsComponent implements OnInit {
  bracket;
  place;
  constructor(
    private _sharedService: SharedService,
   /* @Optional()*/ public dialogRef: MatDialogRef<any>,
    public store: Store<GlobalState>,
    public dialog: MatDialog,
    public route: ActivatedRoute,
   /* @Optional()*/ @Inject(MAT_DIALOG_DATA) public data: Array<any>,
    private _formBuilder: FormBuilder,
    private shared:SharedDataService
  ) {

  }

  ngOnInit() {

  }

  original_bracket_name = "";
  change(event){
    const option = event.target.options[event.target.selectedIndex];
    this.bracket = JSON.parse(option.getAttribute('data'));
    this.original_bracket_name = this.bracket.name;
  }

  getAtribute(item,pool){
    return JSON.stringify({bracket_id:item.id, pool_id: pool.id, name: item.name+' - '+pool.pool_name})
  }

  changePlace(event){
    this.place = event.target.value;
    this.bracket.name = this.original_bracket_name+' - '+this.place
  }
  stringify(obj){
    return JSON.stringify(obj)
  }
  close(){
    if(this.place && this.bracket){
      this.bracket.name = this.original_bracket_name+' - '+this.place;
      this.dialogRef.close({place:this.place,...this.bracket});
    }
  }


}

