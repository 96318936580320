import { Component, Inject, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { GlobalState } from "src/app/store/states/global.state";
import { SharedService } from "src/app/services/service.index";

// Store


// Third libreries

//Services

// Modals
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import { SharedDataService } from "src/app/utils/shared";



@Component({
  selector: "app-new-game-brackets",
  templateUrl: "./new-game.component.html",
  styleUrls: ["./new-game.component.css"],
})
export class NewGameComponent implements OnInit  {

  team1;
  team2;
  isValid = false;
  temp;
  constructor(
    private _sharedService: SharedService,
    public dialogRef: MatDialogRef<NewGameComponent>,
    public store: Store<GlobalState>,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private shared:SharedDataService
  ) {
    this._sharedService.changeTitile("Create Game");

  }
  switchTeam(event){
    if(this.team1 && this.team1 !==  this.team2 && this.team2){
      this.isValid = true;
    }else{
      this.isValid = false;
    }

  }
  ngOnInit() {
    this.temp = this.data;
  }
  close(){
    this.dialogRef.close(true);
  }
  save(){
    this.dialogRef.close([this.data.values.filter(e=> e && e.id === this.team1)[0],
      this.data.values.filter(e=> e && e.id === this.team2)[0]])
  }
}
