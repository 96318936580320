import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  exhaustMap,
  mergeMap,
} from "rxjs/operators";
import { RolesService, AuthService } from "src/app/services/service.index";
import { RolesParams } from "src/app/models/roles/roles-params";
import { RolesResponse } from "src/app/models/roles/roles-response";
import { Router } from "@angular/router";
import * as roles from "../actions/roles.actions";

// Third libreries
import Swal from "sweetalert2";

// Services
import {
  NotificationService,
  AlertService,
} from "src/app/services/service.index";

@Injectable()
export class RolesEffects {
  constructor(
    private _notificationService: NotificationService,
    private _rolesService: RolesService,
    private actions$: Actions,
    public router: Router
  ) {
    //
  }

  public createRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<roles.RolesLoadAction>(roles.RolesActionType.Create),
      map((action) => action.payload),
      switchMap((params: RolesParams) =>
        this._rolesService.create(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Create`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Create`);
              }
            } else {
              setTimeout(() => {
                Swal.fire({
                  title: "Success",
                  text: "Create Role",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }, 800);
            }
            return new roles.RolesLoadSuccessAction(response);
          }),
          catchError((error) => of(new roles.RolesLoadFailAction(error)))
        )
      )
    )
  );

  public updateRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<roles.RolesLoadAction>(roles.RolesActionType.Update),
      map((action) => action.payload),
      switchMap((params: RolesParams) =>
        this._rolesService.update(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Update`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Update`);
              }
            } else {
              setTimeout(() => {
                Swal.fire({
                  title: "Success",
                  text: "Update Role",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }, 800);
            }
            return new roles.RolesLoadSuccessAction(response);
          }),
          catchError((error) => of(new roles.RolesLoadFailAction(error)))
        )
      )
    )
  );

  public getAllRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<roles.RolesLoadAction>(roles.RolesActionType.Get),
      map((action) => action.payload),
      switchMap((params: RolesParams) =>
        this._rolesService.getAllRoles().pipe(
          map((response: any) => {

            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Roles`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Roles`);
              }
            }
            return new roles.RolesLoadSuccessAction(response);
          }),
          catchError((error) => {
            console.error('Error loading roles:', error);
            return of(new roles.RolesLoadFailAction(error));
          })        )
      )
    )
  );

  public enabledRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<roles.RolesLoadAction>(roles.RolesActionType.Enabled),
      map((action) => action.payload),
      switchMap((params: RolesParams) =>
        this._rolesService.enabled(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Enabled`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Enabled`);
              }
            } else {
              setTimeout(() => {
                Swal.fire({
                  title: "Success",
                  text: "Update Role",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }, 800);
            }
            return new roles.RolesLoadSuccessAction(response);
          }),
          catchError((error) => of(new roles.RolesLoadFailAction(error)))
        )
      )
    )
  );

  public deleteRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<roles.RolesLoadAction>(roles.RolesActionType.Delete),
      map((action) => action.payload),
      switchMap((params: RolesParams) =>
        this._rolesService.delete(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Delete`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Delete`);
              }
            } else {
              setTimeout(() => {
                Swal.fire({
                  title: "Success",
                  text: "Update Role",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }, 800);
            }
            return new roles.RolesLoadSuccessAction(response);
          }),
          catchError((error) => of(new roles.RolesLoadFailAction(error)))
        )
      )
    )
  );
}
