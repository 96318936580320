import { Routes, RouterModule } from "@angular/router";
import { AddonsComponent } from './addons/addons.component';
import { EventsComponent } from './events/events.component';
import { OverviewComponent } from './overview/overview.component';
import { UsersComponent } from './users/users.component';
import { RegistrationComponent } from './registration/registration.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FieldsComponent } from './fields/fields.component';
import { RolesComponent } from './roles/roles.component';
import {PoolsBracketsComponent} from "./pools-brackets/pools-brackets.component";

import { PoolsComponent } from "./pools/pools.component";
import { PoolGamesComponent } from "./pool-games/pool-games.component";
import { MatchesComponent } from "./matches/matches.component";
import {BracketsComponent} from "./brackets/brackets.component";
import { DivisionTeamsComponent } from "./division-teams/division-teams.component";


import { CalendarComponent } from './calendar/calendar.component';
import { PoolGamesDoubleComponent } from "./pool-games-double/pool-games-double.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { ReportsComponent } from "./reports/reports.component";
import { TeamsComponent } from "./teams/teams.component";
import { StripeComponent } from "./stripe/stripe.component";
import { NewsFeedComponent } from './news-feed/news-feed.component';


const superadminRoutes: Routes =[
    { path: 'event/:eventId/calendar', component: CalendarComponent },
    { path: 'event/:eventId/division/:division_id/pools/matches/:bracket_id', component: MatchesComponent },

    { path: 'event/:eventId/division/double/:division_id/pools/games/:bracket_id', component: PoolGamesDoubleComponent },
    { path: 'event/:eventId/division/:division_id/pools/games/:bracket_id', component: PoolGamesComponent },

    { path: 'event/:eventId/division/:division_id/pools/:bracket_id', component: PoolsComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'addons', component: AddonsComponent },
    { path: 'events', component: EventsComponent },
    { path: 'pools-brackets/:eventId', component: PoolsBracketsComponent },
    { path: 'event/:eventId/brackets/:division_id', component: BracketsComponent },
    { path: 'division-teams/:division_id/:eventId', component: DivisionTeamsComponent },
    { path: 'overview', component: OverviewComponent },
    { path: 'users', component: UsersComponent },
    { path: 'registration', component: RegistrationComponent },
    { path: 'edit-event/:eventId', component: RegistrationComponent },
    { path: 'registration/:eventId/:duplicate', component: RegistrationComponent },
    { path: 'fields', component: FieldsComponent },
    { path: 'teams', component: TeamsComponent },
    { path: 'roles', component: RolesComponent },
    { path: 'notifications', component: NotificationsComponent },
    { path: 'reports', component: ReportsComponent },
    { path: 'stripe', component: StripeComponent },
    { path: 'stripe/oauthcomplete', component: StripeComponent },
    { path: 'news-feed', component: NewsFeedComponent },
    { path: 'news-feed/:eventId', component: NewsFeedComponent },
    { path: '', redirectTo: '/superadmin/dashboard', pathMatch: 'full' }
];

export const SUPERADMIN_ROUTES = RouterModule.forChild( superadminRoutes );
