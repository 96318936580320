import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSelectChange } from '@angular/material';
import { EventDetailsService, EventService, NotificationService, SharedService } from 'src/app/services/service.index';
import * as moment from "moment";
import { UserService } from '../../../services/user/user.service';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  events;
  event;
  divisions;
  division;
  type;
  message;
  constructor(
    public dialog: MatDialog,
    private _sharedService: SharedService,
    private _eventService: EventService,
    private divService:EventDetailsService,
    private noti:NotificationService,
    private usrService:UserService
  ) {
    this._sharedService.changeTitile("Notifications Center");
   }

  ngOnInit() {
    this._eventService.getByUser().subscribe((res:any)=>{
      if (res.valid && res.data.length > 0) {
        this.events = res.data.map((element: any) => ({
          id:element.idEvent,name:element.name
        }));
      } 
    })
  }
  
  async changeEvents(event:MatSelectChange){
    let divisions:any = await this.divService.getByEvent(event.source.value).toPromise();
    this.divisions = divisions.data.map((element)=>{
      return {
        name:element.division_name,
        id: element.id
      }
    });
    
  }
  changeDivision(event:MatSelectChange){
    this.division = event.source.value;
  }
  changeUser(event:MatSelectChange){
    this.type = event.source.value;
  }

  async sendMessage(){
    if(this.event && this.division && this.type){
      if(this.message){
         let response = await this.usrService.sendNotification({
            event:this.event,
            division:this.division,
            type:this.type,
            message:this.message
          }).toPromise();
          console.log('response',response);
      }else{
        this.noti.showError("A message is required","Error");
      }
    }else{
      this.noti.showError("Filters required","Error");
    }
  }

}
