import { createSelector, createFeatureSelector } from '@ngrx/store';
 
import { StateBrackets, bracketAdapter } from '../states/brackets.state';

export const {

} = bracketAdapter.getSelectors();

export const bracketsState = createFeatureSelector<StateBrackets>('brackets');

 

export const selectBracketError = createSelector(
  bracketsState,
  (state: StateBrackets): boolean => state.error
);

export const selectEventLoading = createSelector(
  bracketsState,
  (state: StateBrackets): any => state
);
