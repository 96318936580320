import { Component, Inject, OnInit, Optional } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { GlobalState } from "src/app/store/states/global.state";
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  DateAdapter,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { SharedService } from "src/app/services/service.index";

// Store
import * as bracketsSelector from "src/app/store/selectors/brackets.selectors";

import {
  ResetSuccess
} from "src/app/store/actions/brackets.actions";

// Third libreries
import Swal from "sweetalert2";

//Services

// Modals
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BracketSaveAction } from "src/app/store/actions/brackets.actions";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BracketParams } from "src/app/models/bracket/bracket-params";
import { SharedDataService } from "src/app/utils/shared";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD-MM-YY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-new-brackets",
  templateUrl: "./new-brackets.component.html",
  styleUrls: ["./new-brackets.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class NewBracketsComponent implements OnInit {

  public bracketFormGroup: FormGroup;
  private subscription: Subscription = new Subscription();
  format_game = new Set(['Round Robin', 'Single Elimination', 'Double Elimination', 'Consolation'])
  div_id;
  loadingEventDetails?: boolean = true;

  errorBracketDetails?: boolean;
  public errorEventDetails$?: Observable<boolean>;


  constructor(
    private _sharedService: SharedService,
   /* @Optional()*/ public dialogRef: MatDialogRef<NewBracketsComponent>,
    public store: Store<GlobalState>,
    public dialog: MatDialog,
    public route: ActivatedRoute,
   /* @Optional()*/ @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private shared:SharedDataService
  ) {
    this.store
      .pipe(select(bracketsSelector.selectEventLoading))
      .subscribe((data) => {
         if(data.brackets && !data.brackets.error){
            setTimeout(() => {
              Swal.fire({
                title: "Success",
                text: "Bracket Created",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
              });
            }, 800);
            this.shared.default_pool = data.brackets.data;
            this.close();
         }
      });
  }

  ngOnInit() {
      this.bracketFormGroup = this._formBuilder.group({
        division_id: [this.data && this.data.division_id  ],
        name: ["", Validators.required],
        format: ["", Validators.required],
        game_length: ["", Validators.required],
        rest_time: ["", Validators.required],
      });
      /*this.store.dispatch(new BracketGetByDivisionId(<any>{
        data: { event_id: params.eventId }
      }));*/
      this.store
      .pipe(select(bracketsSelector.selectBracketError))
      .subscribe((error) => {
        this.errorBracketDetails = error;
      });
  }
  close(){
    this.store.dispatch(new ResetSuccess())
    this.dialogRef.close(true);
  }
  create(){
    this.store.dispatch(
      new BracketSaveAction(<BracketParams>{
        data: { ...this.bracketFormGroup.value },
      })
    );
  }

}
