import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import {Event} from "../models/event/event";
import {Teams} from "../models/teams/teams";

@Injectable({
  providedIn: "root",
})
export class SharedDataService {

  public default_pool:any = null;
  public event:Event = {};


  constructor() {}

  async  asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  getLocalNameFromMatch(match){
    if(match.teamLocal){
      return match.teamLocal.Teams ? match.teamLocal.Teams.name :  match.teamLocal.PoolTBD.name
    }

    if(!match.teamLocal){
      const format = match.Pools.bracket.format;
      if(format === "Single Elimination" || format === "Double Elimination"){
        if(match.matchesElimination && match.matchesElimination.teamRelatedLocal1){
          const seed = match.matchesElimination.seedLocal.charAt(0).toUpperCase() + match.matchesElimination.seedLocal.slice(1)
          return seed + ' ' + match.matchesElimination.teamRelatedLocal1.name;
        }
      }
    }

    return match.matchesElimination.teamRelatedLocal ? match.matchesElimination.teamRelatedLocal.name : '---';
  }

  getVisitorNameFromMatch(match){
    if(match.teamVisitor){
      return match.teamVisitor.Teams ? match.teamVisitor.Teams.name :  match.teamVisitor.PoolTBD.name
    }

    if(!match.teamVisitor){
      const format = match.Pools.bracket.format;
      if(format === "Single Elimination" || format === "Double Elimination"){
        if(match.matchesElimination && match.matchesElimination.teamRelatedVisitor2){
          const seed = match.matchesElimination.seedVisitor.charAt(0).toUpperCase() + match.matchesElimination.seedVisitor.slice(1)
          return seed + ' ' + match.matchesElimination.teamRelatedVisitor2.name;
        }
      }
    }

    return match.matchesElimination.teamRelatedVisitor ? 'Winner of' + match.matchesElimination.teamRelatedVisitor.name : '---';
  }
}
