import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SharedService} from "../../../services/shared/shared.service";
import {MatDialog} from "@angular/material/dialog";
import {SharedDataService} from "../../../utils/shared";
import {BracketService} from "../../../services/brackets/brackets.service";
import {Bracket} from "../../../models/bracket/bracket";
import {Event} from "../../../models/event/event";
import Swal from "sweetalert2";
import {EventEnabledAction} from "../../../store/actions/event.actions";
import {EventParams} from "../../../models/event/event-params";
import {EventService} from "../../../services/event/event.service";

@Component({
  selector: 'app-pools-brackets',
  templateUrl: './brackets.component.html',
  styleUrls: ['./brackets.component.css']
})
export class BracketsComponent implements OnInit {
  division_id: number;
  brackets: Bracket[];
  event: Event = {};

  constructor(
    private route: ActivatedRoute,
    private _sharedService: SharedService,
    private _bracketsService: BracketService,
    public dialog: MatDialog,
    private shared:SharedDataService,
    private _eventService:EventService
  ) {
    this._sharedService.changeTitile("");

    this.shared.default_pool = null;

    route.params.subscribe((params) => {
      const eventId = params.eventId;
      //Get Event
      this._eventService.getById({idEvent:eventId}).subscribe((response:any)=>{
        if(response.valid){
          this.event = response.data;
        }
      });

      this.division_id = params.division_id;

      if(this.division_id){
        //Get Brackets
        _bracketsService.getBracketsByDivisionId({division_id:this.division_id}).subscribe((response:any)=>{
          if(response.valid){
            this.brackets = response.data;            
          }
        });
      }
    })
  }

  removeBracket(event,bracket){
    event.stopPropagation();
    Swal.fire({
      title: `Are you sure to delete the Bracket: ${bracket.name}?`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.value) {
        this._bracketsService.removeBracket(bracket.id).subscribe((res: any) => {
          if (res.valid) {
            this.brackets = this.brackets.filter(function (bracketItem) {
              return bracketItem !== bracket;
            });
          }
        })
      }
    });

  }
  
  link_rute(event_id, division_id, bracket_id, state_rute) {
    if (state_rute == 'created') {
      window.location.href =`/#/superadmin/event/${event_id}/division/${division_id}/pools/${bracket_id}`;
    } else if(state_rute == 'Single Elimination') {
      window.location.href =`/#/superadmin/event/${event_id}/division/${division_id}/pools/games/${bracket_id}`;
    } else if(state_rute == 'Round Robin') {
      window.location.href =`/#/superadmin/event/${event_id}/division/${division_id}/pools/games/${bracket_id}`;
    } else if(state_rute == 'Double Elimination') {
      window.location.href =`/#/superadmin/event/${event_id}/division/double/${division_id}/pools/games/${bracket_id}`;
    }else if(state_rute =='round_robin_score'){
      window.location.href =`/#/superadmin/event/${event_id}/division/${division_id}/pools/matches/${bracket_id}`;
    }
  }

  ngOnInit() {

  }
}
