import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateYears, yearsAdapter } from '../../store/states/years.state';

export const {
  selectIds: _selectYearsDataIds,
  selectEntities: _selectYearsEntities,
  selectAll: _selectAllYears,
  selectTotal: _selectYearsTotal
} = yearsAdapter.getSelectors();

export const selectState = createFeatureSelector<StateYears>('years');

export const selectYearsIds = createSelector(
  selectState,
  _selectYearsDataIds
);

export const selectYearsEntities = createSelector(
  selectState,
  _selectYearsEntities
);

export const selectAllYears = createSelector(
  selectState,
  _selectAllYears
);

export const selectYearsResponse = createSelector(
  selectState,
  (state: StateYears): any => state.years
);

export const selectYearsError = createSelector(
  selectState,
  (state: StateYears): boolean => state.error
);

export const selectYearsLoading = createSelector(
  selectState,
  (state: StateYears): boolean => state.loading
);
