import { Action } from "@ngrx/store";
import { BracketParams } from "../../models/bracket/bracket-params";
import { BracketResponse } from "../../models/bracket/bracket-response";

export enum BracketsActionType {
  Save = "[Bracket] Save",
  Get = "[Bracket] Get",
  GetByEventId = "[Bracket] GetByDivisionId",
  GetAll = "[Bracket] GetAll",
  Loading = "[Bracket] Loading",
  LoadSuccess = "[Bracket] LoadSuccess",
  ResetSuccess = "[Bracket] ResetSuccess",
  LoadFailure = "[Bracket] LoadFailure",
}

export class BracketLoadAction implements Action {
  public readonly type = BracketsActionType.Loading;
  constructor(public payload: BracketParams) {}
}

export class ResetSuccess implements Action {
  public readonly type = BracketsActionType.ResetSuccess;
  constructor() {}
}

export class BracketSaveAction implements Action {
  public readonly type = BracketsActionType.Save;
  constructor(public payload: BracketParams) {}
}

export class BracketGetAction implements Action {
  public readonly type = BracketsActionType.Get;
  constructor(public payload: BracketParams) {}
}

export class BracketGetAllAction implements Action {
  public readonly type = BracketsActionType.GetAll;
  constructor(public payload: BracketParams) {}
}

export class BracketLoadSuccessAction implements Action {
  public readonly type = BracketsActionType.LoadSuccess;
  constructor(public payload: BracketResponse) {}
}

export class BracketLoadFailAction implements Action {
  public readonly type = BracketsActionType.LoadFailure;
  constructor(public error: any) {}
}
export class BracketGetByDivisionId implements Action {
  public readonly type = BracketsActionType.GetByEventId;
  constructor(public payload: BracketParams) {}
}

export type BracketActions =
  | BracketLoadAction
  | BracketLoadSuccessAction
  | BracketLoadFailAction
  | BracketGetByDivisionId
  | ResetSuccess;
