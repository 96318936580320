import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { User } from "../../models/user/user";

export interface StateUser extends EntityState<User> {
  user: any;
  error: boolean;
  loading: boolean;
}

export const userAdapter: EntityAdapter<User> = createEntityAdapter<User>({});

export const initialStateUser: StateUser = userAdapter.getInitialState({
  user: null,
  error: false,
  loading: true,
});
