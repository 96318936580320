import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { reducers } from './store.index';

import { UserEffects } from '../store/effects/user.effects';
import { TypeEventEffects } from '../store/effects/type-event.effects';
import { EventEffects } from '../store/effects/event.effects';
import { EventDetailsEffects } from '../store/effects/event-details.effects';
import { GenderEffects } from '../store/effects/gender.effects';
import { RolesEffects } from '../store/effects/roles.effects';
import { FieldsEffects } from '../store/effects/fields.effects';
import { StaffEffects } from '../store/effects/staff.effects';
import { Type_divisionEffects } from './effects/type_division.effects';
import { YearsEffects } from '../store/effects/years.effects';
import { BracketEffects } from './effects/brackets.effects';

@NgModule({
  imports: [
    HttpClientModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(
      [
        BracketEffects,
        UserEffects,
        TypeEventEffects,
        EventEffects,
        EventDetailsEffects,
        GenderEffects,
        RolesEffects,
        FieldsEffects,
        StaffEffects,
        Type_divisionEffects,
        YearsEffects,
      ]
    ),
    StoreDevtoolsModule.instrument(
      {
        maxAge: 25,
        logOnly: environment.production
      }
    ),
  ],
  exports: [
    StoreModule
  ]
})
export class StorageModule { }
