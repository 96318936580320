import { Component, Inject, OnInit } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { take, startWith, map } from "rxjs/operators";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { GlobalState } from "src/app/store/states/global.state";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";

// Third libreries
import Swal from "sweetalert2";
import * as moment from "moment";

// Store
import {
  FieldsCreateAction,
  FieldsUpdateAction,
} from "src/app/store/actions/fields.actions";
import { FieldsParams } from "src/app/models/fields/fields-params";
import * as field from "src/app/store/selectors/fields.selectors";

@Component({
  selector: "app-new-field",
  templateUrl: "./new-field.component.html",
  styleUrls: ["./new-field.component.css"],
})
export class NewFieldComponent implements OnInit {
  title: string = "";
  btnAction: string = "";
  idField: number;
  disabledBtn: boolean = false;
  fieldFormGroup: FormGroup;

  // Store
  private subscription: Subscription = new Subscription();

  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NewFieldComponent>,
    public dialog: MatDialog,
    public store: Store<GlobalState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    // Set Forms
    this.fieldFormGroup = this._formBuilder.group({
      name: ["", Validators.required],
      number: ["", Validators.required],
      address: ["", Validators.required],
      state: ["", Validators.required],
      zipCode: ["", Validators.required],  
      

    });

    // Set title
    if (this.data) {     
      console.log('DataEdit->',this.data);
      this.title = "Edit Field";
      this.btnAction = "Update";

      // Form
      this.idField = this.data.id;      
      this.fieldFormGroup.setValue({
        name: this.data.nameOnly,
        number: this.data.number,        
        address: this.data.address,
        state: this.data.state,
        zipCode: this.data.zipCode,
      });
    } else {
      this.title = "New Field";
      this.btnAction = "Create";
    }
  }

  // Create or Update
  async action() {
    this.disabledBtn = true;

    if (this.data) {      
      this.store.dispatch(        
        new FieldsUpdateAction(<FieldsParams>{
          data: { ...this.fieldFormGroup.value, idField: this.idField,userId: this.data.userId},
        })
      );
    } else {      
      this.store.dispatch(
        new FieldsCreateAction(<FieldsParams>{
          data: {...this.fieldFormGroup.value}
        })
      );
     //Api Service
    }

    const action = await this.store
      .select(field.selectFieldsLoading)
      .pipe(take(2))
      .toPromise();

    if (action.fields.valid) {
      this.dialogRef.close(true);      
    } else {                 
      this.dialogRef.close(false);      
      this.disabledBtn = false;
    }

    this.store.pipe(select(field.selectFieldsError)).subscribe((error) => {
      console.log("error field: ", error);
    });
  }

  validateFormat(event) {
    let key;
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
     if (!regex.test(key)) {
      event.returnValue = false;
       if (event.preventDefault) {
        event.preventDefault();
       }
     }
    }
}
