import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CKEditorModule } from 'ckeditor4-angular';

// Routes
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./auth/login/login.component";
import { SignupComponent } from "./auth/signup/signup.component";
import { ActivationComponent } from "./auth/activation/activation.component";
import { HttpClientModule } from "@angular/common/http";

//Modules
import { AdminModule } from "./pages/admin/admin.module";
import { SuperadminModule } from "./pages/superadmin/superadmin.module";
import { ModalsModule } from "./modals/modals.module";
import { ToastrModule } from "ngx-toastr";
import { IonicModule } from "@ionic/angular";

//Services
import { ServiceModule } from "./services/service.module";

//-- Interceptors --//
import { interceptorProviders } from "./services/interceptors/interceptor.index";

//-- Store --//
import { StorageModule } from "./store/storage.module";
import {PrivacyPolicyComponent} from "./auth/privacy-policy/privacy-policy.component";
import {SiteComponent} from "./auth/site/site.component";
import {PlayerInvitationComponent} from "./auth/player_invitation/player_invitation.component";
import {MatStepperModule} from "@angular/material/stepper";
import {EventLandingComponent} from "./auth/event-landing/event-landing.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    PrivacyPolicyComponent,
    ActivationComponent,
    SiteComponent,
    PlayerInvitationComponent,
    EventLandingComponent
  ],
  imports: [
    IonicModule.forRoot(),
    ToastrModule.forRoot(),
    CKEditorModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AdminModule,
    SuperadminModule,
    ModalsModule,
    StorageModule,
    ServiceModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatStepperModule
  ],
  providers: [interceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
