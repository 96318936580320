import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import {TypeDivision} from "../../models/type_division/type_division";


export interface StateTypeDivision extends EntityState<TypeDivision> {
  type_division: any;
  error: boolean;
  loading: boolean;
}

export const type_divisionAdapter: EntityAdapter<TypeDivision> = createEntityAdapter<TypeDivision>({});

export const initialStateDivision: StateTypeDivision = type_divisionAdapter.getInitialState({
  type_division: null,
  error: false,
  loading: true,
});
