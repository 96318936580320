import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService, BracketService, EventService, SharedService, EnventTeams } from "src/app/services/service.index";
import { Event } from "../../models/event/event";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-match',
  templateUrl: './add-match.component.html',
  styleUrls: ['./add-match.component.css']
})
export class AddMatchComponent implements OnInit {

  event: Event;
  eventId: string;
  divisions: any[] = [];
  brackets: any[] = [];
  selectedDivisionBrackets: any[] = [];
  selectedDivision: any = null;
  teams: any[] = [];
  selectedBracket: any = null;
  pools_ids = [];
  pools: any;
  selectedTeam1: any = null;
  selectedTeam2: any = null;
  selectedPool: any = null;
  current_pool_teams: any[] = [];
  pool_ngames: any = {};
  isFormValid: boolean = false;

  constructor(
    public _eventService: EventService,
    private _eventTeams: EnventTeams,
    private _bracketService: BracketService,
    public dialogRef: MatDialogRef<AddMatchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.eventId = this.data.eventId;
    this._eventService.getById({ idEvent: this.eventId }).subscribe((response_event: any) => {
      if (response_event.valid) {
        this.event = response_event.data;
        this.divisions = this.event.divisions;
        this.divisions.forEach((division, index) => {
          division.bracket.forEach(bracket => {
            if (bracket.format == 'Round Robin') {
              this.brackets.push(bracket);
            }
          });
        });

        if (this.brackets.length == 0) {
          this.showAlert("Error", "There is not bracket Round Robin ", "error", 2000, true, false, false);
        } else {

          if (this.divisions.length > 0) {
            this.selectedDivision = this.divisions[0];
            this.selectedDivisionBrackets = this.selectedDivision.bracket.filter(bracket => this.brackets.includes(bracket));
            this.loadTeamsDivision(this.selectedDivision.id);

            if (this.selectedDivisionBrackets.length > 0) {
              this.selectedBracket = this.selectedDivisionBrackets[0];
              this.loadPools(this.selectedBracket.id);
            }
          }
        }
      }
    });
  }

  loadTeamsDivision(division_id) {
    this._eventTeams.getAllByDivision({ division_id: division_id }).subscribe((response: any) => {
      if (response.valid) {
        this.teams = response.data;
      }
    });
  }

  loadPools(bracket_id) {
    this._bracketService.getPoolsGames(bracket_id).subscribe((response: any) => {
      if (response.valid) {
        this.pools = response.data;
      }
    });
  }

  onSelectDivision(event) {
    this.selectedDivision = event.value;
    this.selectedDivisionBrackets = this.selectedDivision.bracket.filter(bracket => this.brackets.includes(bracket));
    if (this.selectedDivisionBrackets.length == 0) {
      this.showAlert("Error", "There is not bracket Round Robin ", "error", 2000, false, false, false);
      this.pools = [];
      this.teams = [];

    } else {
      this.loadTeamsDivision(event.value.id);
      this.loadPools(this.selectedDivisionBrackets[0].id);
      this.checkFormValidity();

    }
  }

  onSelectBracket(event) {
    this.selectedBracket = event.value;
    this.loadPools(this.selectedBracket.id);
    this.checkFormValidity();
  }

  checkFormValidity() {
    this.isFormValid = this.selectedDivision && this.selectedBracket && this.selectedPool &&
      this.selectedTeam1 && this.selectedTeam2 && this.selectedTeam1 !== this.selectedTeam2;
  }

  saveData() {
    this.current_pool_teams = [
      this.selectedTeam1.teamId,
      this.selectedTeam2.teamId,
    ];

    this.pool_ngames = {
      id: this.selectedPool.id,
      bracket_id: this.selectedBracket.id,
      ngames: 1,
      games: this.current_pool_teams,
      eventId: this.eventId,
      division_id: this.selectedDivision.id
    };

    this._bracketService.saveAddNewMatch({ data: this.pool_ngames }).subscribe(
      (res: any) => {
        if (res.valid) {
          this.showAlert("Success", "Data saved", "success", 2000, true, true, false);
        } else {
          console.log('Error:', res);
        }
      },
      (error) => {
        console.error('Error', error);
      }
    );
  }

  showAlert(title: string, text: string, icon: any, timer: number, stateAlert: boolean, estateDialogRef: boolean, buttonConfirm: boolean): void {
    setTimeout(() => {
      Swal.fire({
        title,
        text,
        icon,
        showConfirmButton: buttonConfirm,
        timer
      });
      if (stateAlert) {
        this.dialogRef.close(estateDialogRef);
      }
    }, 800);
  }
}
