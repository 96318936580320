import * as years from "../../store/states/years.state";
import { YearsAction, YearsActionType } from "../../store/actions/years.actions";

export function yearsReducer(
  state = years.initialStateYears,
  action: YearsAction | any
): years.StateYears {
  switch (action.type) {
    case YearsActionType.Loading: {
      return { ...state, loading: true };
    }
    case YearsActionType.LoadSuccess: {
      return  { ...state,
        years: action.payload,
        error: false,
        loading: false,
      };
    }
    case YearsActionType.LoadFailure: {
      return  { ...state,
        years: null,
        error: true,
        loading: false,
      };
    }
    default:
      return state;
  }
}
