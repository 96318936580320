import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Roles } from "../../models/roles/roles";

export interface StateRoles extends EntityState<Roles> {
  roles: any;
  error: boolean;
  loading: boolean;
}

export const rolesAdapter: EntityAdapter<Roles> = createEntityAdapter<Roles>({});

export const initialStateRoles: StateRoles = rolesAdapter.getInitialState({
  roles: null,
  error: false,
  loading: true,
});
