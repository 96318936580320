import { Action } from "@ngrx/store";
import { RolesParams } from "../../models/roles/roles-params";
import { RolesResponse } from "../../models/roles/roles-response";

export enum RolesActionType {
  Get = "[Roles] Get",
  Enabled = "[Roles] Enabled",
  Create = "[Roles] Create",
  Update = "[Roles] Update",
  Delete = "[Roles] Delete",
  GetAll = "[Roles] Get All",
  Loading = "[Roles] Loading",
  LoadSuccess = "[Roles] LoadSuccess",
  LoadFailure = "[Roles] LoadFailure",
}

export class RolesLoadAction implements Action {
  public readonly type = RolesActionType.Loading;
  constructor(public payload: RolesParams) {}
}

export class RolesGetAction implements Action {
  public readonly type = RolesActionType.Get;
  constructor(public payload: RolesParams) {}
}

export class RolesEnabledAction implements Action {
  public readonly type = RolesActionType.Enabled;
  constructor(public payload: RolesParams) {}
}

export class RolesCreateAction implements Action {
  public readonly type = RolesActionType.Create;
  constructor(public payload: RolesParams) {}
}

export class RolesUpdateAction implements Action {
  public readonly type = RolesActionType.Update;
  constructor(public payload: RolesParams) {}
}

export class RolesDeleteAction implements Action {
  public readonly type = RolesActionType.Delete;
  constructor(public payload: RolesParams) {}
}

export class RolesGetAllAction implements Action {
  public readonly type = RolesActionType.GetAll;
  constructor(public payload: RolesParams) {}
}

export class RolesLoadSuccessAction implements Action {
  public readonly type = RolesActionType.LoadSuccess;
  constructor(public payload: RolesResponse) {}
}

export class RolesLoadFailAction implements Action {
  public readonly type = RolesActionType.LoadFailure;
  constructor(public error: any) {}
}

export type RolesAction =
  | RolesLoadAction
  | RolesLoadSuccessAction
  | RolesLoadFailAction;
