import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  exhaustMap,
  mergeMap,
} from "rxjs/operators";
import { GenderService, AuthService } from "src/app/services/service.index";
import { GenderParams } from "src/app/models/gender/gender-params";
import { GenderResponse } from "src/app/models/gender/gender-response";
import { Router } from "@angular/router";
import * as gender from "../actions/gender.actions";

// Third libreries
import Swal from "sweetalert2";

// Services
import {
  NotificationService,
  AlertService,
} from "src/app/services/service.index";

@Injectable()
export class GenderEffects {
  constructor(
    private _notificationService: NotificationService,
    private _eventService: GenderService,
    private actions$: Actions,
    public router: Router
  ) {
    //
  }

  public getAllGender$ = createEffect(() =>
    this.actions$.pipe(
      ofType<gender.GenderLoadAction>(gender.GenderActionType.Get),
      map((action) => action.payload),
      switchMap((params: GenderParams) =>
        this._eventService.getAllGender().pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = '';
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Gender`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Gender`);
              }
            }
            return new gender.GenderLoadSuccessAction(response);
          }),
          catchError((error) => of(new gender.GenderLoadFailAction(error)))
        )
      )
    )
  );
}
