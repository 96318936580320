import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class StaffService {
  constructor(public http: HttpClient, public router: Router) {}

  postThirdStep(data: any) {
    return this.http.post(
      `${environment.API_URL}staff/create/thirdStep/`,
      data.data
    );
  }

}
