import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Event } from "../../models/event/event";

export interface StateEvent extends EntityState<Event> {
  event: any;
  eventSearch: any;
  error: boolean;
  loading: boolean;
}

export const eventAdapter: EntityAdapter<Event> = createEntityAdapter<Event>({});

export const initialStateEvent: StateEvent = eventAdapter.getInitialState({
  event: null,
  eventSearch: null,
  error: false,
  loading: true,
});
