import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  menu: any = [
    {
      titulo: "Dashboard",
      icono: "flaticon2-protection",
      subMenu: [],
      url: "/admin/dashboard",
    },
    {
      titulo: "Actions",
      icono: "flaticon2-protection",
      url: "",
      subMenu: [
        {
          titulo: "Add New",
          url: "",
          subMenu: [
            { titulo: "User", url: "/login" },
            { titulo: "Order", url: "/login" },
          ],
        },
        {
          titulo: "Edit",
          url: "/login",
          subMenu: [],
        },
      ],
    },
  ];
  constructor() {}
}
