import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateStaff, staffAdapter } from '../../store/states/staff.state';

export const {
  selectIds: _selectStaffDataIds,
  selectEntities: _selectStaffEntities,
  selectAll: _selectAllStaff,
  selectTotal: _selectStaffTotal
} = staffAdapter.getSelectors();

export const selectState = createFeatureSelector<StateStaff>('staff');

export const selectStaffIds = createSelector(
  selectState,
  _selectStaffDataIds
);

export const selectStaffEntities = createSelector(
  selectState,
  _selectStaffEntities
);

export const selectAllStaff = createSelector(
  selectState,
  _selectAllStaff
);

export const selectStaffResponse = createSelector(
  selectState,
  (state: StateStaff): any => state.staff
);

export const selectStaffError = createSelector(
  selectState,
  (state: StateStaff): boolean => state.error
);

export const selectStaffLoading = createSelector(
  selectState,
  (state: StateStaff): any => state
);
