import { Component, OnInit, Input, Injectable } from "@angular/core";
import { SharedService } from "src/app/services/service.index";

@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.css"],
})
@Injectable({
  providedIn: "root",
})
export class BreadcrumbsComponent implements OnInit {
  titleSection: string;

  constructor(private _sharedService: SharedService) {
    this._sharedService.getTitleSection$().subscribe((titleSection) => {
      this.titleSection = titleSection;
    });
  }

  ngOnInit() {
    //
  }
}
