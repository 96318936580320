import { Component, OnInit,NgModule, Inject  } from '@angular/core';
import { dislikeMathesService } from "src/app/services/service.index";
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-show-dislike',
  templateUrl: './show-dislike.component.html',
  styleUrls: ['./show-dislike.component.css']
})
export class ShowDislikeComponent implements OnInit {
  commentsDislike: any[] = [];
  numShow: number = 5;
  nameMatch: string; 
  constructor(
    private _dislikeMathesService: dislikeMathesService,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }

  ngOnInit() {

    this._dislikeMathesService.getLikeMatchesByUser({matchId:this.data.matchId}).subscribe((res:any) => {
      if(res.valid){         
         this.commentsDislike =  res.data;
      } else {
        console.log('False->',res);
      }
    });
  }

  showMore() {
    this.numShow += 5;    
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const currentDate = new Date();
    const diffInMs = currentDate.getTime() - date.getTime();
    const diffInMin = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMin / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays > 0) {
      return `${diffInDays} days`;
    } else if (diffInHours > 0) {
      return `${diffInHours} hours`;
    } else {
      return `${diffInMin} minutes`;
    }
  }


}
