import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { EventDetails } from "../../models/event-details/event-details";

export interface StateEventDetails extends EntityState<EventDetails> {
  eventDetails: any;
  error: boolean;
  loading: boolean;
}

export const eventDetailsAdapter: EntityAdapter<EventDetails> = createEntityAdapter<EventDetails>({});

export const initialStateEventDetails: StateEventDetails = eventDetailsAdapter.getInitialState({
  eventDetails: null,
  error: false,
  loading: true,
});
