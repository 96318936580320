import { Component, Inject, OnInit, Optional } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { GlobalState } from "src/app/store/states/global.state";
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  DateAdapter,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { SharedService } from "src/app/services/service.index";

// Store
import * as bracketsSelector from "src/app/store/selectors/brackets.selectors";

import {
  ResetSuccess
} from "src/app/store/actions/brackets.actions";

// Third libreries
import Swal from "sweetalert2";

//Services

// Modals
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BracketSaveAction } from "src/app/store/actions/brackets.actions";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BracketParams } from "src/app/models/bracket/bracket-params";
import { SharedDataService } from "src/app/utils/shared";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD-MM-YY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-scheduling",
  templateUrl: "./scheduling.component.html",
  styleUrls: ["./scheduling.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SchedulingComponent implements OnInit {

  dates = [];
  fields = [];
  hours_day = Array(24).fill(0).map((x, i) => i + 1);
  errorBracketDetails?: boolean;
  public errorEventDetails$?: Observable<boolean>;


  constructor(
    private _sharedService: SharedService,
   /* @Optional()*/ public dialogRef: MatDialogRef<SchedulingComponent>,
    public store: Store<GlobalState>,
    public dialog: MatDialog,
    public route: ActivatedRoute,
   /* @Optional()*/ @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private shared:SharedDataService
  ) {

        //console.log( this.dates);
  }

  ngOnInit() {
   // console.log(this.data.data);
      const startDate = new Date(this.data.data.event.date_from);
      const endDate = new Date(this.data.data.event.date_to);
      this.fields = this.data.data.fields;
      for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        this.dates.push(
          {
            date: date.getFullYear()+'-'+date.toISOString().slice(5, 7)+'-'+date.toISOString().slice(8, 10),
            time:null,
            games:null
          }
        );
      }
      //this.max_games =  Array(Math.round(1200/(this.data.data.event.data.game_length+this.data.data.event.data.rest_time))).fill(0).map((x, i) => i + 1);
     // console.log(this.data);
  }

  close(){
    this.dialogRef.close(false);
  }



  create(){
      if(this.fields.length === 0){
        Swal.fire({
          title: "Error",
          text: "You need at least one valid site",
          icon: "warning",
          showConfirmButton: true,
        });
        return false;
      }
     /* if(this.dates.filter(e=> !e.time ||  !e.games).length > 0){
        Swal.fire({
          title: "Error",
          text: "You need complete all the form",
          icon: "warning",
          showConfirmButton: true,
        });
        return false;
      }*/

      this.dialogRef.close({
        dates:this.dates,
        fields:this.fields
      });

  }
  removeChip(field:any){
    this.fields = this.fields.filter(e=>e.id !== field.id);
  }
  getTimeValue(hour){
    return String(hour).padStart(2, '0')+":00";
  }
}
