import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateGender, genderAdapter } from '../../store/states/gender.state';

export const {
  selectIds: _selectGenderDataIds,
  selectEntities: _selectGenderEntities,
  selectAll: _selectAllGender,
  selectTotal: _selectGenderTotal
} = genderAdapter.getSelectors();

export const selectState = createFeatureSelector<StateGender>('gender');

export const selectGenderIds = createSelector(
  selectState,
  _selectGenderDataIds
);

export const selectGenderEntities = createSelector(
  selectState,
  _selectGenderEntities
);

export const selectAllGender = createSelector(
  selectState,
  _selectAllGender
);

export const selectGenderResponse = createSelector(
  selectState,
  (state: StateGender): any => state.gender
);

export const selectGenderError = createSelector(
  selectState,
  (state: StateGender): boolean => state.error
);

export const selectGenderLoading = createSelector(
  selectState,
  (state: StateGender): boolean => state.loading
);
