import { Action } from "@ngrx/store";
import { YearsParams } from "../../models/years/years-params";
import { YearsResponse } from "../../models/years/years-response";

export enum YearsActionType {
  Get = "[Years] Get",
  Loading = "[Years] Loading",
  LoadSuccess = "[Years] LoadSuccess",
  LoadFailure = "[Years] LoadFailure",
}

export class YearsLoadAction implements Action {
  public readonly type = YearsActionType.Loading;
  constructor(public payload: YearsParams) {}
}

export class YearsGetAction implements Action {
  public readonly type = YearsActionType.Get;
  constructor(public payload: YearsParams) {}
}

export class YearsLoadSuccessAction implements Action {
  public readonly type = YearsActionType.LoadSuccess;
  constructor(public payload: YearsResponse) {}
}

export class YearsLoadFailAction implements Action {
  public readonly type = YearsActionType.LoadFailure;
  constructor(public error: any) {}
}

export type YearsAction =
  | YearsLoadAction
  | YearsLoadSuccessAction
  | YearsLoadFailAction;
