import { HTTP_INTERCEPTORS } from "@angular/common/http";

// Token Interceptor
import { TokenInterceptor } from "./token.interceptor";
import { HttpErrorInterceptor } from './tokenError.interceptor';
import { RequestAnimationInterceptor } from "./request-animation.interceptor";

export const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestAnimationInterceptor,
    multi: true,
  },
];
