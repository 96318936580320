import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateRoles, rolesAdapter } from '../../store/states/roles.state';

export const {
  selectIds: _selectRolesDataIds,
  selectEntities: _selectRolesEntities,
  selectAll: _selectAllRoles,
  selectTotal: _selectRolesTotal
} = rolesAdapter.getSelectors();

export const selectState = createFeatureSelector<StateRoles>('roles');

export const selectRolesIds = createSelector(
  selectState,
  _selectRolesDataIds
);

export const selectRolesEntities = createSelector(
  selectState,
  _selectRolesEntities
);

export const selectAllRoles = createSelector(
  selectState,
  _selectAllRoles
);

export const selectRolesResponse = createSelector(
  selectState,
  (state: StateRoles): any => state.roles
);

export const selectRolesError = createSelector(
  selectState,
  (state: StateRoles): boolean => state.error
);

export const selectRolesLoading = createSelector(
  selectState,
  (state: StateRoles): any => state
);
