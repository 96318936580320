import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  exhaustMap,
  mergeMap,
} from "rxjs/operators";
import { EventService, AuthService } from "src/app/services/service.index";
import { TypeEventParams } from "src/app/models/type-event/type-event-params";
import { TypeEventResponse } from "src/app/models/type-event/type-event-response";
import { Router } from "@angular/router";
import * as typeEvent from "../actions/type-event.actions";

// Third libreries
import Swal from "sweetalert2";

// Services
import {
  NotificationService,
  AlertService,
} from "src/app/services/service.index";

@Injectable()
export class TypeEventEffects {
  constructor(
    private _notificationService: NotificationService,
    private _eventService: EventService,
    private actions$: Actions,
    public router: Router
  ) {
    //
  }

  public getTypeEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<typeEvent.TypeEventLoadAction>(typeEvent.TypeEventActionType.Get),
      map((action) => action.payload),
      switchMap((params: TypeEventParams) =>
        this._eventService.getTypeEvent().pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = '';
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Type Event`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Type Event`);
              }
            }
            return new typeEvent.TypeEventLoadSuccessAction(response);
          }),
          catchError((error) => of(new typeEvent.TypeEventLoadFailAction(error)))
        )
      )
    )
  );
}
