import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class EnventTeams {
  constructor(public http: HttpClient, public router: Router) {}

  getAllByDivision(data: any) {
    return this.http.get(`${environment.API_URL}teams/get/allByDivision/${data.division_id}`);
  }

  deleteTeamEventById(idTeam) {
    return this.http.post(`${environment.API_URL}teams/deleteTeamEventById/`,{id:idTeam});
  }

  getAllTeamsNotDivision(data: any) {
    return this.http.get(`${environment.API_URL}teams/get/allNotDivision/${data.division_id}`);
  }  

  assignTeams(data: any) {
    return this.http.post(`${environment.API_URL}teams/assignTeams`, data.data);
  }
 
  getAllTeams() {
    return this.http.get(`${environment.API_URL}teams/get/allTeams/`);
  }

  saveTeam(data: any) {
    return this.http.post(`${environment.API_URL}teams/create`, data.data);
  }

  updateTeamPlayers(data: any) {    
    return this.http.post(`${environment.API_URL}teams/updateTeamPlayers`, data.data);
  }

  getByEventId(data: any) {   
      return this.http.get(`${environment.API_URL}teams/get/byEventId/${data.eventId}/${data.divisionId}`);
  }

  getPlayersByTeam(data: any){
    return this.http.get(`${environment.API_URL}teams/${data.id}`);
  }

}
