import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Years } from "../../models/years/years";

export interface StateYears extends EntityState<Years> {
  years: any;
  error: boolean;
  loading: boolean;
}

export const yearsAdapter: EntityAdapter<Years> = createEntityAdapter<Years>({});

export const initialStateYears: StateYears = yearsAdapter.getInitialState({
  years: null,
  error: false,
  loading: true,
});
