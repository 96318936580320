import {Component, ComponentRef, ElementRef, Inject, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { FormGroup,FormControl,FormBuilder } from '@angular/forms';
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  DateAdapter,
  MatSelect,
  MatTabGroup,
} from "@angular/material";
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import {AuthService, BracketService, EventService, SharedService,FieldsService} from "src/app/services/service.index";
import Swal from "sweetalert2";
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';


import {MatDialog} from "@angular/material/dialog";
import {SharedDataService} from "src/app/utils/shared";
import {NewBracketsComponent} from "src/app/modals/new-brackets/new-brackets.component";
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {NewGameComponent} from 'src/app/modals/new-game/new-game.component';
import {SchedulingComponent} from 'src/app/modals/scheduling/scheduling.component';
import {EditCalendarComponent} from 'src/app/modals/edit-calendar/edit-calendar.component';
import * as event from 'src/app/store/selectors/event.selectors';
import {Event} from "../../../models/event/event";
import {forEachComment} from "tslint";
import { Fields } from 'src/app/models/fields/fields';

//Date
import {MatDatepickerModule} from '@angular/material/datepicker';
import {AddMatchComponent} from 'src/app/modals/add-match/add-match.component';
export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD-MM-YY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};



interface Field {
  id: number;
  name: string;
  matches?: any[];
}

interface MatchCalendar {
  id: number;
  name: string;
  date_event: Date;
  hour: number;
  fieldId: number;
  game_length: number;
  rest_time: number;
}


@Component({

  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CalendarComponent implements OnInit {
  @ViewChild('tabGroup', {static: false}) tabGroup: MatTabGroup;
  matches;
  unscheduled_matches;
  scheduled_matches;
  checkboxEstados: { [key: string]: boolean} = {};

  dates = [];
  selectedData: any[] = [];
  hours_day = Array(24).fill(0).map((x, i) => i + 1);
  //@ViewChild('todoElement', {static: true}) todoElement: ElementRef;
  event: Event;
  division_id: number;

  myForm: FormGroup;
  from: Date;
  to: Date;
  minutesArray: number[];
  errorMessage: string = '';
  eventId: string;
  countDivisions:number =0;
  @ViewChild('matchesList', {static: false}) matchesList: CdkDrag;
  constructor(
    public sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    private bracketService: BracketService,
    private _sharedService: SharedService,
    private _eventService: EventService,
    private _authService: AuthService,
    public shared: SharedDataService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    private router: Router,
    private _fieldsService : FieldsService
    )
  {
    this._sharedService.changeTitile("");
    this.myForm = this.formBuilder.group({
      date_from: new FormControl(),
      date_To: new FormControl(),
    });
  }

  fields: any[];
  fieldsEvent: any[];
  fieldsDistribute: any[];
  data;
  let_show = true;
  division_colors = {};
 /* colors = [
    {color: "red", background: '#ff000025'},
    {color: "blue", background: '#0000ff25'},
    {color: "green", background: '#00800025'},
    {color: "purple", background: '#80008025'},
    {color: "yellow", background: '#ffff0025'},
    {color: "cyan", background: '#00ffff25'},
    {color: "orange", background: '#ffa50025'},
    {color: "saddlebrown", background: '#8b451325'},
    {color: "olive", background: '#80800025'},
    {color: "blueviolet ", background: '#8a2be225'},
    {color: "darkred ", background: '#8b000025'},
    {color: "greenyellow ", background: '#adff2f25'},
  ];*/

  ngOnInit() {
    Swal.close();
    Swal.showLoading();

    this.route.params.subscribe(async (params) => {
      this.eventId = params.eventId;
      this.division_id = params.division_id;

      //Get Event
      const response_event : any = await this._eventService.getById({idEvent: this.eventId}).toPromise();
      if(response_event.valid) {
        this.event = response_event.data;

        //Get And Check Permissions
        this._authService.getRolesByEvent(this.eventId).subscribe((response: any) => {
          if (response.valid) {
            if(!response.data.some(role => role.role === "Scheduling")){
              this.router.navigate(['/404'])
            }
          } else {
            console.log(response);
            this.router.navigate(['/404']);
          }
        });

      }
      //Assign Colors
      this.countDivisions =  this.event.divisions.length;

      this.event.divisions.forEach((division, index) => {
        const backgroundColor = division.customize;
        const textColor = backgroundColor === '#ffffff' ? '#000000' : backgroundColor;

        this.division_colors[division.id] = {
          background: backgroundColor,
          color: textColor,
        };
      });
      //console.log("Division Colors",this.division_colors);


      this._fieldsService.getFielsdByEvent({ eventId: params.eventId }).subscribe((res: any) => {
        if (res.valid) {
          this.fieldsEvent = res.data;
        } else {
          console.log('False fields event');
        }
      });


      let response_matches: any = await this.bracketService.getMatchesByEvent(params.eventId);
      //let fields_response: any = await this.bracketService.getFields();
      this.data = {
        fields: this.fieldsEvent,
        matches: response_matches.data,
        event: this.event
      };

      this.fields = this.fieldsEvent;
      this.fields = this.fields.map(e=>{return {...e,matches:{}};});

      this.matches = response_matches.data.data;

      this.unscheduled_matches = this.matches.filter(item => item.date === null);
      this.scheduled_matches = this.matches.filter(item => item.date !== null);

      //console.log('ONIT',this.unscheduled_matches);
      // console.log('INFORMACION', this.scheduled_matches);
      // const minutesArray = this.scheduled_matches.map(item => new Date(item.date).getMinutes());
      // console.log('ARRAY MINUTOS',minutesArray);


      const startDate = new Date(this.event.date_from);
      const endDate = new Date(this.event.date_to);

      for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        this.dates.push(date.getFullYear() + '-' + date.toISOString().slice(5, 7) + '-' + date.toISOString().slice(8, 10));
      }

      this.from = new Date(this.event.date_from);
      this.to = new Date(this.event.date_to);
      this.from.setDate(this.from.getDate() + 1);
      this.to.setDate(this.to.getDate() + 1);

      this.myForm.get('date_from').setValue(this.from);
      this.myForm.get('date_To').setValue(this.to);


      this.assignMatchesToCalendar();
      //console.log("Fields: ",this.fields);
      //console.log("Dates: ",this.dates);
      Swal.close();
    })
  }

  assignMatchesToCalendar(){
    this.scheduled_matches.map(s_match => {
      const s_match_field_id = s_match.field.id;
      const date_f = this.getFormatedDate(new Date(s_match.date));
      const hour = new Date(s_match.date).getHours();
      const minutes = new Date(s_match.date).getMinutes();
      let field_element = this.fields.find(field => field.id === s_match_field_id);
      if(!field_element.matches[date_f]) field_element.matches[date_f] = {};
      if(!field_element.matches[date_f][hour]) field_element.matches[date_f][hour] = {};
      if(!field_element.matches[date_f][hour][minutes]) field_element.matches[date_f][hour][minutes] = [];
      field_element.matches[date_f][hour][minutes].push({
        id: s_match.id,
        conflict: s_match.conflict,
        date_event: this.getFormatedDate(new Date(s_match.date)),
        name: this.shared.getLocalNameFromMatch(s_match)  + ' vs ' + this.shared.getVisitorNameFromMatch(s_match),
        hour: new Date(s_match.date).getHours(),
        minutes: new Date(s_match.date).getMinutes(),
        fieldId: s_match_field_id,
        game_length: s_match.Pools.bracket.game_length,
        rest_time: s_match.Pools.bracket.rest_time,
        division_name: s_match.Pools.bracket.eventDetails.division_name,
        bracket_name: s_match.Pools.bracket.name,
        division_id: s_match.Pools.bracket.eventDetails.id,
        pool_name: s_match.Pools.pool_name,
        teamLocalId:  s_match.teamLocal ? s_match.teamLocal.id : null,
        teamVisitorId: s_match.teamVisitor ? s_match.teamVisitor.id : null
      });
    });


  }

  stringify(data: Object) {
    return JSON.stringify(data);
  }

  unscheduled(id){
    let sched = this.scheduled_matches.filter(e=>e.id===id)[0];
    let unsched = this.unscheduled_matches.filter(e=>e.id===id)[0];
    if(sched){
      this.matchesList.data.push(sched);
    }else{
      this.matchesList.data.push(unsched);
    }
    this.removeScheduledMatch(this.fields,id)
    try {
      this.bracketService.updateMatches(
        { id: id, field: null, date: null }
      );
      setTimeout(async ()=> await this.check_conflict(),1000);
    } catch (e) {
      console.log(e);
      return false
    }
  }

  removeScheduledMatch(matches, targetId) {
    for (const match in matches) {
      for (const dates in matches[match]['matches']) {
        for (const keys in matches[match]['matches'][dates]) {
          for (const keys2 in matches[match]['matches'][dates][keys]) {
            const matchesArray = matches[match]['matches'][dates][keys][keys2];
            for (let i = 0; i < matchesArray.length; i++) {
              const item = matchesArray[i];
              if (item.id === targetId) {
                matchesArray.splice(i, 1);
                return item;
              }
            }
          }
        }
      }
    }
    return null;
  }


  dragMoved(event){
    setTimeout(()=>{
      if(!document.querySelector<HTMLElement>('.cdk-drag-placeholder'))return false;
      document.querySelector<HTMLElement>('.cdk-drag-placeholder').style.display = 'none';
      setTimeout(()=>{
        document.querySelector<HTMLElement>('.cdk-drag-placeholder').style.display = 'block';
      },100)
    },100)
  };


  async drop(event: CdkDragDrop<MatchCalendar[]>) {

    const field_and_time = event.container.data;

    const fieldIndex = this.fields.findIndex((field) => field.id === field_and_time['field'].id);

    let new_event;
    let api_data: any;
    let match_calendar;
    const hour = field_and_time['time'];
    const minutes = field_and_time['minutes'];
    const date_event = this.dates[this.tabGroup.selectedIndex];

    if (!event.item.element.nativeElement.id.startsWith('field') && !isNaN(Number(event.item.element.nativeElement.id))) {
      const event_data = JSON.parse(event.previousContainer.element.nativeElement.querySelector('.teams-content').getAttribute('event-teams'));
      new_event = this.unscheduled_matches.filter(e => e.id == event.item.element.nativeElement.id)[0];
      match_calendar = {
        id: new_event.id,
        date_event: date_event,
        name: this.shared.getLocalNameFromMatch(new_event)  + ' vs ' + this.shared.getVisitorNameFromMatch(new_event),
        hour: hour,
        minutes: minutes,
        fieldId: field_and_time['field'].id,
        division_name: new_event.Pools.bracket.eventDetails.division_name,
        division_id: new_event.Pools.bracket.eventDetails.id,
        bracket_name: new_event.Pools.bracket.name,
        pool_name: new_event.Pools.pool_name,
        teamLocalId: new_event && new_event.teamLocal && new_event.teamLocal.id ? new_event.teamLocal.id : null,
        teamVisitorId: new_event && new_event.teamVisitor && new_event.teamVisitor.id ? new_event.teamVisitor.id:null,
        game_length: new_event.Pools.bracket.game_length,
        rest_time: new_event.Pools.bracket.rest_time,

        /*division_name: event_data.Pools.bracket.eventDetails.division_name,
        division_id: event_data.Pools.bracket.eventDetails.id,
        bracket_name: event_data.Pools.bracket.name,
        pool_name: event_data.Pools.pool_name,
        teamLocalId: event_data.teamLocal.id? event_data.teamLocal.id:null,
        teamVisitorId: event_data.teamVisitor.id? event_data.teamVisitor.id:null,
        game_length: event_data.Pools.bracket.game_length,
        rest_time: event_data.Pools.bracket.rest_time,*/
      };

      if(!this.fields[fieldIndex].matches[date_event]) this.fields[fieldIndex].matches[date_event] = {};
      if(!this.fields[fieldIndex].matches[date_event][hour]) this.fields[fieldIndex].matches[date_event][hour] = {};
      if(!this.fields[fieldIndex].matches[date_event][hour][minutes]) this.fields[fieldIndex].matches[date_event][hour][minutes] = [];
      this.fields[fieldIndex].matches[date_event][hour][minutes].push(match_calendar);
      api_data = {
        id: new_event.id,
        field: field_and_time['field'].id,
        date: new Date(match_calendar.date_event + ' ' + hour.toString().padStart(2, '0') + ':'+minutes+':00'),
      }
    } else {
      match_calendar = JSON.parse(event.item.element.nativeElement.getAttribute('event-data'));
      let prevField = this.fields.filter(e => e.id === event.previousContainer.data['field'].id)[0];
      prevField.matches[match_calendar.date_event][match_calendar.hour][match_calendar.minutes] = prevField.matches[match_calendar.date_event][match_calendar.hour][match_calendar.minutes].filter(r => r.id !== match_calendar.id);
      match_calendar.fieldId = field_and_time['field'].id;
      match_calendar.hour = hour;
      match_calendar.minutes = minutes;
      if(!this.fields[fieldIndex].matches[date_event]) this.fields[fieldIndex].matches[date_event] = {};
      if(!this.fields[fieldIndex].matches[date_event][hour]) this.fields[fieldIndex].matches[date_event][hour] = {};
      if(!this.fields[fieldIndex].matches[date_event][hour][minutes]) this.fields[fieldIndex].matches[date_event][hour][minutes] = [];
      this.fields[fieldIndex].matches[date_event][hour][minutes].push(match_calendar);
      api_data = {
        id: match_calendar.id,
        field: field_and_time['field'].id,
        date: new Date(date_event + ' ' + hour.toString().padStart(2, '0') + ':'+minutes+':00'),
      }
    }
    //console.log('INFORMACCION',api_data);
    await this.check_conflict();

    if (event.previousContainer !== event.container && !isNaN(Number(event.item.element.nativeElement.id))) {
      event.item.element.nativeElement.className = "hide";
    }
    //this.temp_cache_teams(eve.id);
    //localStorage.setItem('fields', JSON.stringify(this.fields));
    try {
      api_data.conflict =  match_calendar.conflict || false;
      this.bracketService.updateMatches(api_data);

    } catch (e) {
      console.log(e);
      return false
    }
  }

  async check_conflict() {
    const matches = [];
    await this.shared.asyncForEach(this.fields,e => {
      const dates_keys = Object.keys(e.matches);
      dates_keys.forEach(d => {
        const hours = Object.keys(e.matches[d]);
        hours.forEach(h => {
          const minutes = Object.keys(e.matches[d][h]);
          minutes.forEach(m => {
            e.matches[d][h][m].forEach(match => {
              match.start= (parseInt(h) < 10 ? '0'+h : h)+':'+(parseInt(m) < 10 ? '0'+m : m)
              match.end = this.addDurationGame( (parseInt(h) < 10 ? '0'+h : h)+':'+(parseInt(m) < 10 ? '0'+m : m) ,match.game_length);
              matches.push(match);
            })
          })
        })
      })
    })

    await this.shared.asyncForEach(matches,(match, index) => {
      for (let i = 0; i < matches.length; i++) {
        if (i !== index) {
          if (
            (match.teamLocalId === matches[i].teamLocalId ||
              match.teamLocalId === matches[i].teamVisitorId ||
              match.teamVisitorId === matches[i].teamLocalId ||
              match.teamVisitorId === matches[i].teamVisitorId) &&
            match.fieldId !== matches[i].fieldId &&
            match.date_event === matches[i].date_event
          ) {

            const startA = new Date(`2023-01-01 ${match.start}`);
            const endA = new Date(`2023-01-01 ${match.end}`);
            const startB = new Date(`2023-01-01 ${matches[i].start}`);
            const endB = new Date(`2023-01-01 ${matches[i].end}`);
            //console.log(match.name+":::"+matches[i].name+">>>>"+startA.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) +"-------"+ endB.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) )
            //console.log(match.name+":::"+matches[i].name+">>>>"+endA.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) +"........."+ startB.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) )
            if (startA <= endB && endA >= startB) {
              match.conflict = true;
              this.bracketService.updateMatches({id:match.id,conflict:true});
              break;
            }else{
              match.conflict = false;
              this.bracketService.updateMatches({id:match.id,conflict:false});
            }
          }
        }else{
          matches[i].conflict = false;
          this.bracketService.updateMatches({id:matches[i].id,conflict:false});
        }
      }
    });
  }

  addDurationGame(timeString, minutesToAdd) {
    const baseDate = new Date("2000-01-01T" + timeString);
    baseDate.setMinutes(baseDate.getMinutes() + minutesToAdd);
    const hours = baseDate.getHours();
    const minutes = baseDate.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return formattedTime;
  }

  selectDataCalendar(event: any,date:any,field:any,idCol:any){

    let estateCheck = event.checked;

    if (estateCheck) {
      const matchFilter = this.scheduled_matches.filter((item) => {
        const dateOk = item.date.includes(date);
        const idOk = item.field.id == field;
        return dateOk && idOk;
      });

      this.selectedData.push(...matchFilter);
    } else {
      this.selectedData = this.selectedData.filter((item) => {
        const dateOk = item.date.includes(date);
        const idOk = item.field.id == field;
        return !(dateOk && idOk);
      });
    }

    this.aplyStyle(date ,idCol,estateCheck);
  }

  aplyStyle(date:any,idCol:any,estateCheck:boolean)
  {
    for (let hour = 0; hour < 24; hour++) {
      const currentId = 'todo-cell-' + hour + '_' + idCol + '_' + date;
      const selectedDiv = document.getElementById(currentId);
      if (selectedDiv) {
         if (estateCheck){
          selectedDiv.style.backgroundColor = 'var(--ws-blue';
          selectedDiv.style.border = '1px solid blue';
          selectedDiv.style.height = '70px';
          selectedDiv.style.color = 'rgba(0, 0, 0, 0.87)';

        }else{
          selectedDiv.style.backgroundColor = 'rgba(255, 0, 0, 0.145)';
          selectedDiv.style.border = '1px solid red';
          selectedDiv.style.height = '70px';
          selectedDiv.style.color = 'rgba(0, 0, 0, 0.87)';
        }
      }
    }

  }
  openEditCalendar(){
   // console.log('Select',this.selectedData)
      const dialogRef = this.dialog.open(EditCalendarComponent, {
      width: "30%",
      data: {matches: this.selectedData,
            dates: this.dates,
            fields: this.fields,
            from: this.from,
            to:this.to},
      });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigate(['/superadmin/event/'+this.event.id+'/calendar'])
        .then(() => {
          window.location.reload();
        });
      }
      else{
        this.selectedData = [];
        for (let key in this.checkboxEstados) {
          if (this.checkboxEstados.hasOwnProperty(key)) {
              this.checkboxEstados[key] = false;
              const idInfo = key.split('_');
              const date = idInfo[0];
              const colum = idInfo[2];
              this.aplyStyle(date,colum,false);
          }
        }
      }
    });
  }

  shouldEnableCheckbox(matches: any,fiel: any,date:any): boolean {
    if (fiel.matches.hasOwnProperty(date)) {
      return matches && Object.keys(matches).length > 0 && fiel.matches  ;
    } else {
      return false
    }

  }


  getDateFieldKey(date: string, field: any,colum : number): string {
    return `${date}_${field.name}_${colum}`;
  }

  shouldEnableButton(): boolean {
    //check if the button should be disabled
    return Object.values(this.checkboxEstados).some(value => value);
  }

  onTabChange(event:any) {
    const date = this.dates[event.index];
    const estate :boolean =this.shouldEnableButton();
    if(!estate){
      for (let key in this.checkboxEstados) {
        if (this.checkboxEstados.hasOwnProperty(key)) {
            this.checkboxEstados[key] = false;
            const idInfo = key.split('_');
            const date = idInfo[0];
            const colum = idInfo[2];
            this.aplyStyle(date,colum,false);
        }
      }
    }
  }

  open_auto() {
    const dialogRef = this.dialog.open(SchedulingComponent, {
      width: "30%",
      data: {data: this.data},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fieldsDistribute = result.fields;
        let fieldList: any[] = [];
        for (let i = 0; i < this.fields.length; i++) {

          for (let iy = 0; iy < this.fieldsDistribute.length; iy++) {
            if (this.fields[i].id == this.fieldsDistribute[iy].id) {
              fieldList.push(this.fields[i])
            }
          }
        }
        result.dates.forEach(e => {
          if (e.time && e.games) {
          this.distributeGames(e.date + 'T00:00:00', e.time, e.games,fieldList);
          }
        });
      }
    });
  }

  getDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  distributeGames(startDate, startTime, numGames,fieldList: any[]): Array<any> {
    let games = this.unscheduled_matches.map(t => this.shared.getLocalNameFromMatch(t)  + ' vs ' + this.shared.getVisitorNameFromMatch(t));
    let currentDate = new Date(startDate);
    const startTimeSplit = startTime.split(':');
    const startHour = startTimeSplit[0];
    const startMinute = startTimeSplit[1];
    let currentHour = parseInt(startHour);
    let currentMinute =  parseInt(startMinute);
    let teams_count = {};
    if (games.length == 0) {
      return [];
    }

    let field_index = 0;
    let fields_new_dates = {};

    for (let match of this.unscheduled_matches) {

      !teams_count[match.teamLocal.id] ?  teams_count[match.teamLocal.id] = 1 : teams_count[match.teamLocal.id]++;
      !teams_count[match.teamVisitor.id] ?  teams_count[match.teamVisitor.id] = 1 : teams_count[match.teamVisitor.id]++;
      if(teams_count[match.teamLocal.id] > numGames || teams_count[match.teamVisitor.id] > numGames){
        continue;
      }


      let field =  null;
      let nextHour = null;
      let nextMinutes = null;
      let breakAll = false;

      while (field === null){

        if(field_index > fieldList.length - 1)
          field_index = 0;
        let fields_filled = 0;
        for(let fi = field_index; fi < fieldList.length ; fi++){
          const field_id = fieldList[field_index].id;
          if(!fields_new_dates[field_id]) fields_new_dates[field_id] = {nextHour: currentHour, nextMinutes: currentMinute};
          nextHour = fields_new_dates[field_id].nextHour ? fields_new_dates[field_id].nextHour : currentHour;
          if(nextHour >= 24){
            fields_filled ++;
            field_index++;
            continue;
          }
          nextMinutes = fields_new_dates[field_id].nextMinutes ? fields_new_dates[field_id].nextMinutes : currentMinute;
          if(!fieldList[fi].matches[this.getFormatedDate(currentDate)]
            || !fieldList[fi].matches[this.getFormatedDate(currentDate)][nextHour]
            || !fieldList[fi].matches[this.getFormatedDate(currentDate)][nextHour][nextMinutes]
            ){
            field = fieldList[fi];
            field_index++;
            break;
          } else {
            const last_match_glength = fieldList[fi].matches[this.getFormatedDate(currentDate)][nextHour][nextMinutes][0].game_length; // verifyx
            const hours_to_add = Math.trunc((last_match_glength + 5) / 60);
            const minutes_to_add = (last_match_glength + 5) % 60;
            if(!fields_new_dates[field_id]) fields_new_dates[field_id] = {nextHour: nextHour, nextMinutes: nextMinutes};
            fields_new_dates[field_id].nextHour += hours_to_add;
            fields_new_dates[field_id].nextMinutes += minutes_to_add;

            if(fields_new_dates[field_id].nextMinutes >= 60){
              fields_new_dates[field_id].nextHour++;
              fields_new_dates[field_id].nextMinutes -= 60;
            }
          }
          field_index++;
        }
        if(fields_filled === fieldList.length)
          breakAll = true;
          break;
      }

      if(breakAll)
        break;


      if (field) {
        this.unscheduled_matches.splice(this.unscheduled_matches.indexOf(match), 1);
        if(!field.matches[this.getFormatedDate(currentDate)]) field.matches[this.getFormatedDate(currentDate)] = {};
        if(!field.matches[this.getFormatedDate(currentDate)][nextHour]) field.matches[this.getFormatedDate(currentDate)][nextHour] = {};
        if(!field.matches[this.getFormatedDate(currentDate)][nextHour][nextMinutes]) field.matches[this.getFormatedDate(currentDate)][nextHour][nextMinutes] = [];
          field.matches[this.getFormatedDate(currentDate)][nextHour][nextMinutes].push({
            id: match.id,
            date_event: this.getFormatedDate(currentDate),
            name: this.shared.getLocalNameFromMatch(match)  + ' vs ' + this.shared.getVisitorNameFromMatch(match),
            hour: nextHour,
            minutes: nextMinutes,
            game_length: match.Pools.bracket.game_length,
            rest_time: match.Pools.bracket.rest_time,
            division_name: match.Pools.bracket.eventDetails.division_name,
            division_id: match.Pools.bracket.eventDetails.id,
            bracket_name: match.Pools.bracket.name,
            pool_name: match.Pools.pool_name,
            teamLocalId: match.teamLocal.id,
            teamVisitorId: match.teamVisitor.id,
          });

          let api_data = {
            id: match.id,
            field: field.id,
            date: new Date(this.getFormatedDate(currentDate) + ' ' + nextHour.toString().padStart(2, '0') + ':'+ nextMinutes.toString().padStart(2, '0') +':00'),
          }
          try {
            this.bracketService.updateMatches(api_data);
          } catch (e) {
            console.log(e);
            throw e
          }

          const hours_to_add = Math.trunc((match.Pools.bracket.game_length + 5) / 60);
          const minutes_to_add = (match.Pools.bracket.game_length + 5) % 60;
          if(!fields_new_dates[field.id]) fields_new_dates[field.id] = {nextHour: nextHour, nextMinutes: nextMinutes};
          fields_new_dates[field.id].nextHour += hours_to_add;
          fields_new_dates[field.id].nextMinutes += minutes_to_add;
          if(fields_new_dates[field.id].nextMinutes >= 60){
            fields_new_dates[field.id].nextHour++;
            fields_new_dates[field.id].nextMinutes -= 60;
          }
      }
    }
  }

  getFormatedDate(date: Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so we need to add 1
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  dateFilter(){

    this.errorMessage = null;
    const fromDate = this.resetHour( new Date(this.myForm.get('date_from').value));
    const toDate = this.resetHour(new Date(this.myForm.get('date_To').value));
    this.from = this.resetHour(this.from);

    if ((fromDate >= this.from && toDate >= this.from) && (fromDate <= this.to && toDate <= this.to)) {
      if (fromDate <= toDate) {
        this.dates.splice(0);
        for (let date = fromDate; date <= toDate; date.setDate(date.getDate() + 1)) {
          this.dates.push(date.getFullYear() + '-' + date.toISOString().slice(5, 7) + '-' + date.toISOString().slice(8, 10));
        }
      } else {
        this.errorMessage = 'Invalid date range.';
      }
    } else {
      this.errorMessage = 'Invalid date range.';
    }
  }

  resetFilter(){

    this.errorMessage = null;
    const fromReset = new Date(this.event.date_from);
    const toReset = new Date(this.event.date_to);

    let days: number = 0;
    this.dates.splice(0);
    for (let date = fromReset; date <= toReset; date.setDate(date.getDate() + 1)) {
      this.dates.push(date.getFullYear() + '-' + date.toISOString().slice(5, 7) + '-' + date.toISOString().slice(8, 10));
      days = days + 1;
    }
    fromReset.setDate(fromReset.getDate() - (days-1));
    toReset.setDate(toReset.getDate() + 1);
    this.myForm.get('date_from').setValue(new Date(fromReset));
    this.myForm.get('date_To').setValue(new Date(toReset));
 }

  resetHour(date:any){

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;

  }
  addMatch(){
    const dialogRef = this.dialog.open(AddMatchComponent, {
      width: "30%",
      data: {eventId: this.eventId},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.location.reload();
      }
    });
  }

}

