import { Component, Input, OnInit } from '@angular/core';
import { Event } from "../../../../models/event/event";
import { EventDetails } from "../../../../models/event-details/event-details";
import { ActivatedRoute, Router } from "@angular/router";
import { EventService } from "../../../../services/event/event.service";
import { environment } from "src/environments/environment";
import {AuthService} from "../../../../services/auth/auth.service";

@Component({
  selector: 'app-event-header',
  templateUrl: './event-header.component.html',
  styleUrls: ['./event-header.component.css']
})
export class EventHeaderComponent implements OnInit {

  @Input() event: Event;
  @Input() division: EventDetails;
  eventLogo: string;
  url: string;
  eve: any;
  eventDa: any;
  isCalendarActive: boolean = false;
  isPoolsBracketsActive: boolean = true;
  imagesEvent: any = [
    {
      eventLogo: "",
      eventSponsor: [],
    },
  ];

  divisions: any = [];
  nameDivision: string = "";
  role_scheduling: false;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _eventService: EventService,
    private _authService: AuthService
    ) {
    this.url = this.router.url;
  }

  ngOnInit() {



    this.route.params.subscribe((params) => {
      const eventId = params.eventId;
      const divisionId = params.division_id;

      //Get Event
      this._eventService.getById({ idEvent: eventId }).subscribe((response: any) => {
        if (response.valid) {

          //Get And Check Permissions
          this._authService.getRolesByEvent(eventId).subscribe((response: any) => {
            if (response.valid) {
              this.role_scheduling = response.data.some(role => role.role === "Scheduling");
            } else {
              console.log(response);
            }
          });

          this.eventDa = response.data;
          if (divisionId) {
            this.divisions = response.data.divisions;
            const division = this.divisions.find(registro => registro.id == divisionId);
            this.nameDivision = " - " + division['division_name'];
          }
          else {
            this.nameDivision = " ";
          }
          if (response.data.images) {
            this.imagesEvent = JSON.parse(response.data.images);
            this.eventLogo = environment.API_URL +"static/" +this.imagesEvent[0].eventLogo

          }
        }
        else {
          console.log(response.valid);
        }
      });
    });

  }


  selectPoolsBrackets() {
    this.isPoolsBracketsActive = true;
    this.isCalendarActive = false;
  }

  selectCalendar() {
    this.isPoolsBracketsActive = false;
    this.isCalendarActive = true;
  }
}
