import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  exhaustMap,
  mergeMap,
} from "rxjs/operators";
import { FieldsService, AuthService } from "src/app/services/service.index";
import { FieldsParams } from "src/app/models/fields/fields-params";
import { FieldsResponse } from "src/app/models/fields/fields-response";
import { Router } from "@angular/router";
import * as fields from "../actions/fields.actions";

// Third libreries
import Swal from "sweetalert2";

// Services
import {
  NotificationService,
  AlertService,
} from "src/app/services/service.index";

@Injectable()
export class FieldsEffects {
  constructor(
    private _notificationService: NotificationService,
    private _fieldsService: FieldsService,
    private actions$: Actions,
    public router: Router
  ) {
    //
  }

  public createFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fields.FieldsLoadAction>(fields.FieldsActionType.Create),
      map((action) => action.payload),
      switchMap((params: FieldsParams) =>
        this._fieldsService.create(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Create`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Create`);
              }
            } else {
              setTimeout(() => {
                Swal.fire({
                  title: "Success",
                  text: "Create Field",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }, 800);
            }
            return new fields.FieldsLoadSuccessAction(response);
          }),
          catchError((error) => of(new fields.FieldsLoadFailAction(error)))
        )
      )
    )
  );

  public updateFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fields.FieldsLoadAction>(fields.FieldsActionType.Update),
      map((action) => action.payload),
      switchMap((params: FieldsParams) =>
        this._fieldsService.update(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Update`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Update`);
              }
            } else {
              setTimeout(() => {
                Swal.fire({
                  title: "Success",
                  text: "Update Field",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }, 800);
            }
            return new fields.FieldsLoadSuccessAction(response);
          }),
          catchError((error) => of(new fields.FieldsLoadFailAction(error)))
        )
      )
    )
  );

  public getAllFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fields.FieldsLoadAction>(fields.FieldsActionType.Get),
      map((action) => action.payload),
      switchMap((params: FieldsParams) =>
        this._fieldsService.getAllFields().pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Fields`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Fields`);
              }
            }
            return new fields.FieldsLoadSuccessAction(response);
          }),
          catchError((error) => of(new fields.FieldsLoadFailAction(error)))
        )
      )
    )
  );

  public enabledFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fields.FieldsLoadAction>(fields.FieldsActionType.Enabled),
      map((action) => action.payload),
      switchMap((params: FieldsParams) =>
        this._fieldsService.enabled(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Enabled`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Enabled`);
              }
            } else {
              setTimeout(() => {
                Swal.fire({
                  title: "Success",
                  text: "Update Field",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }, 800);
            }
            return new fields.FieldsLoadSuccessAction(response);
          }),
          catchError((error) => of(new fields.FieldsLoadFailAction(error)))
        )
      )
    )
  );

  public deleteFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fields.FieldsLoadAction>(fields.FieldsActionType.Delete),
      map((action) => action.payload),
      switchMap((params: FieldsParams) =>
        this._fieldsService.delete(params.data).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = "";
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Delete`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Delete`);
              }
            } else {
              setTimeout(() => {
                Swal.fire({
                  title: "Success",
                  text: "Update Field",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }, 800);
            }
            return new fields.FieldsLoadSuccessAction(response);
          }),
          catchError((error) => of(new fields.FieldsLoadFailAction(error)))
        )
      )
    )
  );
}
