import * as roles from "../../store/states/roles.state";
import { RolesAction, RolesActionType } from "../../store/actions/roles.actions";

export function rolesReducer(
  state = roles.initialStateRoles,
  action: RolesAction | any
): roles.StateRoles {
  switch (action.type) {
    case RolesActionType.Loading: {
      return { ...state, loading: true };
    }
    case RolesActionType.LoadSuccess: {
      return  { ...state,
        roles: action.payload,
        error: false,
        loading: false,
      };
    }
    case RolesActionType.LoadFailure: {
      return  { ...state,
        roles: null,
        error: true,
        loading: false,
      };
    }
    default:
      return state;
  }
}
