import { Component, OnInit } from "@angular/core";
import {EventService} from "../../services/service.index";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../environments/environment";

declare var google: any;

@Component({
  selector: "app-site",
  templateUrl: "./event-landing.component.html",
  styleUrls: ["./event-landing.component.css"],
})
export class EventLandingComponent implements OnInit {

  event: any;
  logos: any;
  eventLogo: any;
  map: any;
  geocoder: any;
  public environment = environment;

  constructor(
    private _eventService: EventService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(async (params) => {
      let eventId = params.eventId;
      this._eventService.publicGetById(eventId).subscribe((result : any) => {
        if(result.valid){
          this.event = result.data;
          this.logos = JSON.parse(this.event.images)[0];
          this.eventLogo = this.logos.eventLogo ? 'images/' + this.logos.eventLogo : null;
          this.loadMap()
        }
      });
    })
  }

  loadMap() {
    this.geocoder = new google.maps.Geocoder();

    const address = this.event.address;

    this.geocoder.geocode({ 'address': address }, (results, status) => {
      if (status === 'OK') {
        const mapOptions = {
          center: results[0].geometry.location,
          zoom: 15
        };

        this.map = new google.maps.Map(document.getElementById('map'), mapOptions);

        const marker = new google.maps.Marker({
          position: results[0].geometry.location,
          map: this.map
        });
      } else {
        console.error('Geocoding failed ' + status);
      }
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
  }
}
