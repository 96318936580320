import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

//Services
import { AuthService } from "src/app/services/auth/auth.service";
@Injectable({
  providedIn: "root",
})
export class LoginAdminGuard implements CanActivate {
  constructor(private router: Router, private _authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let login = this._authService.isLogin();
    let user = this._authService.getSessionStorage();

    if (login && ['Admin','Staff','Superadmin'].indexOf(user.role) !== -1 ) {
      // Role partner
      return true;
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
