import { Action } from "@ngrx/store";
import { StaffParams } from "../../models/staff/staff-params";
import { StaffResponse } from "../../models/staff/staff-response";

export enum StaffActionType {
  Save = "[Staff] Save",
  Get = "[Staff] Get",
  Loading = "[Staff] Loading",
  LoadSuccess = "[Staff] LoadSuccess",
  LoadFailure = "[Staff] LoadFailure",
}

export class StaffLoadAction implements Action {
  public readonly type = StaffActionType.Loading;
  constructor(public payload: StaffParams) {}
}

export class StaffSaveAction implements Action {
  public readonly type = StaffActionType.Save;
  constructor(public payload: StaffParams) {}
}

export class StaffGetAction implements Action {
  public readonly type = StaffActionType.Get;
  constructor(public payload: StaffParams) {}
}

export class StaffLoadSuccessAction implements Action {
  public readonly type = StaffActionType.LoadSuccess;
  constructor(public payload: StaffResponse) {}
}

export class StaffLoadFailAction implements Action {
  public readonly type = StaffActionType.LoadFailure;
  constructor(public error: any) {}
}

export type StaffAction =
  | StaffLoadAction
  | StaffLoadSuccessAction
  | StaffLoadFailAction;
