import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  exhaustMap,
  mergeMap,
} from "rxjs/operators";
import { YearsService, AuthService } from "src/app/services/service.index";
import { YearsParams } from "src/app/models/years/years-params";
import { YearsResponse } from "src/app/models/years/years-response";
import { Router } from "@angular/router";
import * as years from "../actions/years.actions";

// Third libreries
import Swal from "sweetalert2";

// Services
import {
  NotificationService,
  AlertService,
} from "src/app/services/service.index";

@Injectable()
export class YearsEffects {
  constructor(
    private _notificationService: NotificationService,
    private _yearsService: YearsService,
    private actions$: Actions,
    public router: Router
  ) {
    //
  }

  public getAllYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType<years.YearsLoadAction>(years.YearsActionType.Get),
      map((action) => action.payload),
      switchMap((params: YearsParams) =>
        this._yearsService.getAllYears().pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = '';
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Years`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Years`);
              }
            }
            return new years.YearsLoadSuccessAction(response);
          }),
          catchError((error) => of(new years.YearsLoadFailAction(error)))
        )
      )
    )
  );
}
