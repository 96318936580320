import { Injectable } from "@angular/core";
// import { CONFIG } from "../../config/config";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    public http: HttpClient,
    public router: Router,
    private _authService: AuthService
  ) {}

  create(data: any) {
    return this.http.post(`${environment.API_URL}users/create`, data);
  }

  update(data: any) {
    return this.http.put(
      `${environment.API_URL}users/edit/${data.idUser}`,
      data
    );
  }

  getAll() {
    return this.http.get(`${environment.API_URL}users/all`);
  }
  

  enabled(data: any) {
    return this.http.put(`${environment.API_URL}users/${data.idUser}`, null);
  }

  delete(data: any) {
    return this.http.delete(`${environment.API_URL}users/${data.idUser}`);
  }
  sendNotification(data:any){
    return this.http.post(`${environment.API_URL}users/mobile/send-notification`,data);
  }

  getAllUserPlayers(data: any) {    
    return this.http.get(`${environment.API_URL}users/players/all/${data}`);
  }
}
