import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  exhaustMap,
  mergeMap,
} from "rxjs/operators";
import { StaffService, AuthService } from "src/app/services/service.index";
import { StaffParams } from "src/app/models/staff/staff-params";
import { StaffResponse } from "src/app/models/staff/staff-response";
import { Router } from "@angular/router";
import * as staff from "../actions/staff.actions";

// Third libreries
import Swal from "sweetalert2";

// Services
import {
  NotificationService,
  AlertService,
} from "src/app/services/service.index";

@Injectable()
export class StaffEffects {
  constructor(
    private _notificationService: NotificationService,
    private _staffService: StaffService,
    private actions$: Actions,
    public router: Router
  ) {
    //
  }

  public createStaff$ = createEffect(() =>
    this.actions$.pipe(
      ofType<staff.StaffLoadAction>(staff.StaffActionType.Save),
      map((action) => action.payload),
      switchMap((params: StaffParams) =>
        this._staffService.postThirdStep(params).pipe(
          map((response: any) => {
            if (!response.valid) {
              let errorMsg = '';
              if (response.errors) {
                const reversedKeys = Object.keys(response.errors).reverse();
                reversedKeys.forEach((key) => {
                  errorMsg = `${
                    response.errors[key]["param"].charAt(0).toUpperCase() +
                    response.errors[key]["param"].slice(1)
                  }: ${response.errors[key]["msg"]}`;
                  this._notificationService.showError(`${errorMsg}`, `Staff`);
                });
              } else {
                errorMsg = response.message;
                this._notificationService.showError(`${errorMsg}`, `Staff`);
              }
            }
            return new staff.StaffLoadSuccessAction(response);
          }),
          catchError((error) => of(new staff.StaffLoadFailAction(error)))
        )
      )
    )
  );
}
