import { Component, Inject, OnInit } from "@angular/core";
import { take, startWith, map } from "rxjs/operators";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { GlobalState } from "src/app/store/states/global.state";
import { ActivatedRoute } from '@angular/router';
import { PlayerService} from "src/app/services/service.index";


import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule
} from "@angular/forms";


@Component({
  selector: 'app-add-stats',
  templateUrl: './add-stats.component.html',
  styleUrls: ['./add-stats.component.css']
})
export class AddStatsComponent implements OnInit {
  PlayersTeamsFormGroup: FormGroup;
  idTeams: any[] = [];
  teams: any[] = [];
  playersTeamLocal  : any[] = null;
  playersTeamVisitor : any[] = null;
  nameinput: string;
  valorinput: number;
  dataPlayer: any[] = [];
  btnUpdateVisitor: any[] = [];
  btnUpdateLocal: any[] = [];
  rowLocal: any[][] = [];
  rowVisitor: any[][] = [];
  nameTeamLocal: string = '';
  nameTeamVisitor: string = '';

  playersLocal: any[];

  constructor(
    private _formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public _PlayerService: PlayerService,
    @Inject(MAT_DIALOG_DATA) public data: any,   

  ) {    

  }

  ngOnInit() {    
    this._PlayerService.getPlayesTeam(this.data.idTeamLocal, this.data.idTeamVisitor,this.data.idMatch,this.data.idEvent,this.data.division_id).subscribe((response: any) => {
      if (response.valid) {          
        this.teams = response.data;
        this.nameTeamLocal =this.teams[0].teamLocal;
        this.nameTeamVisitor =this.teams[0].teamVisitor;
        if(this.teams.length> 0 && this.teams[0].statPlayer.length>0){

          this.playersTeamLocal = this.teams[0].statPlayer.filter(team => team.idteam == this.data.idTeamLocal);        
          this.playersTeamVisitor = this.teams[0].statPlayer.filter(team => team.idteam == this.data.idTeamVisitor); 
  
          this.btnUpdateLocal = new Array(this.playersTeamLocal.length).fill(false);        
          this.btnUpdateVisitor = new Array(this.playersTeamVisitor.length).fill(false);     
  
          this.rowLocal =  new Array(this.playersTeamLocal.length).fill(false).map(() => new Array(9).fill(false)); 
          this.rowVisitor =  new Array(this.playersTeamVisitor.length).fill(false).map(() => new Array(9).fill(false));           
        }   
        else{

          console.log('Data null');
        }
      }
      else{
        console.log('False');     
      }
    });
  }

  validateButton(idplayer, valor: number, indice: number, field: string, typeTem : number, col : number) {    

    let teamId: number;
    if (typeTem == 0) {      
      this.btnUpdateLocal[indice] = true;
      this.rowLocal[indice][col] = true;
      teamId=this.data.idTeamLocal;
    } else {
      this.btnUpdateVisitor[indice] = true;
      this.rowVisitor[indice][col] = true;
      teamId=this.data.idTeamVisitor;
    }

    this.dataPlayer[indice] = {
      ...this.dataPlayer[indice],
      ['idEvent']: this.data.idEvent,
      ['playerId']: idplayer,
      ['teamId']: teamId,
      ['matchId']: this.data.idMatch,
      ['division_id']: this.data.division_id,
      [field]: valor,      
    };
    
  }

  saveData(indice: number,typeTem : number)
  {    
    const valorPases = this.dataPlayer[indice];    
    let save_data = this.dataPlayer[indice];       
    this._PlayerService.postPlayerMatchTeam({ data: save_data }).subscribe((res: any) => {
      if (res.valid) {        
        if (typeTem == 0) {      
          this.btnUpdateLocal[indice] = false;         
        } else {
          this.btnUpdateVisitor[indice] = false;         
        }
        this.dataPlayer = [];

      } else {
        console.log('false',res);        
      }
    });
  }
}
