import { Routes, RouterModule } from "@angular/router";

import { UsersComponent } from './users/users.component';
import { CustomersComponent } from './customers/customers.component';


const adminRoutes: Routes =[
    { path: 'users', component: UsersComponent },
    { path: 'customers', component: CustomersComponent },
    { path: '', redirectTo: '/admin/customers', pathMatch: 'full' }
];

export const ADMIN_ROUTES = RouterModule.forChild( adminRoutes ); 