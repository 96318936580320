import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";


@Injectable({
  providedIn: "root",
})
export class PlayerService {
  constructor(public http: HttpClient, public router: Router) { }

  getPlayesTeam(idlocal, idvisitor,idMatch,eventId,division_id) {
    return this.http.get(`${environment.API_URL}players/get/PlayerIdTeam/${idlocal}/${idvisitor}/${idMatch}/${eventId}/${division_id}`);
  }

  postPlayerMatchTeam(data: any) {
    return this.http.post(
      `${environment.API_URL}players/createPlayerStatMatch/`, data.data);
  }

  acceptInvitation(playerId,invitation_code) {
    return this.http.post(`${environment.API_URL}players/accept-invitation`, {playerId: playerId,invitation_code: invitation_code});
  }

  acceptWaivers(playerId,invitation_code) {
    return this.http.post(`${environment.API_URL}players/accept-waivers`, {playerId: playerId,invitation_code: invitation_code});
  }

  getPlayersEventByTeam(data) {
    return this.http.get(`${environment.API_URL}players/AllPlayersEventByTeam/${data.teamId}/${data.divisionId}`);

  }
  savePlayerWithTeam(data: any) {    
    return this.http.post(`${environment.API_URL}players/savePlayerWithTeam`, data);
  }

  assignmentPlayerEventByTeam(data: any) {    
    return this.http.post(`${environment.API_URL}players/assignmentPlayerEventByTeam`, data);
  }
  
}
