import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AdminComponent } from "./pages/admin/admin.component";
import { SuperadminComponent } from "./pages/superadmin/superadmin.component";

import { PagenotfoundComponent } from "./shared/pagenotfound/pagenotfound.component";
import { LoginComponent } from "./auth/login/login.component";
import { SignupComponent } from "./auth/signup/signup.component";
import { ActivationComponent } from "./auth/activation/activation.component";

//Services
import {
  LoginGuard,
  StatusLoginGuard,
  LoginAdminGuard,
  LoginSuperAdminGuard,
} from "src/app/services/service.index";
import {PrivacyPolicyComponent} from "./auth/privacy-policy/privacy-policy.component";
import {SiteComponent} from "./auth/site/site.component";
import {PlayerInvitationComponent} from "./auth/player_invitation/player_invitation.component";
import { StripeComponent } from './pages/superadmin/stripe/stripe.component';
import {EventLandingComponent} from "./auth/event-landing/event-landing.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
    canActivate: [StatusLoginGuard],
  },
  { path: "signup", component: SignupComponent },
  { path: "activation/:idActivation", component: ActivationComponent },
  { path: "activation", component: ActivationComponent },
  {
    path: "admin",
    component: AdminComponent,
    loadChildren: () =>
      import("./pages/admin/admin.module").then((m) => m.AdminModule),
    canActivate: [LoginAdminGuard],
  },
  {
    path: "superadmin",
    component: SuperadminComponent,
    loadChildren: () =>
      import("./pages/superadmin/superadmin.module").then(
        (m) => m.SuperadminModule
      ),
    canActivate: [LoginSuperAdminGuard],
  },
  { path: "privacy-policy", component: PrivacyPolicyComponent  },
  { path: "site", component: SiteComponent  },
  { path: "event/site/:eventId", component:   EventLandingComponent},
  { path: "player_invitation/:playerId/:invitation_code", component: PlayerInvitationComponent  },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  { path: "**", component: PagenotfoundComponent },

  { path: 'stripe', component: StripeComponent },
  { path: 'stripe/oauthcomplete', component: StripeComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
