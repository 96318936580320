import { Component, Inject, OnInit } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { take, startWith, map } from "rxjs/operators";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { GlobalState } from "src/app/store/states/global.state";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";

// Third libreries
import Swal from "sweetalert2";
import * as moment from "moment";

// Store
import {
  RolesCreateAction,
  RolesUpdateAction,
} from "src/app/store/actions/roles.actions";
import { RolesParams } from "src/app/models/roles/roles-params";
import * as rol from "src/app/store/selectors/roles.selectors";

@Component({
  selector: "app-new-rol",
  templateUrl: "./new-rol.component.html",
  styleUrls: ["./new-rol.component.css"],
})
export class NewRolComponent implements OnInit {
  title: string = "";
  btnAction: string = "";
  idRol: number;
  disabledBtn: boolean = false;
  rolFormGroup: FormGroup;

  // Store
  private subscription: Subscription = new Subscription();

  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NewRolComponent>,
    public dialog: MatDialog,
    public store: Store<GlobalState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    // Set Forms
    this.rolFormGroup = this._formBuilder.group({
      rol: ["", Validators.required],
    });

    // Set title
    if (this.data) {
      this.title = "Edit Role";
      this.btnAction = "Update";

      // Form
      this.idRol = this.data.id;
      this.rolFormGroup.setValue({
        rol: this.data.rol,
      });
    } else {
      this.title = "New Role";
      this.btnAction = "Create";
    }
  }

  // Create or Update
  async action() {
    this.disabledBtn = true;

    if (this.data) {
      this.store.dispatch(
        new RolesUpdateAction(<RolesParams>{
          data: { ...this.rolFormGroup.value, idRol: this.idRol },
        })
      );
    } else {
      this.store.dispatch(
        new RolesCreateAction(<RolesParams>{
          data: this.rolFormGroup.value,
        })
      );
    }

    const action = await this.store
      .select(rol.selectRolesLoading)
      .pipe(take(2))
      .toPromise();

    if (action.roles.valid) {
      this.dialogRef.close(true);
    } else {
      this.disabledBtn = false;
    }

    this.store.pipe(select(rol.selectRolesError)).subscribe((error) => {
      console.log("error rol: ", error);
    });
  }
}
