import * as staff from "../../store/states/staff.state";
import { StaffAction, StaffActionType } from "../../store/actions/staff.actions";

export function staffReducer(
  state = staff.initialStateStaff,
  action: StaffAction | any
): staff.StateStaff {
  switch (action.type) {
    case StaffActionType.Loading: {
      return { ...state, loading: true };
    }
    case StaffActionType.LoadSuccess: {
      return  { ...state,
        staff: action.payload,
        error: false,
        loading: false,
      };
    }
    case StaffActionType.LoadFailure: {
      return  { ...state,
        staff: null,
        error: true,
        loading: false,
      };
    }
    default:
      return state;
  }
}
