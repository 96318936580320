import { Action } from "@ngrx/store";
import { FieldsParams } from "../../models/fields/fields-params";
import { FieldsResponse } from "../../models/fields/fields-response";

export enum FieldsActionType {
  Get = "[Fields] Get",
  Enabled = "[Fields] Enabled",
  Create = "[Fields] Create",
  Update = "[Fields] Update",
  Delete = "[Fields] Delete",
  GetAll = "[Fields] Get All",
  Loading = "[Fields] Loading",
  LoadSuccess = "[Fields] LoadSuccess",
  LoadFailure = "[Fields] LoadFailure",
}

export class FieldsLoadAction implements Action {
  public readonly type = FieldsActionType.Loading;
  constructor(public payload: FieldsParams) {}
}

export class FieldsGetAction implements Action {
  public readonly type = FieldsActionType.Get;
  constructor(public payload: FieldsParams) {}
}

export class FieldsEnabledAction implements Action {
  public readonly type = FieldsActionType.Enabled;
  constructor(public payload: FieldsParams) {}
}

export class FieldsCreateAction implements Action {
  public readonly type = FieldsActionType.Create;
  constructor(public payload: FieldsParams) {}
}

export class FieldsUpdateAction implements Action {
  public readonly type = FieldsActionType.Update;
  constructor(public payload: FieldsParams) {}
}

export class FieldsDeleteAction implements Action {
  public readonly type = FieldsActionType.Delete;
  constructor(public payload: FieldsParams) {}
}

export class FieldsGetAllAction implements Action {
  public readonly type = FieldsActionType.GetAll;
  constructor(public payload: FieldsParams) {}
}

export class FieldsLoadSuccessAction implements Action {
  public readonly type = FieldsActionType.LoadSuccess;
  constructor(public payload: FieldsResponse) {}
}

export class FieldsLoadFailAction implements Action {
  public readonly type = FieldsActionType.LoadFailure;
  constructor(public error: any) {}
}

export type FieldsAction =
  | FieldsLoadAction
  | FieldsLoadSuccessAction
  | FieldsLoadFailAction;
