import { Component, Inject, OnInit } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { GlobalState } from "src/app/store/states/global.state";
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  DateAdapter,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { SharedService } from "src/app/services/service.index";

 

// Modals
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SharedDataService } from "src/app/utils/shared";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD-MM-YY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-team-selection-brackets",
  templateUrl: "./team-selection.component.html",
  styleUrls: ["./team-selection.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class TeamSelectionComponent implements OnInit {

  public bracketFormGroup: FormGroup;
  private subscription: Subscription = new Subscription();
  teams = []
  nteams = 0;
  div_id;
  loadingEventDetails?: boolean = true;

  errorBracketDetails?: boolean;
  public errorEventDetails$?: Observable<boolean>;


  constructor(
    private _sharedService: SharedService,
   /* @Optional()*/ public dialogRef: MatDialogRef<TeamSelectionComponent>,
    public store: Store<GlobalState>,
    public dialog: MatDialog,
    public route: ActivatedRoute,
   /* @Optional()*/ @Inject(MAT_DIALOG_DATA) public data: Array<any>,
    private _formBuilder: FormBuilder,
    private shared:SharedDataService
  ) {
    // console.log(data);
     data = data.map(e=>{
        if(!e.hasOwnProperty("selected")){
          e.selected = true;
        } 
        return e;
     });
     
  }

  ngOnInit() {
    this.nteams = this.data.length;
    this.nteams = this.data.filter(e=>e.selected).length
  }

  toggleSelectAll() {
    const selected = this.isAllSelected();
    this.data.forEach(item => item.selected = !selected);
    this.nteams = this.data.filter(e=>e.selected).length
  }
  isAllSelected() {
    return this.data.every(item => item.selected);
  }
  updateSelectAll(ev) {
    if (!this.isAllSelected()) {
       let index = this.data.findIndex(x => x.id == ev.source.id);
       if(!ev.checked){
          this.data[index].selected = false
       }else{
        this.data[index].selected = true
       }
    }
    this.nteams = this.data.filter(e=>e.selected).length
  }
  stringify(obj){
    return JSON.stringify(obj)
  }
  close(){
    this.dialogRef.close(this.data.filter(e=>e.selected));
  }
  create(){}

}
