import * as eventDetails from "../../store/states/event-details.state";
import { EventDetailsAction, EventDetailsActionType } from "../../store/actions/event-details.actions";

export function eventDetailsReducer(
  state = eventDetails.initialStateEventDetails,
  action: EventDetailsAction | any
): eventDetails.StateEventDetails {
  switch (action.type) {
    case EventDetailsActionType.Loading: {
      return { ...state, loading: true };
    }
    case EventDetailsActionType.LoadSuccess: {
      return  { ...state,
        eventDetails: action.payload,
        error: false,
        loading: false,
      };
    }
    case EventDetailsActionType.LoadFailure: {
      return  { ...state,
        eventDetails: null,
        error: true,
        loading: false,
      };
    }
    default:
      return state;
  }
}
