import * as type_division from "../states/type_division.state";
import { TypeDivisionAction, TypeDivisionActionType } from "../actions/type_division.actions";

export function type_divisionReducer(
  state = type_division.initialStateDivision,
  action: TypeDivisionAction | any
): type_division.StateTypeDivision {
  switch (action.type) {
    case TypeDivisionActionType.Loading: {
      return { ...state, loading: true };
    }
    case TypeDivisionActionType.LoadSuccess: {
      return  { ...state,
        type_division: action.payload,
        error: false,
        loading: false,
      };
    }
    case TypeDivisionActionType.LoadFailure: {
      return  { ...state,
        type_division: null,
        error: true,
        loading: false,
      };
    }
    default:
      return state;
  }
}
