import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { compressImage } from "../../utils/tools.utils";
import { UserService } from "src/app/services/user/user.service";
import { EnventTeams } from "src/app/services/teams/team.service";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";

import Swal from 'sweetalert2';
import { v4 as uuidv4 } from "uuid";


@Component({
  selector: 'app-manage-teams',
  templateUrl: './manage-teams.component.html',
  styleUrls: ['./manage-teams.component.css']
})
export class ManageTeamsComponent implements OnInit {
  title: string = "";
  fieldFormGroup: FormGroup;
  imageTeam: any;
  imageTeamSend: any;
  allUsers: any = [];
  filteredUsers: any[] = [];
  selectedUsers: any[] = [];
  searchTerm: string = '';
  btnAction: string = "SAVE";
  userCapitan: any;
  players: any = [];
  Teamid: any = "";
  idTeam: any = "";

  constructor(
    private _formBuilder: FormBuilder,
    private _userService: UserService,
    private _teamService: EnventTeams,
    public dialogRef: MatDialogRef<ManageTeamsComponent>,
    public dialog: MatDialog,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {


  }

  ngOnInit() {
    this.fieldFormGroup = this._formBuilder.group({
      name: ["", Validators.required],
      idUser: [null, Validators.required],
    });

    this._userService.getAll().subscribe((response: any) => {
      if (response.valid && response.data.length > 0) {
        this.allUsers = response.data;
      }
    });

    if (this.data) {
      this.title = "Edit Team";
      this.btnAction = "Update";

      // Form      
      this.idTeam = this.data.id;
      this.Teamid = this.data.teamId;

      this.fieldFormGroup.setValue({
        name: this.data.name,
        idUser: this.data.userId.idUser,
      });

      this.data.players.forEach(element => {
        this.selectedUsers.push(element.userId);
      });


      if (this.data.logo != null && this.data.logo != undefined) {
        
        this.imageTeam = this.convertImage(this.data.logo, 'static/')
        this.imageTeamSend =this.data.logo;
      }


    } else {
      this.title = "New Team";
      this.btnAction = "Create";
    }
  }

  convertImage(base64string: string, path: string) {
    if (base64string.length) {
      return `${environment.API_URL + path + base64string}`
    }
  }

  async handleUpload(event) {

    const file = event.target.files[0];
    const reader = new FileReader();
    const max_size = 1048576;

    if (event.target.files[0].size > max_size) {

      setTimeout(() => {
        Swal.fire({
          title: "Error",
          text: "Maximum size allowed is 1 Mb",
          icon: "error",
          showConfirmButton: false,
          timer: 4000,
        });
      }, 800);
      return false;
    }

    reader.readAsDataURL(file);
    reader.onload = () => {
      compressImage(reader.result, 300, 300).then((compressed) => {
        this.imageTeam = compressed;
        this.imageTeamSend =compressed;
      });
    };

  }
  searchByName(name: string): void {

    if (name.trim() === '') {
      this.filteredUsers = [];
      return;
    }
    this.filteredUsers = this.allUsers.filter(user => {
      const userLastName = user['lastName'];
      const userFirstName = user['firstName'];
      return (
        userLastName && userLastName.toLowerCase().includes(name.toLowerCase()) ||
        userFirstName && userFirstName.toLowerCase().includes(name.toLowerCase())
      );
    });
  }

  selectUser(user) {

    const userIndex = this.selectedUsers.findIndex(selectedUser => selectedUser['idUser'] === user['idUser']);
    if (userIndex === -1) {
      this.selectedUsers.push({ ...user, idPlayer: uuidv4() });
    } else {
      this.selectedUsers.splice(userIndex, 1);
    }
  }

  capitanSelected(event: any) {
    this.userCapitan = event.value;
  }

  removeSelectedUser(selectedUser: any): void {
    const userIndex = this.selectedUsers.findIndex(user => user['idUser'] === selectedUser['idUser']);
    if (userIndex !== -1) {
      this.selectedUsers.splice(userIndex, 1);
    }
  }
  removeUpload() {
    this.imageTeam = "";
    this.imageTeamSend = "";
  }

  saveTeam(): void {

    let imageLogo;
    if (this.imageTeam) {
      //imageLogo = Buffer.from(this.imageTeam).toString("base64");
      imageLogo = this.imageTeamSend   
    } else {
      imageLogo = '';
    }

    let Teamid;
    if (this.idTeam) {
      Teamid = this.Teamid;
    } else {
      Teamid = uuidv4();
    }

    let data: any = {
      ...this.fieldFormGroup.value,
      idEvent: null,
      idTeam: Teamid,
      players: this.selectedUsers,
      logo: JSON.stringify(imageLogo),
      id: this.idTeam
    };

    if (!this.idTeam) {
     
      this._teamService.saveTeam({ data: data }).subscribe((res: any) => {
        if (res.valid) {
          this.dialogRef.close(true);
        } else {
          this.dialogRef.close(false);
        }
      });
    } else {
     
      this._teamService.updateTeamPlayers({ data: data }).subscribe((res: any) => {
        if (res.valid) {
          this.dialogRef.close(true);
        } else {
          this.dialogRef.close(false);
        }
      });
    }
  }
}


