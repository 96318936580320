import { Component, Inject, OnInit } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { take, startWith, map } from "rxjs/operators";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { GlobalState } from "src/app/store/states/global.state";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";

// Third libreries
import { Buffer } from "buffer";
import { compressImage } from "../../utils/tools.utils";
import Swal from "sweetalert2";
import * as moment from "moment";

// Store
import {
  UserCreateAction,
  UserUpdateAction,
} from "src/app/store/actions/user.actions";
import { UserParams } from "src/app/models/user/user-params";
import * as user from "src/app/store/selectors/user.selectors";
import * as gender from "src/app/store/selectors/gender.selectors";

@Component({
  selector: "app-new-user",
  templateUrl: "./new-user.component.html",
  styleUrls: ["./new-user.component.css"],
})
export class NewUserComponent implements OnInit {
  idUser: number;
  title: string = "";
  btnAction: string = "";
  formCreate: boolean = false;
  disabledBtn: boolean = false;
  userFormGroup: FormGroup;
  userAditionalFormGroup: FormGroup;
  imagesUser: any = [
    {
      profileLogo: "",
    },
  ];
  gender: any;

  // Store
  private subscription: Subscription = new Subscription();

  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NewUserComponent>,
    public dialog: MatDialog,
    public store: Store<GlobalState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    // Get Gender
    this.subscription.add(
      this.store
        .pipe(select(gender.selectGenderLoading))
        .subscribe((loading) => {
          if (!loading) {
            this.store
              .pipe(select(gender.selectGenderResponse))
              .subscribe((response) => {
                if (response.valid) {
                  this.gender = response.data;
                }
              });
          }
        })
    );

    // Set Forms
    this.userFormGroup = this._formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      phoneNumber: ["", Validators.required],
      username: ["", Validators.required],
      jerseyNumber: [""],
      address: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      zipCode: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
    });

    this.userAditionalFormGroup = this._formBuilder.group({
      feet: ["", Validators.required],
      inches: ["", Validators.required],
      password: ["", Validators.required],
      rePassword: ["", Validators.required],
      dateBirth: ["", Validators.required],
      genderUser: [""],
    });

    // Set title
    if (this.data) {
      this.title = "Edit User";
      this.btnAction = "Update";
      this.formCreate = false;

      // Form Edit
      this.idUser = this.data.idUser;
      this.userFormGroup.setValue({
        firstName: this.data.firstName,
        lastName: this.data.lastName,
        phoneNumber: this.data.phoneNumber,
        username: this.data.username,
        jerseyNumber: this.data.jerseyNumber,
        address: this.data.address,
        city: this.data.city,
        state: this.data.state,
        zipCode: this.data.zipCode,
        email: this.data.email,
      });
    } else {
      this.title = "New User";
      this.btnAction = "Create";
      this.formCreate = true;
    }
  }

  get c() {
    return this.userFormGroup.controls;
  }

  get d() {
    return this.userAditionalFormGroup.controls;
  }

  // Get Images
  async handleUpload(event, type) {
    // Type : 0 - Profile
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      switch (type) {
        case 0:
          compressImage(reader.result, 300, 300).then((compressed) => {
            this.imagesUser[0].profileLogo = compressed;
          });
          console.log(
            "this.imagesUser.profileLogo> ",
            this.imagesUser[0].profileLogo
          );
          break;
        default:
          break;
      }
    };
  }

  // Remove Images
  removeUpload(type, idx) {
    // Type : 0 - Profile
    switch (type) {
      case 0:
        this.imagesUser[0].profileLogo = "";
        break;
      default:
        break;
    }
  }

  // Validate Form
  validateForm() {
    if (!this.formCreate) {
      return !this.userFormGroup.valid;
    } else {
      return !this.userFormGroup.valid === false &&
        !this.userAditionalFormGroup.valid === false &&
        this.imagesUser[0].profileLogo
        ? false
        : true;
    }
  }

  // Create or Update
  async action() {
    this.disabledBtn = true;

    if (this.data) {
      this.store.dispatch(
        new UserUpdateAction(<UserParams>{
          data: { ...this.userFormGroup.value, idUser: this.idUser },
        })
      );
    } else {
      if (
        this.userAditionalFormGroup.value.password !=
        this.userAditionalFormGroup.value.rePassword
      ) {
        Swal.fire({
          title: "Error",
          text: "Passwords do not match.",
          icon: "error",
          showConfirmButton: true,
          timer: 2000,
        });
        this.disabledBtn = false;
      } else {
        delete this.userAditionalFormGroup.value.rePassword;
        (this.userAditionalFormGroup.value.dateBirth = moment(
          this.userAditionalFormGroup.value.dateBirth
        ).format("YYYY-MM-DD")),
          this.store.dispatch(
            new UserCreateAction(<UserParams>{
              data: {
                ...this.userFormGroup.value,
                ...this.userAditionalFormGroup.value,
                ...{
                  profilePicture: Buffer.from(
                    this.imagesUser[0].profileLogo
                  ).toString("base64"),
                },
                ...{
                  idCardPicture: Buffer.from(
                    this.imagesUser[0].profileLogo
                  ).toString("base64"),
                },
              },
            })
          );
      }
    }

    const action = await this.store
      .select(user.selectUserLoading)
      .pipe(take(2))
      .toPromise();

    if (action.user.valid) {
      this.dialogRef.close(true);
    } else {
      this.disabledBtn = false;
    }

    this.store.pipe(select(user.selectUserError)).subscribe((error) => {
      console.log("error user: ", error);
    });
  }
}
