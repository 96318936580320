import * as typeEvent from "../../store/states/type-event.state";
import { TypeEventAction, TypeEventActionType } from "../../store/actions/type-event.actions";

export function typeEventReducer(
  state = typeEvent.initialStateTypeEvent,
  action: TypeEventAction | any
): typeEvent.StateTypeEvent {
  switch (action.type) {
    case TypeEventActionType.Loading: {
      return { ...state, loading: true };
    }
    case TypeEventActionType.LoadSuccess: {
      return  { ...state,
        typeEvent: action.payload,
        error: false,
        loading: false,
      };
    }
    case TypeEventActionType.LoadFailure: {
      return  { ...state,
        typeEvent: null,
        error: true,
        loading: false,
      };
    }
    default:
      return state;
  }
}
