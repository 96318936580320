import * as user from "../../store/states/user.state";
import { UserAction, UserActionType } from "../../store/actions/user.actions";

export function userReducer(
  state = user.initialStateUser,
  action: UserAction | any
): user.StateUser {
  switch (action.type) {
    case UserActionType.Loading: {
      return { ...state, loading: true };
    }
    case UserActionType.LoadSuccess: {
      return  { ...state,
        user: action.payload,
        error: false,
        loading: false,
      };
    }
    case UserActionType.LoadFailure: {
      return  { ...state,
        user: null,
        error: true,
        loading: false,
      };
    }
    default:
      return state;
  }
}
