import { Component, OnInit } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { GlobalState } from "src/app/store/states/global.state";

// Store
import { TypeEventGetAction } from "src/app/store/actions/type-event.actions";
import * as typeEvent from "src/app/store/selectors/type-event.selectors";

import { GenderGetAction } from "src/app/store/actions/gender.actions";
import * as gender from "src/app/store/selectors/gender.selectors";

import { RolesGetAction } from "src/app/store/actions/roles.actions";
import * as roles from "src/app/store/selectors/roles.selectors";

import { FieldsGetAction } from "src/app/store/actions/fields.actions";
import * as fields from "src/app/store/selectors/fields.selectors";

import { YearsGetAction } from "src/app/store/actions/years.actions";
import * as years from "src/app/store/selectors/years.selectors";

import { TypeDivisionGetAction } from "src/app/store/actions/type_division.actions";
import * as type_division from "src/app/store/selectors/type_division.selectors";

import { UserGetAllAction } from "src/app/store/actions/user.actions";
import * as user from "src/app/store/selectors/user.selectors";

@Component({
  selector: "app-superadmin",
  templateUrl: "./superadmin.component.html",
  styles: [],
})
export class SuperadminComponent implements OnInit {
  // TypeEvent
  loadingTypeEvent?: boolean = true;
  errorTypeEvent?: boolean;
  public errorTypeEvent$?: Observable<boolean>;

  // Gender
  loadingGender?: boolean = true;
  errorGender?: boolean;
  public errorGender$?: Observable<boolean>;

  // Roles
  loadingRoles?: boolean = true;
  errorRoles?: boolean;
  public errorRoles$?: Observable<boolean>;

  // Fields
  loadingFields?: boolean = true;
  errorFields?: boolean;
  public errorFields$?: Observable<boolean>;

  // Years
  loadingYears?: boolean = true;
  errorYears?: boolean;
  public errorYears$?: Observable<boolean>;

  // Division
  loadingTypeDivision?: boolean = true;
  errorTypeDivision?: boolean;
  public errorTypeDivision$?: Observable<boolean>;

  // User
  loadingUser?: boolean = true;
  errorUser?: boolean;
  public errorUser$?: Observable<boolean>;

  // Others
  private subscription: Subscription = new Subscription();

  constructor(public store: Store<GlobalState>) {}

  ngOnInit() {
    // Get Type Events
    this.store.dispatch(new TypeEventGetAction(null));

    this.subscription.add(
      this.store
        .pipe(select(typeEvent.selectTypeEventLoading))
        .subscribe((loading) => {
          this.loadingTypeEvent = loading;
        })
    );

    this.store
      .pipe(select(typeEvent.selectTypeEventError))
      .subscribe((error) => {
        this.errorTypeEvent = error;
      });

    // Get Gender
    this.store.dispatch(new GenderGetAction(null));

    this.subscription.add(
      this.store
        .pipe(select(gender.selectGenderLoading))
        .subscribe((loading) => {
          this.loadingGender = loading;
        })
    );

    this.store.pipe(select(gender.selectGenderError)).subscribe((error) => {
      this.errorGender = error;
    });

    // Get Roles
    this.store.dispatch(new RolesGetAction(null));

    this.subscription.add(
      this.store.pipe(select(roles.selectRolesLoading)).subscribe((data) => {
        this.loadingRoles = data.loading;
      })
    );

    this.store.pipe(select(roles.selectRolesError)).subscribe((error) => {
      this.errorRoles = error;
    });

    // Get Fields
    this.store.dispatch(new FieldsGetAction(null));

    this.subscription.add(
      this.store.pipe(select(fields.selectFieldsLoading)).subscribe((data) => {
        this.loadingFields = data.loading;
      })
    );

    this.store.pipe(select(fields.selectFieldsError)).subscribe((error) => {
      this.errorFields = error;
    });

    // Years
    this.store.dispatch(new YearsGetAction(null));

    this.subscription.add(
      this.store.pipe(select(years.selectYearsLoading)).subscribe((loading) => {
        this.loadingYears = loading;
      })
    );

    this.store.pipe(select(years.selectYearsError)).subscribe((error) => {
      this.errorYears = error;
    });

    // Division
    this.store.dispatch(new TypeDivisionGetAction(null));

    this.subscription.add(
      this.store
        .pipe(select(type_division.selectTypeDivisionLoading))
        .subscribe((loading) => {
          this.loadingTypeDivision = loading;
        })
    );

    this.store.pipe(select(type_division.selectTypeDivisionError)).subscribe((error) => {
      this.errorTypeDivision = error;
    });

    // Users
    this.store.dispatch(new UserGetAllAction(null));

    this.subscription.add(
      this.store.pipe(select(user.selectUserLoading)).subscribe((loading) => {
        this.loadingUser = loading.valid;
      })
    );

    this.store.pipe(select(user.selectUserError)).subscribe((error) => {
      this.errorUser = error;
    });
  }
}
