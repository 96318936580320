import { Component, OnInit,NgModule, Inject  } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup } from '@angular/forms';
import { SharedDataService } from "src/app/utils/shared";
import {BracketService, EventService, SharedService} from "src/app/services/service.index";


@Component({
  selector: 'app-update-score',
  templateUrl: './update-score.component.html',
  styleUrls: ['./update-score.component.css']
})
export class UpdateScoreComponent implements OnInit {

  nameLocal: string = "";
  nameVisitor: string = "";
  localscore: number = 0;
  visitorscore: number = 0;
  finalizeScore: boolean=false;  
  confirmFinalize: number = 0;
  bracket: number = 0;  
  scoreFormGroup: FormGroup;
  formatBracket : string ="";
  response: any = "";
  idEvent : any = "";
  division_id: any = "";
  observationsMatch : string ="";

  idmatch:number;
  

  constructor(
    public _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public shared:SharedDataService,
    private service:BracketService,
    public dialogRef: MatDialogRef<UpdateScoreComponent>,
    private _bracketsService: BracketService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 


  }

  ngOnInit() {

    this.scoreFormGroup = this._formBuilder.group({           
      localscore: [null],
      visitorscore: [null],
      finalizeScore: [null],       
    });        

    this.localscore =this.data.localscore; 
    this.visitorscore = this.data.visitorscore;
    this.nameLocal = this.data.nameLocal;
    this.nameVisitor =this.data.nameVisitor;
    this.bracket =this.data.bracket;
    this.idEvent = this.data.idEvent;
    this.division_id=this.data.division_id;

    this.service.getByBracketId({bracket_id:this.bracket}).subscribe((response:any)=>{
      if(response.valid){               
        this.formatBracket = response.data.format;
        
      }
    });
  }

  async saveScore() {
    
    let data: any;   
    if (this.finalizeScore){
      this.confirmFinalize =1
    } 

    data = {
      Id: this.data.idmatch,
      scoreLocal: this.localscore,
      scoreVisitor: this.visitorscore,      
      bracket: this.bracket,
      idEvent: this.idEvent,
      division_id: this.division_id,
      confirmFinalize :this.confirmFinalize
    };      
    
    if (this.formatBracket == 'Double Elimination') {
      this.response = await this.service.updateScoreMatchDouble(data);
    } else {
      this.response = await this.service.updateScoreMatch(data);
    }
    if (this.response.valid) {

      const dataResponse = {
        result: true,
        visitor: this.visitorscore,
        local: this.localscore,
        finalize: this.finalizeScore
      };
      this.dialogRef.close(dataResponse);

    }
    else {
      this.dialogRef.close(false);
    }
  }

}
