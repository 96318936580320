import { Component, OnInit,Inject} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {EnventTeams} from "src/app/services/teams/team.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ManageTeamsComponent } from '../manage-teams/manage-teams.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.css']
})
export class AddTeamComponent implements OnInit {
  public teamsFormGroup: FormGroup;
  teams: any[];
  idTeams: any[] = [];
  constructor(
    private _formBuilder: FormBuilder,
    private _eventTeams: EnventTeams,
    public dialogRef: MatDialogRef<AddTeamComponent>,
    public dialog: MatDialog,   
  /* @Optional()*/ @Inject(MAT_DIALOG_DATA) public data: any,
  )
  { 
    this.idTeams = [];
  }

  addTeam(id: string, idTeam: string) {
    let posicion = this.idTeams.findIndex(item => item.id === id);
    console.log(posicion);
    if (posicion === -1) {
      this.idTeams.push({ 'id': id, 'idTeam': idTeam });
    } else {
      this.idTeams.splice(posicion, 1);
    }
    console.log(this.idTeams);
  }

  newTeam() {
    const dialogRef = this.dialog.open(ManageTeamsComponent, {
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        setTimeout(() => {
          Swal.fire({
            title: "Success",
            text: "Create Team",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }, 1000);
        this.ngOnInit();        
      }
    });
  }

  saveTeams ()
  {
  
    let sava_data_teams = [];
    this.idTeams.forEach((idTeam) => {
      sava_data_teams.push({team_id: idTeam.id, team_id_hash:idTeam.idTeam, division_id: this.data.division_id, event_id: this.data.event_id })
    });
    console.log(sava_data_teams);
    this._eventTeams.assignTeams({data: {assignments:sava_data_teams}}).subscribe((res:any) => {
      if(res.valid){
      this.close();     
      } else {
        console.log('False->',res);
      }
    });
  }
  ngOnInit()
  { 
    this.teamsFormGroup = this._formBuilder.group({      
    });

    this._eventTeams.getAllTeamsNotDivision({division_id:this.data.division_id}).subscribe((response:any)=>{
      if(response.valid){
        this.teams = response.data;        

      }
    });
  }
  close(){   
    this.dialogRef.close(true);
  }

}
