import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateUser, userAdapter } from '../../store/states/user.state';

export const {
  selectIds: _selectUserDataIds,
  selectEntities: _selectUserEntities,
  selectAll: _selectAllUser,
  selectTotal: _selectUserTotal
} = userAdapter.getSelectors();

export const selectState = createFeatureSelector<StateUser>('user');

export const selectUserIds = createSelector(
  selectState,
  _selectUserDataIds
);

export const selectUserEntities = createSelector(
  selectState,
  _selectUserEntities
);

export const selectAllUser = createSelector(
  selectState,
  _selectAllUser
);

export const selectUserResponse = createSelector(
  selectState,
  (state: StateUser): any => state.user
);

export const selectUserError = createSelector(
  selectState,
  (state: StateUser): boolean => state.error
);

export const selectUserLoading = createSelector(
  selectState,
  (state: StateUser): any => state
);
